<template>
  <div>
    <h1 class="header">Сенсоры</h1>
    <div class="d-flex justify-content-end">
      <base-button
        text="Добавить сенсор"
        color="green"
        size="m"
        @click="setPopupVisibility(true)"
      />
    </div>
    <div v-if="isLoading" class="mt-20 text-center">
      <img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" />
    </div>
    <!-- <div v-if="isLoading" class="mt-20 text-center"><img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" /></div> -->
    <base-table class="mt-5" v-else>
      <template v-slot:head>
        <base-table-row>
          <base-table-head-cell width="20%">Название</base-table-head-cell>
          <base-table-head-cell width="20%">Тип</base-table-head-cell>
          <base-table-head-cell width="45%">Адрес здания</base-table-head-cell>
          <base-table-head-cell width="10%">Комплекс</base-table-head-cell>
          <base-table-head-cell width="5%"></base-table-head-cell>
        </base-table-row>
      </template>
      <template v-slot:body>
        <base-table-row v-for="item in sensors" :key="item?.id">
          <base-table-cell width="20%"> {{ item?.name }} </base-table-cell>
          <base-table-cell width="20%">{{
            sensorType(item.type)
          }}</base-table-cell>
          <base-table-cell width="45%">
            {{ item.building?.address || '-' }}
          </base-table-cell>
          <base-table-cell width="10%">
            {{ item.complex?.name || '-' }}
          </base-table-cell>
          <base-table-cell width="5%">
            <mdicon
              size="17"
              name="pencil"
              class="cursor-pointer"
              @click="onSensorUpdate(item)"
            ></mdicon>
          </base-table-cell>
        </base-table-row>
      </template>
    </base-table>
  </div>
  <sensor-modal
    @close="setPopupVisibility(false)"
    :isModalVisible="isPopupVisible"
    @create="onSensorCreated"
    @update="onSensorUpdated"
    :sensor="selectedSensor"
  />
</template>
<script setup>
import { ref, watch } from 'vue';
import { BaseButton } from '@/components/atoms';
import {
  BaseTable,
  BaseTableCell,
  BaseTableHeadCell,
  BaseTableRow,
} from '@/components/BaseTable';
import SensorModal from './SensorModal.vue';
import { getAllSensors } from '@/graphql/Sensors.graphql';
import { useQuery } from '@vue/apollo-composable';
// import { THEME } from '@/constant/main'
const THEME = config.VUE_APP_THEME;
const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');
const isLoading = ref(true);

const sensors = ref([]);

const { result: allSensors, refetch } = useQuery(getAllSensors);

watch(allSensors, (newValue) => {
  sensors.value = newValue.getAllSensors;
  isLoading.value = false;
});

const sensorType = (type) => {
  switch (type) {
    case 'DOMFON':
      return 'Домофон';
    case 'CAMERA':
      return 'Камера';
    case 'SHLAGBAUM':
      return 'Шлагбаум';
  }
};

const onSensorCreated = async () => {
  await refetch();
};

const onSensorUpdated = async () => {
  await refetch();
};

const selectedSensor = ref(null);

const onSensorUpdate = (sensor) => {
  selectedSensor.value = sensor;
  setPopupVisibility(true);
};

const isPopupVisible = ref(false);

const setPopupVisibility = (status) => {
  isPopupVisible.value = status;

  if (!status) {
    selectedSensor.value = null;
  }
};
</script>
<style scoped>
.header {
  color: var(--black);
}
</style>
