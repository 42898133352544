<template>
  <base-popup
    size="large"
    topIndent="80"
    @close="onModalClose"
    :is-visible="isModalVisible"
  >
    <div class="popup">
      <div class="item status">
        <div class="status__item" :class="vote.vote.archived ? 'red' : 'green'">
          <span>{{
            vote.vote.archived
              ? `Завершен ${formatDate(vote.vote.updatedAt)}`
              : 'Активен'
          }}</span>
        </div>
      </div>
      <div class="item max-w">
        <span class="item__title">Тема опроса:</span>
        <span class="item__text">{{ vote.vote.title }}</span>
      </div>
      <div class="item">
        <span class="item__title">Варианты ответа:</span>
        <div
          class="item__variants variants"
          v-for="(result, idx) in results"
          :key="result.optionId"
        >
          <div class="variants__item result">
            <span class="item__title">Вариант {{ idx + 1 }}</span>
            <div
              class="result__body"
              :class="{
                first:
                  (results.indexOf(result) === 0 ||
                    result.count === results[0].count) &&
                  results[0].count,
              }"
            >
              <span class="result__text">{{ result.title }}</span>
              <span class="result__text"
                >{{ calcPercent(result.count, vote.totalResults) }}%</span
              >
              <div
                v-if="THEME === 'domion'"
                class="result__progress"
                :style="`width: ${calcPercent(
                  result.count,
                  vote.totalResults
                )}%; background: rgba(2, 103, 255, ${
                  results.indexOf(result) === 0 || result.count === results[0].count
                    ? 0.33
                    : 0.15
                })`"
              ></div>
              <div
                v-else
                class="result__progress"
                :style="`width: ${calcPercent(
                  result.count,
                  vote.totalResults
                )}%; background: rgba(160, 232, 175, ${
                  results.indexOf(result) === 0 || result.count === results[0].count
                    ? 0.33
                    : 0.15
                })`"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <span class="item__title">Получатели:</span>
        <span class="item__text">{{
          vote.vote.toAll ? 'Собственники и жильцы' : 'Собственники'
        }}</span>
      </div>
      <div class="item">
        <div class="recipients__list">
          <div
            class="recipients__item"
            v-for="building in vote.buildings"
            :key="building.id"
          >
            <span>{{ building.name }}</span>
          </div>
        </div>
      </div>
      <div class="item">
        <span class="item__text">{{
          vote.vote.isMultiple
            ? 'Несколько вариантов ответа'
            : 'Один вариант ответа'
        }}</span>
      </div>
      <div class="item">
        <span class="item__title"
          >{{
            declinationFromNumber(
              vote.totalUsers,
              'Проголосовал',
              'Проголосовало',
              'Проголосовало'
            )
          }}:</span
        >
        <span class="item__text">{{ vote.totalUsers }} чел.</span>
      </div>
      <div class="item" v-if="vote.vote.termType === 'COUNT'">
        <span class="item__title">Срок завершения опроса:</span>
        <p class="item__text mt-20">По процентам</p>
        <div class="range-input">
          <BaseInputRange
            :min="1"
            :value="(vote.vote.termValue / totalCount) * 100"
            :disabled="true"
          />
          <div class="item__text ml-4">
            {{ Math.floor((vote.vote.termValue / totalCount) * 100) + 1 }}%
          </div>
          <div class="item__title ml-4">{{ vote.vote.termValue }} чел.</div>
        </div>
      </div>
      <div class="item" v-else>
        <span class="item__title">Срок завершения опроса:</span>
        <span class="item__text">{{ vote.vote.termValue }}</span>
        <!-- <span class="item__text">{{ formatDate(vote.vote.termValue) }}</span> -->
      </div>
      <div class="button__item">
        <span class="item__title"
          >Дата создания: {{ formatDate(vote.vote.createdAt) }}</span
        >
        <!-- <span>{{ formatDate(vote.vote.createdAt) }}</span> -->
        <BaseButton text="Готово" color="green" @click="onModalClose" />
      </div>
    </div>
  </base-popup>
</template>
<script setup>
import { computed, watch, ref } from 'vue';
import { defineEmits, defineProps } from 'vue';
import { BasePopup, BaseInputRange, BaseButton } from '@/components/atoms';
import { getBuildings } from '@/graphql/Votes.graphql';
import { useQuery } from '@vue/apollo-composable';
import { useRouter, useRoute } from 'vue-router';

const THEME = config.VUE_APP_THEME;
const router = useRouter();
const route = useRoute();

const emit = defineEmits(['close']);

const props = defineProps({
  isModalVisible: {
    type: Boolean,
    default: false,
  },
  vote: {},
});

const buildingsList = ref([]);

watch(
  () => props.isModalVisible,
  (newValue) => {
    if (newValue) {
      // console.log('vote', props.vote);
      allBuildings.value?.getBuildings.forEach((item) => {
        props.vote.buildings.forEach((subItem) => {
          if (item.id === subItem.id) {
            buildingsList.value.push(item);
          }
        });
      });
      // console.log('buildingList', buildingsList.value);
      buildingsList.value.forEach(({ count }) => (totalCount.value += count));
      router.replace({
        route,
        query: {
          vote: props.vote.vote.id,
        },
      });
    }
  }
);

const onModalClose = () => {
  emit('close');
  totalCount.value = 0;
  buildingsList.value = [];
  router.replace({
    route,
  });
};

const totalCount = ref(0);

let { result: allBuildings } = useQuery(getBuildings);

const results = computed(() => {
  const voteItem = props.vote.resultsCount;
  return voteItem
    .slice()
    .sort((a, b) => a.count - b.count)
    .reverse();
});

const calcPercent = (count, total) => {
  return ((count / total) * 100 || 0).toFixed(2);
};
const formatDate = (date) => {
  // console.log(date);
  return new Date(date).toLocaleDateString('ru-RU');
};
const declinationFromNumber = (number, one, two, five) => {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }
  return five;
};
</script>
<style scoped>
.popup {
  font-family: 'Golos';
}
.item {
  margin-bottom: 40px;
}
.item__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.25px;
  color: var(--grey-text-color);
  margin-right: 10px;
}
.item__text {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--black);
}
.result__body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  /* height: 40px; */
  border: 1px solid var(--grey-purple);
  filter: drop-shadow(0px 4px 4px var(--black-notification-shadow));
  border-radius: 10px;
  padding: 11px 16px;
  position: relative;
  overflow: hidden;
  margin-left: 10px;
}
/* .result__body.first {
  border: 1px solid var(--primary-color);
} */
.result__text {
  font-weight: 500;
  font-size: 15px;
  line-height: 1.25;
  color: var(--dark);
}
.result__progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 10px;
  z-index: -1;
  transition: all 0.3s ease 0s;
}
.item__variants {
  margin-top: 15px;
}
.variants__item {
  display: flex;
  align-items: center;
}
.variants__item:not(:last-child) {
  margin-bottom: 10px;
}
.recipients__list {
  display: flex;
  flex-wrap: wrap;
}
.recipients__item {
  background: var(--primary-light-opacity-color);
  border: 0.5px solid var(--grey-disabled-color);
  border-radius: 5px;
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px 10px 0;
}
.recipients__item span {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--black);
}
.range-input {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.range-input .item__text,
.range-input .item__title {
  margin-bottom: 20px;
}
.button__item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}
.button__item::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--grey-purple);
}
.item.status {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
}
.status__item span {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.status__item {
  background: var(--light-bg);
  box-shadow: 0px 2px 2px var(--black-notification-shadow);
  border-radius: 6px;
  padding: 7px 15px;
}
.status__item.green span {
  color: var(--primary-color);
}
.status__item.red span {
  color: var(--button-error-disable-color);
}
.max-w {
  max-width: 80%;
}
</style>
