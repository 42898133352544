<template>
  <form class="form">
    <SetupInput
      v-if="template"
      :value="template.name"
      label="Название шаблона"
    />
    <SetupSelect
      label="Поставщик"
      :value="agentGroup"
      :options="agentGroupItems"
      @change="onChangeAgentGroup"
    />
    <div class="datepicker">
      <div class="datepicker__label">Выбрать месяц *</div>
      <Datepicker
        ref="datepickerRef"
        v-model="period"
        locale="ru-RU"
        selectText="Выбрать"
        cancelText="Отмена"
        :format="'MM/yyyy'"
        placeholder="Выберите дату"
        month-picker
        :enable-time-picker="false"
        @update:model-value="onChangeDate"
      >
      </Datepicker>
    </div>
    <div class="form" :key="formKey">
      <div v-if="isLoading" class="mt-20 text-center">
        <img
          :src="THEME === 'domion' ? loaderDomion : loaderGreen"
          alt="loading"
        />
      </div>
      <div v-else v-for="(item, idx) in formData" :key="idx">
        <SetupInput
          :label="`${item.Caption} ${item.Required === 'true' ? '*' : ''}`"
          :required="item.Required === 'true'"
          :suffixLabel="
            item.Name === 'summa' ? `Комиссия: ${genbankData.commission}%` : ''
          "
          @input="onChangeInput($event, item)"
        />
      </div>
    </div>
    <SetupInfoBlock
      v-if="debtSum"
      :icon="{
        name: 'wallet',
        color: '#FF0000',
        size: '18',
      }"
      title="Задолженность"
      :suffix="debtSum + ' руб.'"
      suffixColor="#FF0000"
    />
  </form>
</template>
<script setup>
import { watch, ref, onMounted } from 'vue';
import SetupInfoBlock from '../../SetupComponents/SetupInfoBlock.vue';
import SetupInput from '../../SetupComponents/SetupInput.vue';
import SetupSelect from '../../SetupComponents/SetupSelect.vue';
import { useStore } from 'vuex';

const THEME = config.VUE_APP_THEME;
const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');
const store = useStore();
const sessionToken = store.getters['app/getSessionToken'];

const props = defineProps({
  template: Object,
  agentGroupItems: Array,
  deepModalData: Object,
});

const emit = defineEmits(['formChanged', 'formData']);

let payload = ref({});
const datepickerRef = ref(null);
const genbankData = ref();
const agentGroup = ref('');
const formData = ref([]);
const agentId = ref('');
const debtSum = ref('');
const isLoading = ref(true);
const period = ref();
const formKey = ref(1);

const clearDatePickerValue = () => {
  if (
    datepickerRef.value &&
    typeof datepickerRef.value.clearValue === 'function'
  ) {
    datepickerRef.value.clearValue();
  }
};

const onChangeAgentGroup = (item) => {
  payload.value = {};
  clearDatePickerValue();
  formKey.value += 1;
  agentId.value = item.agentId;
  // console.log(String(item.agentId));
  payload.value.agentId = String(item.agentId);
  getPaymentFormFields(item.agentId);
};

const onChangeDate = (data) => {
  payload.value.period = `${data?.month + 1}.${data?.year}`;
  emit('formChanged', payload.value);
};

let timeoutId;
const onChangeInput = (e, item) => {
  payload.value[item.Name] = e.target.value;
  if (timeoutId) clearTimeout(timeoutId);
  if (item.Name === 'ls') {
    timeoutId = setTimeout(async () => {
      await getPaymentItemDebt();
    }, 1000);
  }

  emit('formChanged', payload.value);
};

const getPaymentFormFields = async (agentId) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await fetch(
      `https://trouter.domion.tech/genbank/agent-fields`,
      {
        // const response = await fetch(`${config.VUE_APP_API_TROUTER}/genbank/agent-fields`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify({
          agentId,
          terminalId: 'Domion',
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      const data = await response.json();
      emit('formData', data);

      // SORTING
      let filteredAndSortedData = [...data];
      filteredAndSortedData = filteredAndSortedData.filter(
        (item) =>
          item.Name !== 'period' &&
          item.Name !== 'agentId' &&
          item.Name !== 'terminalId'
      );
      const summaIndex = filteredAndSortedData.findIndex(
        (item) => item.Name === 'summa'
      );

      if (summaIndex !== -1) {
        const summaItem = filteredAndSortedData.splice(summaIndex, 1)[0];
        filteredAndSortedData.push(summaItem);
      }

      formData.value = filteredAndSortedData;

      let payloadValue = data.reduce((acc, item) => {
        acc[item.Name] = '';
        return acc;
      }, {});

      payload.value = payloadValue;
      payload.value.agentId = String(agentId);
      isLoading.value = false;
    }
  } catch (error) {
    // console.error('There was a problem with the fetch operation:', error);
    throw error;
  }
};

const getPaymentGetaway = async () => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await fetch(
      // `${config.VUE_APP_API_TROUTER}/genbank/get-geatway`,
      `https://trouter.domion.tech/genbank/get-geatway`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      const data = await response.json();
      genbankData.value = data[0];
    }
  } catch (error) {
    // console.error('There was a problem with the fetch operation:', error);
    throw error;
  }
};

const getPaymentItemDebt = async () => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await fetch(
      // `${config.VUE_APP_API_TROUTER}/genbank/agent-debt`,
      `https://trouter.domion.tech/genbank/agent-debt`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify({
          agentId: String(agentId.value),
          // agentId: "185",
          ls: payload.value.ls,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      const data = await response.json();
      debtSum.value = data;
      // console.log(data, 'debt');
    }
  } catch (error) {
    // console.error('There was a problem with the fetch operation:', error);
    throw error;
  }
};

watch(
  () => props.agentGroupItems,
  (value) => {
    if (value) {
      if (props.deepModalData) {
        agentId.value = props.deepModalData.agentId;
        getPaymentFormFields(agentId.value);
        agentGroup.value = value.find((item) => item.agentId === agentId.value);
        return;
      }

      agentId.value = value[0].agentId;
      getPaymentFormFields(value[0].agentId);
      agentGroup.value = value[0];
    }
  },
  { deep: true }
);

onMounted(() => {
  getPaymentGetaway();
});
</script>
<style lang="scss" scoped>
.datepicker {
  &__label {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 400;
    color: #535352;
  }
}
.form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__two-col {
    display: flex;
    gap: 13px;
  }
}
</style>
