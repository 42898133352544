<template>
<div v-for="(item, idx) in templates" :key="idx" @click="onClick(item)" class="templates__cards-card">
  <div class="templates__cards-card-header">
    <div class="templates__cards-card-header-inner">
      <div class="templates__cards-card-header-icon">
        <mdicon name="waves" size="24" />
      </div>
      <div class="templates__cards-card-header-info">
        <div class="templates__cards-card-header-info-title">{{ item.name }}</div>
        <div class="templates__cards-card-header-info-subtitle">{{ item.firm }}</div>
      </div>
    </div>
    <div class="templates__cards-card-header-arrow">
      <mdicon name="chevron-right" size="24" />
    </div>
  </div>
  <div class="templates__cards-card-subheader">
    <div class="templates__cards-card-subheader-icon">
      <mdicon name="wallet" size="24" />
    </div>
    <div class="templates__cards-card-subheader-title">Задолженность</div>
  </div>
  <div class="templates__cards-card-sum">{{ item.debt }} руб.</div>
</div>
</template>
<script setup>
defineProps({
  templates: Array
})

const emit = defineEmits(['onElemClick']);

const onClick = (item) => {
  emit('onElemClick', item);
}
</script>
<style scoped lang="scss">
.templates__cards {
  &-card {
    padding: 18px 15px;
    background: #F8F8F8;
    border-radius: 15px;
    cursor: pointer;

    &-header {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-inner {
        display: flex;
        align-items: center;
        gap: 12px;
      }

      &-icon {
        background-color: #FFFFFF;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--primary-color);
      }

      &-info {
        display: flex;
        flex-direction: column;
        gap: 5px;

        &-title {
          font-size: 16px;
          font-weight: 700;
        }

        &-subtitle {
          font-size: 16px;
          font-weight: 600;
          opacity: .5;
        }
      }

      &-arrow {
        margin-left: 50px;
        background-color: #FFFFFF;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--primary-color); 
      }
    }

    &-subheader {
      display: flex;
      align-items: center;
      gap: 25px;

      &-icon {
        background-color: #FFE2E280;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FF0000; 
      }

      &-title {
        font-size: 16px;
        font-weight: 700;
      }
    }

    &-sum {
      margin-top: 10px;
      font-size: 16px;
      font-weight: 700;
      color: #FF0000; 
    }
  }
}
</style>