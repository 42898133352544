<template>
  <notification-message />
  <media-splash-screen />
  <router-view></router-view>
</template>
<script setup>
import NotificationMessage from './components/NotificationMessage.vue';
import { MediaSplashScreen } from '@/components';
import { useTitle } from '@vueuse/core';
import { onBeforeMount } from 'vue';

const THEME = config.VUE_APP_THEME
const title = useTitle();

onBeforeMount(() => {
  title.value = THEME === 'domion' ? 'Домион' : 'Гринвуд';
});
</script>
