<template>
  <base-popup
    class="modal"
    topIndent="80"
    size="extra-large"
    :is-visible="isModalVisible"
    title="Новый сенсор"
    @close="onModalClose"
  >
    <div>
      <div class="input-item">
        <label class="model-label">
          <span class="gray">Название*:</span>
          <input
            type="text"
            placeholder="\"
            class="modal-input"
            v-model="currentSensor.name"
          />
        </label>
        <v-select
          class="claim-modal-select modal-select"
          :options="typeOptions"
          v-model="currentSensor.type"
          placeholder="Выберите тип*"
          :reduce="(value) => value.id"
          label="value"
        >
          <template v-slot:no-options>
            <div>Извините, ничего не найдено</div>
          </template>
        </v-select>
      </div>
      <div class="mb-20">
        <BaseRadioButtonGroup
          @change="onLocationChange"
          :options="locationsRadioButtonList"
          :isHorizontal="true"
          name="locations"
          :defaultValue="locationValue"
          class="mb-3"
        />
        <div class="input-item">
          <div class="claim-modal-select">
            <v-select
              class="modal-select"
              :options="complexOptions"
              placeholder="Выберите комплекс"
              :reduce="(value) => value.id"
              label="value"
              v-model="currentSensor.complexId"
              v-if="locationValue === 'complex'"
            >
              <template v-slot:no-options>
                <div>Извините, ничего не найдено</div>
              </template>
            </v-select>
            <v-select
              class="modal-select"
              :options="buildingOptions"
              placeholder="Выберите здание"
              :reduce="(value) => value.id"
              label="value"
              v-model="currentSensor.buildingId"
              v-else-if="locationValue === 'building'"
            >
              <template v-slot:no-options>
                <div>Извините, ничего не найдено</div>
              </template>
            </v-select>
          </div>
          <label class="model-label">
            <span class="gray">Адрес видео стрима:</span>
            <input
              type="text"
              placeholder="\"
              class="modal-input"
              v-model="currentSensor.stream"
            />
          </label>
          <!-- <v-select
            class="claim-modal-select modal-select"
            :options="modelOptions"
            placeholder="Выберите модель"
          >
            <template v-slot:no-options>
            <div v-if="!buildingValue">Сначала выберите дом</div>
            <div v-else>Извините, ничего не найдено</div>
          </template>
          </v-select> -->
        </div>
      </div>
      <div class="input-item">
        <label class="model-label">
          <span class="gray">Серийный номер*:</span>
          <input
            type="text"
            placeholder="№"
            class="modal-input"
            v-model="currentSensor.serialNumber"
          />
        </label>
        <label class="model-label">
          <span class="gray">Аккаунт:</span>
          <input
            type="text"
            placeholder="\"
            class="modal-input"
            v-model.number="currentSensor.account"
          />
        </label>
      </div>
      <div class="input-item">
        <label class="model-label">
          <span class="gray">IP:</span>
          <input
            type="text"
            placeholder="\"
            class="modal-input"
            v-model="currentSensor.ip"
          />
        </label>
        <label class="model-label">
          <span class="gray">Порт:</span>
          <input
            type="text"
            placeholder="\"
            class="modal-input"
            v-model.number="currentSensor.port"
          />
        </label>
      </div>
      <div class="input-item">
        <label class="model-label">
          <span class="gray">Логин:</span>
          <input
            type="text"
            placeholder="\"
            class="modal-input"
            v-model="currentSensor.login"
          />
        </label>
        <label class="model-label">
          <span class="gray">Пароль:</span>
          <input
            type="text"
            placeholder="\"
            class="modal-input"
            v-model="currentSensor.password"
          />
        </label>
      </div>
    </div>
    <div class="text-right mt-24">
      <base-button
        text="Отменить"
        color="gray"
        class="mr-3"
        @click="onModalClose"
      />
      <base-button
        text="Сохранить"
        color="green"
        @click="onFormSubmit"
        :disabled="isFormDataInvalid"
      />
    </div>
  </base-popup>
</template>
<script setup>
import { defineEmits, defineProps, ref, watch, computed } from 'vue';
import {
  BasePopup,
  BaseButton,
  BaseRadioButtonGroup,
} from '@/components/atoms';
import {
  getAllComplexes,
  getBuildings,
  createSensor,
  updateSensor,
} from '@/graphql/Sensors.graphql';
import { useQuery, useMutation } from '@vue/apollo-composable';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useStore } from 'vuex';
const store = useStore();

const emit = defineEmits(['close', 'create', 'update']);

const props = defineProps({
  isModalVisible: {
    type: Boolean,
    default: false,
  },
  sensor: {},
});

watch(
  () => props.sensor,
  (newValue) => {
    if (newValue) {
      // console.log('sensor', newValue);
      currentSensor.value = JSON.parse(JSON.stringify(newValue));
      const data = JSON.parse(newValue.data.data);
      currentSensor.value.account = newValue.data.account;
      currentSensor.value.ip = data.ip;
      currentSensor.value.port = data.port;
      currentSensor.value.login = data.login;
      currentSensor.value.password = data.password;
      currentSensor.value.stream = data.stream;
      if (newValue.complex) {
        locationValue.value = 'complex';
        currentSensor.value.complexId = newValue.complex.id;
      } else if (newValue.building) {
        locationValue.value = 'building';
        currentSensor.value.buildingId = newValue.building.id;
      }
    } else {
      currentSensor.value = ref({ ...emptySensor }).value;
    }
  }
);

const { result: allComplexes } = useQuery(getAllComplexes);

watch(allComplexes, (newValue) => {
  // console.log(newValue);
  complexOptions.value = newValue.getAllComplexes.map(({ id, name }) => {
    return {
      id,
      value: name,
    };
  });
});
const { result: allBuildings } = useQuery(getBuildings);

watch(allBuildings, (newValue) => {
  // console.log(newValue);
  buildingOptions.value = newValue.getBuildings.map(({ id, number }) => {
    return {
      id,
      value: `Дом ${number}`,
    };
  });
});

const emptySensor = {
  id: null,
  complexId: null,
  buildingId: null,
  type: null,
  name: '',
  serialNumber: '',
  stream: '',
  ip: '',
  port: null,
  login: '',
  password: '',
  account: '',
};
const currentSensor = ref({
  id: null,
  complexId: null,
  buildingId: null,
  type: null,
  name: '',
  serialNumber: '',
  stream: '',
  ip: '',
  port: null,
  login: '',
  password: '',
  account: null,
});

const rules = computed(() => {
  return {
    name: { required },
    type: { required },
    serialNumber: { required },
  };
});

const v$ = useVuelidate(rules, currentSensor);

const isFormDataInvalid = computed(() => {
  return v$.value.$invalid;
});

const typeOptions = ref([
  { value: 'Домофон', id: 'DOMFON' },
  { value: 'Шлагбаум', id: 'SHLAGBAUM' },
  { value: 'Камера', id: 'CAMERA' },
]);
const complexOptions = ref([]);
const buildingOptions = ref([]);
// const modelOptions = ref(['Модель1', 'Модель2']);

// const locationsDefaultValue = ref('complex');

const locationsRadioButtonList = [
  { label: 'Комплекс', value: 'complex' },
  { label: 'Здание', value: 'building' },
];

const locationValue = ref('complex');
const onLocationChange = (value) => {
  locationValue.value = value;
  currentSensor.value.buildingId = null;
  currentSensor.value.complexId = null;
};

const { mutate: createSensorMutation } = useMutation(createSensor);
const { mutate: updateSensorMutation } = useMutation(updateSensor);

const onSensorCreate = async () => {
  const {
    complexId,
    buildingId,
    type,
    name,
    serialNumber,
    stream,
    ip,
    port,
    login,
    password,
    account,
  } = currentSensor.value;
  // console.log('submited', currentSensor.value);

  const { data } = await createSensorMutation({
    data: {
      sensor: {
        complexId,
        buildingId,
        type,
        name,
        serialNumber,
      },
      data: {
        ip,
        port,
        login,
        password,
        account,
        stream,
      },
    },
  });
  // console.log('data', data);
  emit('create');
};

const onSensorUpdate = async () => {
  const {
    id,
    complexId,
    buildingId,
    type,
    name,
    serialNumber,
    stream,
    ip,
    port,
    login,
    password,
    account,
  } = currentSensor.value;
  // console.log('updated', currentSensor.value);

  await updateSensorMutation({
    data: {
      sensor: {
        id,
        complexId,
        buildingId,
        type,
        name,
        serialNumber,
      },
      data: {
        ip,
        port,
        login,
        password,
        account,
        stream,
      },
    },
  });
  emit('update');
};

const onFormSubmit = async () => {
  if (props.sensor) {
    await onSensorUpdate();
  } else {
    await onSensorCreate();
  }
  store.dispatch('notification/showNotification', {
    text: `Сенсор успешно ${props.sensor ? 'обновлен' : 'добавлен'}`,
    type: 'success',
  });
  onModalClose();
};

const onModalClose = () => {
  // currentNews.value = ref({ ...emptyNews }).value;
  emit('close');
  locationValue.value = 'complex';
  currentSensor.value = ref({ ...emptySensor }).value;
};
</script>
<style scoped lang="scss">
@import '~@/assets/styles/_colors';
.model-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 45%;
}
.model-label span {
  margin-right: 20px;
  white-space: nowrap;
}
.input-item {
  display: flex;
  justify-content: space-between;
}
.input-item:not(:last-child) {
  margin-bottom: 20px;
}
.modal-input {
  border: 1px solid map-get($lightgray-stroke, 'normal');
  border-radius: 10px;
  padding: 6px 10px;
  font-family: 'Golos';
  font-size: 16px;
  font-weight: 400;
  width: 100%;
}
.claim-modal-select {
  flex: 0 0 45%;
}
.mb-20 {
  margin-bottom: 20px;
}
</style>
