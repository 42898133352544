<template>
    <the-sidebar :data="links"></the-sidebar>
</template>

<script>
import { TheSidebar } from '@/components';

export default {
    name: 'ClientSidebar',
    components: { TheSidebar },
    data() {
    return {
        links: [
            {
                url: '/app/client/main',
                name: 'Главная',
                icon: 'view-dashboard',
            },
            {
                url: '/app/client/claims',
                name: 'Заявки',
                icon: 'message-text',
            },
            {
                url: '/app/client/payments',
                name: 'Оплаты',
                icon: 'credit-card-outline',
            },
        ],
    };
    },
};
</script>
