<template>
  <div class="card">
    <mdicon
      @click="onDownloadFaceId"
      size="24"
      class="download-face"
      name="download"
    />
    <div class="card__body">
      <div class="faceId" @click="onOpenFullImage">
        <img class="faceId__image" :src="clientWithFaceIdData.faceThumbUrl" />
        <mdicon
          size="60"
          class="faceId__magnifying-glass"
          name="magnify-scan"
        />
        <div
          class="faceId__indicator-wrapper"
          v-show="clientWithFaceIdData.isFaceUploaded"
        >
          <mdicon size="25" class="faceId__indicator-icon" name="check" />
        </div>
      </div>
    </div>
    <div class="card__footer">
      <div class="user-info">
        <div class="user-info__item">
          <mdicon class="user-info__icon" size="17" name="account" />
          <div class="user-info__value color-black">
            {{ clientWithFaceIdData.user.firstName }}
            {{ clientWithFaceIdData.user.lastName }}
          </div>
        </div>
        <div class="user-info__item">
          <mdicon
            class="user-info__icon user-info__icon_id"
            size="24"
            name="identifier"
          />
          <div class="user-info__value color-black">
            {{ clientWithFaceIdData.user.id }}
          </div>
        </div>
        <div class="user-info__item">
          <mdicon class="user-info__icon" size="17" name="cellphone" />
          <div class="user-info__value color-black">
            {{ clientWithFaceIdData.user.phone }}
          </div>
        </div>
        <!-- <div class="user-info__item">
          <mdicon class="user-info__icon" size="17" name="email" />
          <div class="user-info__value color-black">
            {{ clientWithFaceIdData.user.email }}
          </div>
        </div> -->
      </div>
    </div>

    <div class="actions">
      <div
        :class="[
          'actions__submit',
          {
            actions__submit_disabled: clientWithFaceIdData.isFaceUploaded,
          },
        ]"
        @click="setApproveStatusForFaceId(true)"
      >
        <mdicon size="35" class="actions__submit-icon" name="check" />
        <div class="actions__submit-value">Подтвердить</div>
      </div>
      <div
        :class="[
          'actions__reject',
          {
            actions__reject_disabled: !clientWithFaceIdData.isFaceUploaded,
          },
        ]"
        @click="setApproveStatusForFaceId(false)"
      >
        <mdicon size="32" class="actions__reject-icon" name="cancel" />
        <div class="actions__reject-value">Отклонить</div>
      </div>
    </div>
    <div class="card__overlay" v-if="isLoading">
      <div>Обновляем...</div>
    </div>
  </div>
</template>

<script>
import { toRefs, watch, ref } from 'vue';

export default {
  name: 'CardClientFaceId',
  props: {
    clientWithFaceIdData: Object,
    clientLoaderData: Object,
  },
  setup(props, { emit }) {
    const { clientWithFaceIdData, clientLoaderData } = toRefs(props);
    const isLoading = ref(false);
    const onOpenFullImage = () => {
      emit('showPhotoModal', clientWithFaceIdData.value);
    };

    const setApproveStatusForFaceId = (status) => {
      emit('setApproveStatusForFaceId', {
        ...clientWithFaceIdData.value,
        isFaceUploaded: status,
      });
    };

    watch(clientLoaderData, (loaderData) => {
      if (loaderData.id === clientWithFaceIdData.value.id) {
        isLoading.value = loaderData.isLoading;
      }
    });

    const onDownloadFaceId = async () => {
      const source = clientWithFaceIdData.value.faceUrl;
      const image = await fetch(source);
      const imageExtension = image.url.split('.').slice(-1);
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);

      const link = document.createElement('a');
      link.href = imageURL;
      link.download = `фото_жильца_${clientWithFaceIdData.value.user.firstName}_${clientWithFaceIdData.value.user.lastName}.${imageExtension}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // const fileName = source.split('/').pop();
      // const el = document.createElement('a');
      // el.setAttribute('href', source);
      // el.setAttribute('download', fileName);
      // document.body.appendChild(el);
      // el.click();
      // el.remove();
    };

    return {
      onOpenFullImage,
      setApproveStatusForFaceId,
      onDownloadFaceId,
      isLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/colors';

.card {
  overflow: hidden;
  border: 1px solid var(--grey-opacity);
  padding: 0 16px;
  box-shadow: 4px 4px 8px var(--black-shadow);
  border-radius: 8px;
  line-height: 22px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;

  &_uploaded {
    background-color: var(--primary-color);
  }

  &__overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    color: var(--white);
    right: 0;
    bottom: 0;
    background-color: var(--black-popup-shadow);
  }

  &:hover {
    .actions {
      bottom: 0;
    }
  }

  &__body {
    padding: 24px 0;
    flex: 1;
    display: flex;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid var(--grey-opacity);
  }

  &__footer {
    padding: 12px 0;
  }
}

.faceId {
  position: relative;
  cursor: pointer;

  &__indicator-wrapper {
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border: 3px solid var(--white);
    background-color: map-get($primary-text, 'hover');
  }

  &__indicator-icon {
    color: var(--white);
  }

  &:hover {
    .faceId__image {
      filter: blur(2px);
    }

    .faceId__magnifying-glass {
      opacity: 0.7;
    }
  }

  &__image {
    border-radius: 50%;
    width: 180px;
    height: 180px;
    object-fit: cover;
    transition: filter 0.2s ease-in-out;
  }

  &__magnifying-glass {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    font-size: 30px;
    color: var(--white);
    transition: opacity 0.2s ease-in-out;
  }
}

.user-info {
  &__item {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }

  &__icon {
    color: map-get($lightblue-text, 'normal');
  }

  &__value {
    font-size: 15px;
    margin-left: 8px;
  }
}

.actions {
  border-radius: 8px;
  bottom: -110px;
  left: 0;
  width: 100%;
  height: 110px;
  position: absolute;
  background-color: map-get($gray, 'normal');
  padding: 12px 16px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  transition: bottom 0.2s ease;

  &__submit,
  &__reject {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: map-get($black-text, 'normal');

    &_disabled {
      opacity: 0.6;
      pointer-events: none;
    }

    &-icon {
      transition: color 0.15s ease-in;
    }

    &-value {
      font-size: 16px;
      transition: color 0.15s ease-in;
    }
  }

  &__submit {
    .actions__submit-value {
      margin-left: 8px;
    }

    &:hover {
      .actions__submit-icon,
      .actions__submit-value {
        color: map-get($primary-text, 'hover');
      }
    }
  }

  &__reject {
    .actions__reject-value {
      margin-left: 12px;
    }

    &:hover {
      .actions__reject-icon,
      .actions__reject-value {
        color: map-get($red, 'dark');
      }
    }
  }
}

.download-face {
  color: map-get($black-text, 'normal');
  position: absolute;
  top: 8px;
  left: 8px;
  cursor: pointer;
}
.user-info__icon_id {
  margin-left: -3px;
}
</style>
