export const addCookie = (cname, cvalue, exdays) => {
    const d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    const expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + JSON.stringify(cvalue) + ';' + expires + ';path=/'
}

export const getCookie = (cname) => {
    const name = cname + '='
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) == ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
            const value = c.substring(name.length, c.length)
            return JSON.parse(value)
        }
    }
    return false
}

export const deleteCookie = (cname) => {
    document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
}

export const deleteAllCookies = () => {
    const cookies = document.cookie.split('; ')
    for (let c = 0; c < cookies.length; c++) {
        const d = window.location.hostname.split('.')
        while (d.length > 0) {
        const cookieBase =
            encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
            '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
            d.join('.') +
            ' ;path='
        const p = location.pathname.split('/')
        document.cookie = cookieBase + '/'
        while (p.length > 0) {
            document.cookie = cookieBase + p.join('/')
            p.pop()
        }
        d.shift()
        }
    }
}