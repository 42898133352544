<template>
  <div class="mt-40">
    <base-table>
      <template v-slot:head>
        <base-table-row>
          <base-table-head-cell width="20%">Исполнитель</base-table-head-cell>
          <base-table-head-cell width="20%">Категория</base-table-head-cell>
          <base-table-head-cell width="15%">Должность</base-table-head-cell>
          <base-table-head-cell width="10%">Комплексы</base-table-head-cell>
          <base-table-head-cell width="25%">Контакты</base-table-head-cell>
          <base-table-head-cell width="5%"></base-table-head-cell>
          <base-table-head-cell width="5%"></base-table-head-cell>
        </base-table-row>
      </template>
      <template v-slot:body>
        <base-table-row
          class="table-row"
          v-for="executor in executorsList"
          :key="executor.id"
        >
          <base-table-cell width="20%">
            <p class="clip-2">{{ executor.name }}</p></base-table-cell
          >
          <base-table-cell width="20%">
            <div class="clip-2">
              <p
                v-for="category in executor.categories"
                :key="category.category.id"
              >
                {{ category.category.title }}&nbsp;
              </p>
            </div>
          </base-table-cell>
          <base-table-cell width="15%">
            <p class="clip-2">
              {{ executor.post.title }}
            </p>
          </base-table-cell>
          <base-table-cell width="10%">
            <div class="clip-2">
              <p v-for="complex in executor.complexes" :key="complex.id">
                {{ complex.complex.name }}&nbsp;
              </p>
            </div>
          </base-table-cell>
          <base-table-cell width="25%"
            >{{ executor.phone }}&nbsp; <br />{{
              executor.email
            }}</base-table-cell
          >
          <base-table-cell width="5%">
            <div class="icon">
              <mdicon size="30" name="account-box" v-if="executor.messenger" />
              <a
                :href="executor.messenger"
                target="_blank"
                class="icon-link"
              ></a>
            </div>
          </base-table-cell>
          <base-table-cell
            width="5%"
            @click="onClick(executor)"
            class="activity-icon"
          >
            <mdicon size="20" name="pencil" />
          </base-table-cell>
        </base-table-row>
      </template>
    </base-table>
  </div>
</template>
<script setup>
import { defineProps, defineEmits } from 'vue';
import {
  BaseTable,
  BaseTableCell,
  BaseTableHeadCell,
  BaseTableRow,
} from '@/components/BaseTable';
const props = defineProps({
  executorsList: {
    type: Array,
    required: true,
  },
});
// console.log('executorList', props.executorsList);
const emit = defineEmits(['click']);

const onClick = (executor) => {
  emit('click', executor);
};
</script>
<style scoped>
.activity-icon {
  cursor: pointer;
}
.icon {
  cursor: pointer;
  position: relative;
}
.icon-link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
</style>
