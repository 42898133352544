<template>
<div class="center">
  <img :src="emptyImg" :alt="Object.entries(route.query)[0][0]"/>
  <div v-html="message" class="center__title" />
  <base-button
    color="green"
    text="В приложение"
    size="big"
    @click="goOnMobile"
  ></base-button>
</div>
</template>
<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { BaseButton } from '@/components/atoms';
import emptyDomion from '@/assets/images/empty-claims.png';
import emptyGreen from '@/assets/images/claims-green/empty.png';

const THEME = config.VUE_APP_THEME

const route = useRoute();

const emptyImg = THEME === 'domion' ? emptyDomion : emptyGreen;

const goOnMobile = () => {
  window.open('https://xn--b1abeozqy.xn--p1ai/prilozhenie-grin-dom/', '_blank');
}

const message = computed(() => {
  let baseMessage;
  switch (Object.entries(route.query)[0][0]) {
    case 'user-unset':
      baseMessage = `Пользователь не настроен
      Настройте его в приложении
      `;
      break;
    case 'user-not-found':
      baseMessage = `Пользователь не зарегистрирован 
      Зарегистрируйте его в приложении
      `;
      break;
    default:
      baseMessage = '404';
  }
  return baseMessage.replace(/\n/g, '<br>');
});
</script>
<style scoped lang="scss">
.center {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &__title {
    margin-bottom: 30px;
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
  }
}
</style>