<template>
  <div class="cameras">
    <div class="results d-flex align-items-center justify-content-between mb-3">
      <div class="d-flex">
        <div class="results__title">Камеры видеонаблюдения</div>
      </div>
      <div class="d-flex justify-content-end">
        <base-button
          @click="onManagerModalOpen"
          color="green"
          size="m"
          text="Добавить камеру"
        />
      </div>
    </div>

    <slot v-if="isLoading"
      ><div class="d-flex justify-content-center mt-5">
        <img
          :src="THEME === 'domion' ? loaderDomion : loaderGreen"
          alt="loading"
        /></div
    ></slot>
    <slot v-else>
      <div v-if="cameras.length">
        <div class="cameras__list-view">
          <base-table>
            <template v-slot:head>
              <base-table-row>
                <base-table-head-cell width="30%">Камера</base-table-head-cell>
                <base-table-head-cell width="30%"
                  >Ссылка на ресурс</base-table-head-cell
                >
                <base-table-head-cell width="30%"
                  >Комплекс</base-table-head-cell
                >
                <base-table-head-cell width="10%"></base-table-head-cell>
              </base-table-row>
            </template>
            <template v-slot:body>
              <base-table-row v-for="camera in cameras" :key="camera.id">
                <base-table-cell width="30%" class="color-black">
                  <div class="d-flex align-items-center">
                    <img
                      class="new-complex__camera-thumb mr-3"
                      v-if="camera.thumbnail"
                      :src="camera.thumbnail"
                      alt=""
                    />
                    <p>{{ camera.name }}</p>
                  </div>
                </base-table-cell>
                <base-table-cell :title="camera.serialNumber" width="30%">
                  <a class="link" :href="camera.serialNumber" target="_blank"
                    >Просмотреть</a
                  >
                </base-table-cell>
                <base-table-cell width="30%">{{
                  camera.complex.name || '-'
                }}</base-table-cell>
                <base-table-cell width="10%">
                  <div
                    v-if="role !== 'MANAGER'"
                    class="d-flex justify-content-end w-100 actions"
                  >
                    <mdicon
                      size="17"
                      name="pencil"
                      class="actions__item mr-3"
                      @click="onManagerUpdate(camera)"
                    ></mdicon>
                    <mdicon
                      size="17"
                      name="delete"
                      class="actions__item"
                      @click="onCameraDelete(camera)"
                    ></mdicon>
                  </div>
                </base-table-cell>
              </base-table-row>
            </template>
          </base-table>
        </div>
      </div>
      <div v-else class="new-complex__wrapper">
        <div
          class="new-complex d-flex align-items-center justify-content-center"
          @click="setNewCameraPopupVisibility(true)"
        >
          <div
            class="new-complex__plus d-flex align-items-center justify-content-center"
          >
            +
          </div>
          <p class="new-complex__txt">Добавить камеру</p>
        </div>
      </div>
    </slot>
  </div>

  <camera-modal
    :camera="selectedCamera"
    :is-modal-visible="isNewCameraPopupVisible"
    @update="onCameraUpdated"
    @create="onCameraCreated"
    @close="setNewCameraPopupVisibility(false)"
  />
</template>

<script>
import { ref, watch } from 'vue';
import { useQuery, useMutation } from '@vue/apollo-composable';
// import { deleteManager} from '@/graphql/Managment.graphql';
import { getAllCameras, deleteCamera } from '@/graphql/Cameras.graphql';
import BaseButton from '@/components/atoms/components/BaseButton';
import CameraModal from './CameraModal';
// import { THEME } from '@/constant/main';

import {
  BaseTable,
  BaseTableCell,
  BaseTableHeadCell,
  BaseTableRow,
} from '@/components/BaseTable';
import { useStore } from 'vuex';

export default {
  name: 'cameras-page',
  components: {
    BaseTable,
    BaseTableRow,
    BaseTableCell,
    BaseTableHeadCell,
    BaseButton,
    CameraModal,
  },
  setup() {
    const store = useStore();
    const role = store.getters['app/userRole'];
    const isLoading = ref(true);
    const isNewCameraPopupVisible = ref(false);
    const selectedCamera = ref(null);
    const view = ref('list');
    const cameras = ref([]);
    const loaderDomion = require('@/assets/images/loader.svg');
    const loaderGreen = require('@/assets/images/loader-green.svg');
    // const themeModule = require('@/constant/main');
    const THEME = config.VUE_APP_THEME;
    // // console.log('th', THEME.THEME);

    const { mutate: deleteCameraMutation } = useMutation(deleteCamera);

    const { result } = useQuery(getAllCameras);

    watch(result, (value) => {
      cameras.value = value.getAllCameras;
      isLoading.value = false;
    });

    const onManagerModalClosed = () => {
      setNewCameraPopupVisibility(false);
    };

    const onManagerModalOpen = () => {
      setNewCameraPopupVisibility(true);
    };

    const setNewCameraPopupVisibility = (status) => {
      isNewCameraPopupVisible.value = status;

      if (!status) {
        selectedCamera.value = null;
      }
    };

    const onManagerUpdate = (manager) => {
      selectedCamera.value = manager;
      setNewCameraPopupVisibility(true);
    };

    const onCameraUpdated = (camera) => {
      cameras.value = cameras.value.map((item) => {
        if (item.id === camera.id) {
          return { ...camera };
        }
        return item;
      });
    };

    const onCameraDelete = async (camera) => {
      //manager
      const isCameraShouldBeDeleted = confirm(
        `Вы действительно хотите удалить камеру ${camera.name}`
      );
      if (isCameraShouldBeDeleted) {
        await deleteCameraMutation({
          id: camera.id,
        });

        cameras.value = cameras.value.filter((item) => item.id !== camera.id);
      }
    };

    const onCameraCreated = (camera) => {
      // console.log('log camera', camera);
      //manager
      // console.log('!!!!!cameras.valuecameras.value ', [
      //   ...cameras.value,
      //   camera,
      // ]);

      cameras.value = [...cameras.value, camera];
    };

    return {
      isLoading,
      cameras,
      view,
      role,
      isNewCameraPopupVisible,
      selectedCamera,
      onCameraDelete,
      onCameraCreated,
      onManagerUpdate,
      onManagerModalOpen,
      setNewCameraPopupVisibility,
      onManagerModalClosed,
      onCameraUpdated,
      loaderDomion,
      loaderGreen,
      THEME,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_colors';

.new-complex {
  width: 300px;
  height: 150px;
  padding: 10px;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  background: var(--white-bg);
  border: 1px dashed var(--primary-range-color);
  flex-direction: column;
  border-radius: 10px;
  cursor: pointer;

  &__plus {
    width: 80px;
    height: 80px;

    font-size: 112px;
    font-weight: normal;
    color: var(--button-disable-color);
    border-radius: 40px;
    background: var(--primary-range-color);
    opacity: 0.6;
  }

  &__txt {
    position: relative;
    bottom: -14px;

    font-weight: bold;
    color: var(--primary-range-color);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
  }

  &__camera-thumb {
    width: 45px;
    height: 45px;
    border: 1px solid var(--light-bg);
    border-radius: 50%;
  }

  &:hover &__plus {
    opacity: 0.8;
  }
}

.results {
  &__view-switcher {
    cursor: pointer;
    color: map-get($primary-text, 'normal');
    padding: 4px;
    border-radius: 4px;
    box-shadow: 0 4px 4px var(--black-popup-shadow);

    &_active {
      cursor: default;
      color: map-get($primary-text, 'hover');

      box-shadow: 0 4px 4px var(--primary-light-opacity-color);
    }
  }

  &__title {
    color: map-get($black-text, 'normal');
    font-size: 18px;
    margin: 0;
    font-weight: 600;
  }
}

.cameras {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &__list-view {
    .actions {
      &__item {
        color: map-get($primary-text, 'default');
        cursor: pointer;
        padding: 5px;
        //transition: color .2s linear;

        &:hover {
          //color: map-get($primary-text, 'hover');
        }
      }
    }
  }

  &__card-view {
    display: grid;
    grid-template-columns: repeat(auto-fill, 1fr);
    gap: 20px;
  }
}
</style>
