<template>
<div class="templates">
  <div class="templates__header">
    <h1 class="header">Шаблоны</h1>
    <div class="templates__header-info">
      <div class="templates__header-info-badge">
        <div class="templates__header-info-badge-icon">
          <mdicon name="wallet" size="24" />
        </div>
        <div class="templates__header-info-badge-text">
          <div class="templates__header-info-badge-text-title">Общая задолженность</div>
          <div class="templates__header-info-badge-text-sum">-1465,31 руб.</div>
        </div>
      </div>
      <base-button 
        text="Оплатить все" 
        color="green" 
        size="biggest" 
        @click="onModalOpen"
      />
    </div>
  </div>

  <div class="templates__cards">
    <SetupTemplatesCard :templates="templates" @onElemClick="onElemClick" />
  </div>
</div>

<SetupTemplatesModal
  :isModalVisible="isModalVisible"
  :template="template"
  @openInfoModal="onInfoModalOpen"
  @submit="onSubmit"
  @close="onModalClose"
/>

<SetupInfoModal 
  :isModalVisible="isInfoModalVisible" 
  title="Вы действительно хотите удалить этот шаблон?"
  text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
  :icon="{
    name: 'trash-can',
    color: '#FF0000',
    size: 35
  }"
  colorButton="#FF0000"
  closeButton
  textButton="Подтвердить"
  @close="onInfoModalClose"
/>
</template>
<script setup>
import { BaseButton } from '@/components/atoms/';
import { ref } from 'vue';
import SetupTemplatesCard from './SetupTemplatesCard';
import SetupTemplatesModal from './SetupTemplatesModal.vue';
import SetupInfoModal from '../SetupComponents/SetupInfoModal.vue';

const isModalVisible = ref(false);
const templates = ref([
  {
    name: "Электроэнергия",
    firm: "КрымЭнерго",
    debt: "-465,31"
  },
  {
    name: "Электроэнергия",
    firm: "КрымЭнерго",
    debt: "-465,31"
  },
  {
    name: "Электроэнергия",
    firm: "КрымЭнерго",
    debt: "-465,31"
  },
  {
    name: "Электроэнергия",
    firm: "КрымЭнерго",
    debt: "-465,31"
  },
]);
const template = ref(null);
const isInfoModalVisible = ref(false);

const onInfoModalOpen = () => {
  isInfoModalVisible.value = true;
}

const onInfoModalClose = () => {
  isInfoModalVisible.value = false;
}

const onElemClick = (item) => {
  isModalVisible.value = true;
  template.value = item;
}

const onModalOpen = () => {
  isModalVisible.value = true;
}

const onModalClose = () => {
  isModalVisible.value = false;
  template.value = false;
}
</script>
<style scoped lang="scss">
.templates {
  &__header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-info {
      padding: 15px 10px;
      display: flex;
      align-items: center;
      gap: 30px;
      background-color: #FFFFFF;
      border-radius: 15px;

      &-badge {
        display: flex;
        gap: 15px;
      
        &-icon {
          background-color: #FFE2E280;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #FF0000; 
        }

        &-text {
          &-title {
            font-size: 16px;
            font-weight: 700;
          }

          &-sum {
            margin-top: 10px;
            font-size: 16px;
            font-weight: 700;
            color: #FF0000; 
          }
        }
      }
    }
  }

  &__cards {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
  }
}
</style>