<template>
    <div class="d-flex">
        <h1 class="header header_black">Должники</h1>
    </div>

    <base-tabs-wrapper @update:selectedTitle="handleTabClick">
        <base-tab title="Информация о должниках">
            <div>
                <div v-if="isLoading" class="mt-20 text-center">
                    <img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" />
                </div>
                <DebtorsInfo v-else
                    :debtors="allDebtors"
                    :pagination="pagination"
                    :isSettingsEmpty="isSettingsEmpty"
                    :filters="filters.filters"
                    :allFilters="filters"
                    :isLoadingCheck="isLoadingCheck"
                    :chosedDebtors="chosedDebtors"
                    :debtorsSettings="JSON.parse(debtorsListRules.warnings)"
                    @filter="onChooseFilter"
                    @click="onClickSettingsButton" 
                    @onChangePage="onChangePage"
                    @showFilters="setFilterVisibility(true)" 
                    @clearFilters="clearFilters"
                    @chosedAll="onChosedAll"
                    @uncheckAllUsers="uncheckAllUsers"
                    @searching="onSearching"
                />
            </div> 
        </base-tab>
        <base-tab title="Архив">
            <div>
                <div v-if="isLoading" class="mt-20 text-center">
                    <img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" />
                </div>
                <DebtorsInfo v-else
                    :debtors="allDebtors"
                    :pagination="pagination"
                    :isSettingsEmpty="isSettingsEmpty"
                    :filters="filters.filters"
                    :allFilters="filters"
                    :isLoadingCheck="isLoadingCheck"
                    :chosedDebtors="chosedDebtors"
                    :debtorsSettings="JSON.parse(debtorsListRules.warnings)"
                    @filter="onChooseFilter"
                    @click="onClickSettingsButton" 
                    @onChangePage="onChangePage"
                    @showFilters="setFilterVisibility(true)" 
                    @clearFilters="clearFilters"
                    @chosedAll="onChosedAll"
                    @uncheckAllUsers="uncheckAllUsers"
                    @searching="onSearching"
                />
            </div>
        </base-tab>
    </base-tabs-wrapper>

    <DebtorsFilterModal 
        :key="filterKey"
        :is-modal-visible="isFilterVisible"
        :filtersData="filters.filters"
        :filteredDebtors="filteredDebtors"
        :debtorsListRules="debtorsListRules"
        @onSubmitFilter="onSubmitFilter"
        @close="setFilterVisibility(false)"
    />
    <DebtorsModal
        :is-modal-visible="isDebtorsModalVisible"
        :debtorsListRules="debtorsListRules"
        @close="setDebtorsPopupVisibility(false)"
    />
</template>
<script setup>
import { BaseTabsWrapper, BaseTab } from '@/components/atoms/';
import DebtorsInfo from './DebtorsInfo.vue';
import DebtorsArchive from './DebtorsArchive.vue';
import { ref, reactive, watch } from 'vue';
import DebtorsModal from './DebtorsModal.vue';
import DebtorsFilterModal from './DebtorsFilterModal.vue';
import { getDebtors, getDebtorsSettings } from '@/graphql/Debtors.graphql';
import { useQuery } from '@vue/apollo-composable';
import { useRouter, useRoute } from 'vue-router';
import { EventBus } from '../../utils/eventBus';
import { useStore } from 'vuex';

const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');
const THEME = config.VUE_APP_THEME;

const router = useRouter();
const route = useRoute();
const store = useStore();

const isDebtorsModalVisible = ref(false);
const isFilterVisible = ref(false);
const isLoading = ref(true);
const isLoadingCheck = ref(false);
const isSettingsEmpty = ref(true);
const debtorsListRules = ref({});
const allDebtors = ref([]);
const chosedDebtors = ref([]);
const perPage = ref(5);
const filteredDebtors = ref(0);
const filterKey = ref(0);
const total = ref(0);
const allChecked = ref(false);

const pagination = reactive({
    totalPages: 0,
    currentPage: +route.query.page || 1
});

const filters = reactive({
  pagination: {
    limit: perPage.value,
    offset: 0,
  },
  filters: {
    complexId: 0,
    buildingId: 0,
    debtDur: 0,
    debtSum: 0,
    warnsCount: 0
  }
});

const { result: debtorsSettings } = useQuery(getDebtorsSettings)

const { 
    result: debtors, 
    refetch: refetchDebtors, 
    fetchMore: fetchMoreDebtors 
} = useQuery(getDebtors, filters);

const fetchAllDebtors = async () => {
    let arr;
    isLoadingCheck.value = true;
    filters.pagination.limit = total.value;
    await fetchMoreDebtors({
      filters,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        arr = [ ...fetchMoreResult.getDebtors.list ];
      },
    });
    filters.pagination.limit = 5;
    isLoadingCheck.value = false;
    return arr;
};

const onChosedAll = async () => {
    // console.log('start choose all');

    allChecked.value = true;
    chosedDebtors.value = []; 
    let arr = await fetchAllDebtors();
    chosedDebtors.value = arr; 

    store.dispatch('notification/showNotification', {
        type: 'success',
        text:  `Выбрано ${filteredDebtors.value} пользователей`
    });

    // console.log(chosedDebtors.value, 'Все должники');
};

const uncheckAllUsers = () => {
    allChecked.value = false;
    chosedDebtors.value = []; 
    // console.log('uncheck all');
}

const setDebtorsPopupVisibility = async (status) => {
    isDebtorsModalVisible.value = status;
//   if (!status) {}
};

const setFilterVisibility = async (status) => {
    isFilterVisible.value = status;
    setTimeout(() => {
        filterKey.value += 1;
    }, 300);
//   if (!status) {}
};

const onSearching = async (text) => {
    if (text.length === 0) {
        delete filters.filters.q;
        return;
    }

    filters.filters.q = text;
    await refetchDebtors();
}

const handleTabClick = async (title) => {
    switch (title) {
        case 'Архив':
            filters.filters.archived = true; 
            break;
        default:
            if (filters.filters.archived) delete filters.filters.archived;
    }

    if (filters.filters.subpoenaed) delete filters.filters.subpoenaed;
    if (filters.filters.notified) delete filters.filters.notified;
    await refetchDebtors();
    // console.log(title, 'chosed');
}

const onChooseFilter = async (filter) => {
    switch (filter) {
        case 'notified':
            filters.filters.notified = true;
            if (filters.filters.subpoenaed) delete filters.filters.subpoenaed;
            break;
        case 'subpoenaed':
            filters.filters.subpoenaed = true; 
            if (filters.filters.notified) delete filters.filters.notified;
            break;
        default:
            if (filters.filters.subpoenaed) delete filters.filters.subpoenaed;
            if (filters.filters.notified) delete filters.filters.notified;
            break;
    }

    await refetchDebtors();
    // console.log(filter, 'chosed');
}

const loadMore = async (page = 0) => {
    const activePage = page === 1 ? 0 : page;
    await fetchMoreDebtors({
    variables: {
        pagination: {
            offset: (activePage === 0 ? 0 : activePage - 1) * perPage.value,
            limit: perPage.value,
        },
    },
    updateQuery: (previousResult, { fetchMoreResult }) => {
        // console.log('fetchMoreResult', fetchMoreResult);
        allDebtors.value = [...fetchMoreResult.getDebtors.list];
    },
    });
};

const getTotalPages = (totalItems) => {
    pagination.totalPages = Math.ceil(totalItems / perPage.value);
};

const onChangePage = async (page) => {
    // console.log('page changed');

    router.replace({
        route,
        query: {
            page,
        },
    });

    pagination.currentPage = page;
    // isLoading.value = true;
    await loadMore(page);
    // isLoading.value = false;
    if (allChecked.value) {
        EventBus.emit('check-all-debtors') 
        // checkAllApartments();
    }
};

const onSubmitFilter = async (filtersData) => {
    // // console.log(filtersData, 'filters');
    filters.filters = filtersData
    await refetchDebtors();
}

const clearFilters = async () => {
    filters.filters = {
        complexId: 0,
        buildingId: 0,
        debtDur: 0,
        debtSum: 0,
        warnsCount: 0
    }
    // console.log(filters, 'filters');
    await refetchDebtors();
} 

const onClickSettingsButton = (status) => {
    setDebtorsPopupVisibility(status);
}

watch(debtors, (value) => {
    if (value) {
        allDebtors.value = value.getDebtors.list; 
        filteredDebtors.value = Number(value?.getDebtors.filtered); 
        total.value = Number(value?.getDebtors.filtered);
        getTotalPages(filteredDebtors.value);

        isLoading.value = false;
    }
    // // console.log(value.getDebtors.list, '[DEBTORS]');
});

watch(debtorsSettings, (value) => {
    if (value) {
        debtorsListRules.value = value.getDebtorsSettings;
        // // console.log(debtorsListRules.value, 'data');
        const allValuesAreNotNull = Object.values(value.getDebtorsSettings).every(val => val !== null);
        isSettingsEmpty.value = !allValuesAreNotNull;
    }
    // console.log(value.getDebtorsSettings, 'debtors');
});
</script>
<style scoped lang="scss">
</style>