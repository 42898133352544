<template>
  <the-sidebar :data="links"></the-sidebar>
</template>

<script>
import { TheSidebar } from '@/components';

export default {
  name: 'AdminSidebar',
  components: { TheSidebar },
  data() {
    return {
      links: [
        {
          url: '/app',
          name: 'Что нового?',
          icon: 'view-dashboard',
        },
        {
          url: '/app/admin/clients',
          name: 'Клиенты',
          icon: 'human-handsup',
        },
        {
          url: '/app/admin/clients-with-faceId',
          name: 'Клиенты c FaceId',
          icon: 'face-recognition',
        },
        {
          url: '/app/admin/claims',
          name: 'Заявки',
          icon: 'message-text',
          disabled: false,
        },
        {
          url: '/app/admin/news',
          name: 'Новости',
          icon: 'text-box-multiple',
          disabled: false,
        },
        {
          url: '/app/admin/notifications',
          name: 'Центр уведомлений',
          icon: 'bell-badge',
          disabled: false,
        },
        {
          url: '/app/admin/logs',
          name: 'Центр событий',
          icon: 'calendar-multiple-check',
          disabled: false,
        },
        {
          url: '/app/admin/cameras',
          name: 'Камеры видеонаблюдения',
          icon: 'cctv',
        },
        {
          url: '/app/admin/directory',
          name: 'Справочник',
          icon: 'folder-open',
          disabled: false,
        },
        {
          url: '/app/admin/managers',
          name: 'Менеджеры',
          icon: 'account-supervisor',
        },
        {
          url: '/app/admin/votes',
          name: 'Опросы',
          icon: 'ballot',
          disabled: false,
        },
        {
          url: '/app/admin/sensors',
          name: 'Сенсоры',
          icon: 'calculator',
        },
        {
          url: '/app/admin/debtors',
          name: 'Должники',
          icon: 'account-alert',
        },
      ],
    };
  },
};
</script>
