import { ApolloClient, HttpLink, InMemoryCache, createHttpLink } from '@apollo/client/core';
import { ApolloLink } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import { EventBus } from '../utils/eventBus';
import { getCookie } from '../utils/cookie';
import { linkGreen, linkDomion } from '@/constant/main';
import * as store from '@/store/index'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const isDomionHost = linkDomion.includes(window.location.host);
const isGreenHost = linkGreen.includes(window.location.host);

const THEME = config.VUE_APP_THEME;
const HOST = THEME === 'domion' ? isDomionHost : isGreenHost;

const router = useRouter();

const getApiLink = () => {
  if (localStorage.getItem('apiUrl')) {
    return localStorage.getItem('apiUrl')
  }
  const storedData = store.default.getters['app/getSession']

  if (!storedData) return;

  const data = JSON.parse(storedData);
  const tenants = data.identity.metadata_public.tenants;
  const activeTenant = tenants.active;
  const tenantInfo = tenants.available.find(tenant => tenant.id === activeTenant);

  if (!tenantInfo) return;
  localStorage.setItem('apiUrl', tenantInfo.apiUrl);

  return tenantInfo.apiUrl;
}

// const logout = async () => {
//     await store.default.dispatch('app/logout');
//     router.push('/');
// }

// const newAuthMigrateFunc = () => {
//   const role = store.default.getters['app/userRole'];
//   const session = localStorage.getItem('s');
//   const logoutUrl = localStorage.getItem('logout-url');

  // if (role !== 'CLIENT') {
  //   if (!session || !logoutUrl) logout();
  // } 
  // else {
    // if (!session) logout();
  // }
// }

// ORY
const httpLink = createHttpLink({
  credentials: 'include',
  uri: `${getApiLink()}/graphql`,
});

// GREEN
// const httpLink = createUploadLink({
//   uri:
//     HOST
//     ? `${process.env.VUE_APP_API_PROD}/graphql`
//     : `${process.env.VUE_APP_API_DEV}/graphql`,
// });

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
  },
  query: {
    fetchPolicy: 'network-only',
  },
};

// ORY
const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token');
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : null,
    }
  });

  // newAuthMigrateFunc();
  return forward(operation);
});

// GREEN
// const authMiddleware = new ApolloLink((operation, forward) => {
  // const token = localStorage.getItem('token');

//   operation.setContext({
//     headers: {
//       authorization: token ? `Bearer ${token}` : null,
//     },
//   });

//   return forward(operation);
// });

export const apolloUploadClient = new ApolloClient({
  link: authMiddleware.concat(httpLink),
  cache: new InMemoryCache(),
  credentials: 'include',
  defaultOptions,
});

document.addEventListener('set-graphql-link', 
  EventBus.on('set-graphql-link', () => getApiLink())
);

document.removeEventListener('set-graphql-link', 
  EventBus.off('set-graphql-link', () => getApiLink())
);

// ORY
// window.addEventListener('DOMContentLoaded', function() {
  // getGraphqlApiLinks();
// });