<template>
<base-popup
    size="largest"
    topIndent="80"
    :is-visible="isModalVisible"
    title="Условия попадания в список должников"
    subtitle=""
    @close="onModalClose"
    >
    <div class="debtors-modal__select-block d-flex mt-30">
        <div>
          <span class="select-label">Период задолженности (от)</span>
          <v-select
            v-model="filters.periodFrom"
            :options="periodFrom"
            placeholder="Выберите период задолженности"
            @update:modelValue="onInput($event, 'periodFrom')"
            class="debtors-modal__select-block-select modal-select"
            :reduce="(value) => value.id"
            label="value"
          >
            <template v-slot:no-options>
              <div>Извините, ничего не найдено</div>
            </template>
          </v-select>

          <span class="select-label mt-10">Период задолженности (до)</span>
          <v-select
            v-model="filters.periodTo"
            :options="periodTo"
            placeholder="Выберите период задолженности"
            class="debtors-modal__select-block-select modal-select"
            :reduce="(value) => value.id"
            label="value"
          >
            <template v-slot:no-options>
              <div>Извините, ничего не найдено</div>
            </template>
          </v-select>
        </div>
        <div>
          <span class="select-label">Сумма задолженности (руб.) (от)</span>
          <v-select
            v-model="filters.amountFrom"
            :options="debtSumOptionsFrom"
            @update:modelValue="onInput($event, 'amountFrom')"
            placeholder="Выберите сумму"
            class="debtors-modal__select-block-select modal-select"
            :reduce="(value) => value.id"
            label="value"
          >
            <template v-slot:no-options>
              <div>Извините, ничего не найдено</div>
            </template>
          </v-select>

          <span class="select-label mt-10">Сумма задолженности (руб.) (до)</span>
          <v-select
            v-model="filters.amountTo"
            :options="debtSumOptionsTo"
            placeholder="Выберите сумму"
            class="debtors-modal__select-block-select modal-select"
            :reduce="(value) => value.id"
            label="value"
          >
            <template v-slot:no-options>
              <div>Извините, ничего не найдено</div>
            </template>
          </v-select>
        </div>
    </div>

    <div class="debtors-modal__notify">
      <h1 class="header header_black">Уведомления</h1>

      <base-tabs-wrapper>
        <base-tab title="SMS">
          <SmsTab 
            :errorsNeedToShow="trueKeys.includes('SMS') ? errorsNeedToShow : false" 
            :warnings="notifyList?.sms" 
            @filters-change="onSmsChange" 
          />
        </base-tab>
        <base-tab title="Пуш-уведомление">
          <PushTab 
            :errorsNeedToShow="trueKeys.includes('Пуш-уведомление') ? errorsNeedToShow : false" 
            :warnings="notifyList?.push" 
            @filters-change="onPushChange" 
          />
        </base-tab>
        <base-tab title="E-mail">
          <EmailTab 
            :errorsNeedToShow="trueKeys.includes('E-mail') ? errorsNeedToShow : false" 
            :warnings="notifyList?.email" 
            @filters-change="onEmailChange" 
          />
        </base-tab>
        <base-tab title="Звонки">
          <CallTab 
            :errorsNeedToShow="trueKeys.includes('Звонки') ? errorsNeedToShow : false" 
            :warnings="notifyList?.call" 
            @filters-change="onCallChange" 
          />
        </base-tab>
      </base-tabs-wrapper>
    </div>

    <div class="line"></div>

    <div class="d-flex justify-content-end">
      <base-button
        text="Закрыть"
        color="gray"
        class="mr-3"
        @click.prevent="onModalClose"
      />

      <base-button
        text="Сохранить"
        color="green"
        @click.prevent="onFormSubmit"
      />
    </div>
</base-popup>
</template>
<script setup>
import { BasePopup, BaseTabsWrapper, BaseTab, BaseButton } from '@/components/atoms';
import { defineProps, defineEmits, reactive, watch, ref, onMounted } from 'vue';
import SmsTab from './ModalTabs/SmsTab.vue';
import PushTab from './ModalTabs/PushTab.vue';
import EmailTab from './ModalTabs/EmailTab.vue';
import CallTab from './ModalTabs/CallTab.vue';
import { useStore } from 'vuex';
import { useMutation } from '@vue/apollo-composable';
import { setDebtorsSettings } from '@/graphql/Debtors.graphql';
import { computed } from 'vue';

const emit = defineEmits(['close']);

const props = defineProps({
  isModalVisible: {
    type: Boolean,
    default: false,
  },
  debtorsListRules: Object
});

const store = useStore();

const { mutate: setSettingsMutation, onError: errorSetSettings } = useMutation(setDebtorsSettings);

const errorsNeedToShow = ref(false);
const trueKeys = ref([]);

const periodFrom = ref([
  { id: '10', value: '10 дней' },
  { id: '20', value: '20 дней' },
]);

const periodTo = ref([
  { id: '10', value: '10 дней' },
  { id: '20', value: '20 дней' },
]);

const debtSumOptionsFrom = ref([
  { id: '100', value: '100' }
]);

const debtSumOptionsTo = ref([
  { id: '100', value: '100' }
]);

const errors = ref({
  sms: false,
  push: false,
  email: false,
  call: false,
});

// let filters = reactive({
//   periodFrom: props.debtorsListRules?.periodFrom,
//   periodTo: props.debtorsListRules?.periodTo,
//   amountFrom: props.debtorsListRules?.amountFrom,
//   amountTo: props.debtorsListRules?.amountTo,
//   warnings: props.debtorsListRules?.warnings,
// });

let filters = reactive({
  periodFrom: 0,
  periodTo: 0,
  amountFrom: 0,
  amountTo: 0,
  warnings: 0,
});

const notifyList = ref({
  sms: null,
  push: null,
  email: null,
  call: null,
});

const onInput = (input, key) => {
  // // console.log(input, key, 'logged');
  if (key === 'amountFrom') {
    debtSumOptionsTo.value = [
      { id: '100', value: '100' }
    ];
    for (let i = 500; i <= 1000000; i += 500) {
      debtSumOptionsTo.value.push({ id: String(i), value: `${i}` });
    }
    filters.amountTo = '0';
    const targetId = Number(input);

    while (debtSumOptionsTo.value[0].id <= targetId) {
      debtSumOptionsTo.value.shift();
    }
  }

  if (key === 'periodFrom') {
    periodTo.value = [
      { id: '10', value: '10 дней' },
      { id: '20', value: '20 дней' },
    ];

    for (let i = 1; i <= 36; i ++) {
      periodTo.value.push({ id: `${i * 30.5}`, value: `${i} мес.` })
    }

    filters.periodTo = '0';
    const targetId = Number(input);

    while (periodTo.value[0].id <= targetId) {
      periodTo.value.shift();
    }
  }
}

const inputController = (filters) => {
 for (const key in filters) {
    if (key === 'periods') {
      for (const period of filters.periods) {
        if ((period.period === '' || period.period === null) || period.content === '') {
          return false;
        }
      }
    } else if (filters[key] === '') {
      return false;
    }
 }
 return true;
};

const isAllFieldsEmpty = (filters) => {
 let isAnyFieldFilled = false; 

 for (const key in filters) {
    if (key === 'periods') {
      for (const period of filters.periods) {
        if (!(period.period === '' || period.period === null) || !(period.content === '' || period.content === null)) {
          isAnyFieldFilled = true;
        }
      }
    } else if (key !== 'telegram' && key !== 'viber' && key !== 'whatsapp' && filters[key] !== '') {
      isAnyFieldFilled = true;
    }
 }

 return !isAnyFieldFilled;
};

const onSmsChange = (filtersSms) => {
  let isSomeFieldEmpty = inputController(filtersSms);
  let isFieldsAllEmpty = isAllFieldsEmpty(filtersSms);
  // // console.log('isSomeFieldEmpty', !isSomeFieldEmpty, 'isAllFieldsEmpty', isFieldsAllEmpty);
  if (!isSomeFieldEmpty && !isFieldsAllEmpty) errors.value.sms = true;
  else errors.value.sms = false;

  if (isFieldsAllEmpty) delete notifyList.value.sms;
  else notifyList.value.sms = filtersSms;
}

const onPushChange = (filtersPush) => {
  let isSomeFieldEmpty = inputController(filtersPush);
  let isFieldsAllEmpty = isAllFieldsEmpty(filtersPush);
  // // console.log('isSomeFieldEmpty', !isSomeFieldEmpty, 'isAllFieldsEmpty', isFieldsAllEmpty);
  if (!isSomeFieldEmpty && !isFieldsAllEmpty) errors.value.push = true;
  else errors.value.push = false;

  if (isFieldsAllEmpty) delete notifyList.value.push;
  else notifyList.value.push = filtersPush;
}

const onEmailChange = (filtersEmail) => {
  let isSomeFieldEmpty = inputController(filtersEmail);
  let isFieldsAllEmpty = isAllFieldsEmpty(filtersEmail);
  // // console.log('isSomeFieldEmpty', !isSomeFieldEmpty, 'isAllFieldsEmpty', isFieldsAllEmpty);
  if (!isSomeFieldEmpty && !isFieldsAllEmpty) errors.value.email = true;
  else errors.value.email = false;

  if (isFieldsAllEmpty) delete notifyList.value.email;
  else notifyList.value.email = filtersEmail;
}

watch(() => filters, (value) => {
  
  // console.log(value, 'changed filter');
}, { deep: true });

const onCallChange = (filtersCall) => {
  let isSomeFieldEmpty = inputController(filtersCall);
  let isFieldsAllEmpty = isAllFieldsEmpty(filtersCall);
  // // console.log('isSomeFieldEmpty', !isSomeFieldEmpty, 'isAllFieldsEmpty', isFieldsAllEmpty);
  if (!isSomeFieldEmpty && !isFieldsAllEmpty) errors.value.call = true;
  else errors.value.call = false;

  if (isFieldsAllEmpty) delete notifyList.value.call;
  else notifyList.value.call = filtersCall;
}

watch(() => notifyList, (value) => {
  const filteredValue = Object.fromEntries(
    Object.entries(value.value).filter(([key, val]) => val !== null)
  );

  filters.warnings = JSON.stringify(filteredValue);

  // console.log(filters, 'final filters');
}, { deep: true });

const onModalClose = (isSubmit) => {
  isSubmit !== true ? (isSubmit = false) : (isSubmit = true);
  let isClosed = true;
  if (!isSubmit) {
    isClosed = confirm(
      'Вы действительно хотите закрыть окно? Внесенные изменения не сохранятся'
    );
  }
  if (isClosed) {
    trueKeys.value = [];
    errorsNeedToShow.value = false;
    errors.value = {
      sms: false,
      push: false,
      email: false,
      call: false,
    };

    emit('close');
  }
};

const errorKeyDefiner = (key) => {
  switch (key) {
    case 'sms':
      return 'SMS'
    case 'push':
      return 'Пуш-уведомление'
    case 'email':
      return 'E-mail'
    case 'call':
      return 'Звонки'
    default:
      return key
  }
}

const onFormSubmit = async () => {
  const hasError = Object.values(errors.value).some(value => value === true);
  trueKeys.value = Object.entries(errors.value)
    .filter(([key, value]) => value === true)
    .map(([key, value]) => errorKeyDefiner(key));
  
  // console.log(trueKeys, 'keys');

  if (hasError) {
    store.dispatch('notification/showNotification', {
      type: 'error',
      text: `Есть незаполненные поля во вкладках \n${trueKeys.value}\n 
      заполните полностью или удалите содержимое чтобы не использовать`
    });
    errorsNeedToShow.value = true;
    return;
  }

  if (filters.__typename) delete filters.__typename;

  const updatedFilters = Object.fromEntries(
    Object.entries(filters).map(([key, value]) => [key, value === null ? '' : value])
  );

  const { data } = await setSettingsMutation({
    data: updatedFilters,
  });

  if (data) store.dispatch('notification/showNotification', {
    type: 'success',
    text: 'Настройки сохранены!'
  });

  // console.log(data, "AFTER SETTING SETTINGS");
};

watch(() => props.debtorsListRules, (newVal) => {
  const { periodFrom, periodTo, amountFrom, amountTo, warnings } = newVal;

  // console.log(JSON.parse(warnings), 'warnings');
  let parsedWarnings = JSON.parse(warnings);
  // console.log(parsedWarnings, 'hello');

  filters.periodFrom = periodFrom;
  filters.periodTo = periodTo;
  filters.amountFrom = amountFrom;
  filters.amountTo = amountTo;
  filters.warnings = parsedWarnings;

  notifyList.value = parsedWarnings;
  // console.log(notifyList, 'notifyList');
});

onMounted(() => {
  for (let i = 500; i <= 1000000; i += 500) {
    debtSumOptionsFrom.value.push({ id: String(i), value: `${i}` });
  }

  for (let i = 500; i <= 1000000; i += 500) {
    debtSumOptionsTo.value.push({ id: String(i), value: `${i}` });
  }

  for (let i = 1; i <= 36; i ++) {
    periodFrom.value.push({ id: `${i * 30.5}`, value: `${i} мес.` })
  }

  for (let i = 1; i <= 36; i ++) {
    periodTo.value.push({ id: `${i * 30.5}`, value: `${i} мес.` })
  }
});
</script>

<style scoped lang="scss">
.line {
  margin: 30px 0;
  width: 100%;
  height: 1px;
  background-color: #00000047;  
}

.select-label {
  font-size: 12px;
  margin-bottom: 9px;
  display: block;
}

.debtors-modal {
  &__select-block {
    gap: 12px;

    &-select {
      width: 20rem;
    }
  }

  &__notify {
    margin-top: 36px;

    & > h1 {
      font-size: 23px;
      font-weight: 500;
      opacity: 70%;
    }
  }
}
</style>