<template>
  <base-popup
    title="История звонка"
    :subtitle="subTitle"
    size="largest"
    topIndent="80"
    :is-visible="isModalVisible"
    @close="onModalClose"
  >
    <div class="item-wrapper">
      <div class="item">
        <span class="title">Дата:</span>
        <span class="value">{{ setDate }}</span>
      </div>
      <div class="item">
        <span class="title">Время:</span>
        <span class="value">{{ setTime }}</span>
      </div>
      <div class="item">
        <span class="title">Продолжительность звонка:</span>
        <span class="value">{{ currentCallLog.callDuration }}</span>
      </div>
      <div class="item">
        <span class="title">Продолжительность разговора:</span>
        <span class="value">{{ currentCallLog.talkDuration ?? '-' }}</span>
      </div>
    </div>
    <div class="item-wrapper">
      <span class="title mb-3">События:</span>
      <div class="ml-3">
        <div class="item">
          <span class="title">Пуш ушел:</span>
          <span
            class="value"
            v-for="(user, idx) in currentCallLog.pushedUsers"
            :key="idx"
          >
            <span :class="user.success ? 'green' : 'red'">
              {{ user.name
              }}<span
                class="p-0 m-0"
                v-if="idx !== currentCallLog.pushedUsers.length - 1"
                >, &nbsp;</span
              >
            </span>
          </span>
        </div>
        <div class="item">
          <span class="title">На звонок ответил:</span>
          <span class="value"
            >{{ currentCallLog.answerer?.lastName ?? '-' }}
            {{ currentCallLog.answerer?.firstName }}</span
          >
        </div>
        <div class="item">
          <span class="title">Кнопка открытия двери:</span>
          <span
            class="value"
            :class="currentCallLog.isOpened ? 'green' : 'red'"
            >{{ currentCallLog.isOpened ? 'нажата' : 'не нажата' }}</span
          >
        </div>
      </div>
    </div>
    <div class="item-wrapper">
      <span class="title">Вложения:</span>
      <div class="media-wrapper">
        <!-- TODO: Video here -->
        <!-- <div class="item-media">
          <video controls preload="metadata">
            <source type="video/mp4" :src="video" />
          </video>
        </div> -->
        <a
          class="item-media"
          :href="img"
          target="_blank"
          v-for="img in media"
          :key="img"
        >
          <img v-lazy="img" alt="video-call-image" id="call-img" />
        </a>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <base-button text="Закрыть" color="green" @click="onModalClose" />
    </div>
  </base-popup>
</template>
<script setup>
import { defineProps, defineEmits, watch, ref, computed } from 'vue';
import { BasePopup, BaseButton } from '@/components/atoms';
import { getCallInfo } from '@/graphql/Logs.graphql';
import { useQuery } from '@vue/apollo-composable';
const emit = defineEmits(['close']);
const props = defineProps({
  isModalVisible: {
    type: Boolean,
    default: false,
  },
  callLog: {
    type: Object,
  },
});

const currentCallLog = ref({});

let { refetch } = useQuery(getCallInfo, {
  id: 1,
});

watch(
  () => props.callLog,
  async (newValue) => {
    const {
      data: { getCallInfo },
    } = await refetch({
      id: newValue.id,
    });
    currentCallLog.value = getCallInfo;
    // console.log(currentCallLog.value);
  }
);

const onModalClose = () => {
  emit('close');
};

const video = ref(null);

const media = computed(() => {
  if (currentCallLog.value) {
    let imagesArr = [];
    currentCallLog.value?.attach?.map((item) => {
      if (item.split('.').slice(-1)[0] === 'jpg') {
        imagesArr.push(item);
        // for (const item of imagesArr) {
        //   const newImg = new Image();
        //   newImg.onload = () => {
        //     document.getElementById('call-img').src = newImg.src;
        //   };
        //   // console.log(item);
        //   newImg.src = item;
        // }
      } else {
        video.value = item;
      }
    });
    return imagesArr;
  }
  return null;
});

const subTitle = computed(() => {
  return `Квартира ${currentCallLog.value?.apartment?.number} • ${currentCallLog.value?.building?.name} • Объект ${currentCallLog.value?.complex?.name}`;
});

const setDate = computed(() => {
  const currentDate = new Date(currentCallLog.value?.createdAt?.slice(0, -1));
  return currentDate.toLocaleDateString('ru-RU');
});
const setTime = computed(() => {
  const currentDate = new Date(currentCallLog.value?.createdAt?.slice(0, -1));
  return `${String(currentDate.getHours()).padStart(2, '0')}:${String(
    currentDate.getMinutes()
  ).padStart(2, '0')}`;
});
</script>
<style scoped>
.item {
  margin-bottom: 10px;
  display: flex;
}
.title {
  color: var(--grey-text-color);
  font-size: 14px;
  line-height: 1.25;
  margin-right: 10px;
  display: block;
  white-space: nowrap;
}
.value {
  color: var(--black);
  font-weight: 600;
  font-size: 14px;
  line-height: 1.25;
  display: block;
}
.item-wrapper {
  margin-bottom: 40px;
}
.item-media {
  /* max-width: 290px; */
  width: calc(33% - 1rem);
  border-radius: 10px;
  overflow: hidden;
  display: block;
  margin: 1rem 1rem 0 0;
  position: relative;
  z-index: 1;
}
.item-media video {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.item-media img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.media-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
}
.green {
  color: var(--primary-color);
}
.red {
  color: var(--button-error-color);
}
</style>
