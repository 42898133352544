<template>
  <SwipeModal
    v-model="isOpen"
    :is-full-screen="true"
    :is-persistent="true"
    class="swipe-modal"
  >
    <div class="swipe-modal__action">
        <mdicon @click="onPaymentModalClose" name="close" size="30" class="swipe-modal__header-close" />
    </div>
    <div class="payment-modal">
        <div class="payment-modal__head">
            <img src="@/assets/images/genbank-logo.svg" class="payment-modal__head-logo" alt="genbank-logo"/>
            <div class="payment-modal__head-text">Комиссия {{ percent }}%</div>
        </div>

        <div class="payment-modal__content">
            <div class="payment-modal__content-title">Сумма оплаты</div>
            <div class="payment-modal__content-block">
                <input :value="sumPay" @input="onChangeInput" class="payment-modal__content-block-input" type="number" />
                <div class="payment-modal__content-block-line" />
                <div class="payment-modal__content-block-currency">руб.</div>
            </div>
            <div class="payment-modal__content-subtitle">
                Итого к оплате <span class="payment-modal__content-subtitle--price">{{ isNaN(sumWithCommission) ?  0 : sumWithCommission.toFixed(2) }} руб.</span>
            </div>
        </div>

        <div class="payment-modal__action">
            <div @click="onPaymentModalClose" class="payment-modal__action-dismiss">Отмена</div>
            <base-button text="Оплатить" class="payment-modal__action-success" color="green" size="big" @click.prevent="onPaymentSubmit" />
        </div>
    </div> 
  </SwipeModal>
</template>
<script setup>
import { SwipeModal } from "@takuma-ru/vue-swipe-modal";
import { BaseButton } from '@/components/atoms';
import { useStore } from 'vuex';
import { ref, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { getPaymentLink } from '@/graphql/Payments.graphql';

const store = useStore();

const emit = defineEmits(['close', 'create', 'update', 'delete']);

const props = defineProps({
  isPaymentModalVisible: { type: Boolean, default: false },
  sumForPay: { type: Number, default: 0 },
});

const isOpen = ref();
const percent = ref(2);
const sumPay = ref(0);
const sumWithCommission = ref(0);

const { refetch: refetchPaymentLink } = useQuery(getPaymentLink, { amount: String(sumPay.value) });

const onPaymentModalClose = (isSubmit) => {
    isOpen.value = false;
    emit('close', false);
    // console.log(isSubmit, "SUBMIT");
}

const onChangeInput = (e) => {
    sumPay.value = e.target.value;
    sumWithCommission.value = parseFloat(sumPay.value) + (parseFloat(sumPay.value) * percent.value) / 100;
}

const onPaymentSubmit = async () => {
    if (sumPay.value < 1) {
        store.dispatch('notification/showNotification', {
            text: 'Сумма должна быть больше 0 руб.',
            type: 'error',
        });
        return;
    }

    const { data } = await refetchPaymentLink({ 
        amount: String(sumPay.value) 
    });
    // TODO: maybe add the error catch 
    // window.open(data.getPaymentLink);
    window.location.href = data.getPaymentLink;
}

watch(() => props.isPaymentModalVisible,
  async () => {
    isOpen.value = props.isPaymentModalVisible;
    sumPay.value = parseFloat(store.getters['client/getDebt']);
    sumWithCommission.value = sumPay.value + (sumPay.value * percent.value) / 100;
  }, { deep: true });
</script>
<style scoped lang="scss">
.swipe-modal {
  padding: 10px 20px;
  background: #fff;

  &__action {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;

    &-title {
      font-weight: 600;
      color: var(--black);
    }

    &-close {
      color: var(--black);
    }
  }
}

.payment-modal {
    &__head {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-text {
            margin-top: 8px;
            font-size: 12px;
            font-weight: 400;
            color: var(--grey-color);
        }
    }

    &__content {
        margin-top: 38px;

        &-title {
            margin-bottom: 8px;
            font-size: 16px;
            font-weight: 400;
        }

        &-block {
            padding: 15px;
            width: 100%;
            display: flex;
            align-items: end;
            border-radius: 17px;
            border: 1px solid rgba(185, 185, 185, 1);
            box-sizing: border-box;

            &-input {
                margin: 0;
                padding: 0;
                min-width: 90%;

                border: 0;
                background: transparent;
                font-size: 27px;
                font-weight: 600;
                box-sizing: border-box;
            }

            &-price {
                margin-right: 10px;
                font-size: 27px;
                font-weight: 600;
            }

            &-line {
                margin: 0 10px;
                min-width: 1px;
                height: 30px;
                background: var(--grey-color);
            }

            &-currency {
                font-size: 16px;
                font-weight: 500;
                color: var(--grey-color);
            }
        }

        &-subtitle {
            margin-top: 10px;
            width: 100%;
            text-align: right;
            font-size: 16px;
            color: var(--grey-color);

            &--price {
                margin-left: 5px;
                font-weight: 600;
            }
        }
    }

    &__action {
        margin-top: 38px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-dismiss {
            font-weight: 500;
            color: var(--grey-color);
            cursor: pointer;
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
     /* <-- Apparently some margin are still there even though it's hidden 
         margin: 0; 
     */
}

@media(max-width: 485px) {
    .payment-modal {
        &__content {
            &-block {
                &-input {
                    min-width: 30%; 
                }
            }
        }
    }
}

@media(max-width: 375px) {
    .payment-modal {
        &__action {
            width: 100%;
            flex-direction: column-reverse;
            justify-content: center;
            gap: 20px;
        }
    }
}
</style>