<template>
  <div class="payments-page">
    <div class="payments-page__header">
      <h1 class="header">Оплаты</h1>
    </div>

    <base-tabs-wrapper @update:selectedTitle="handleTabClick">
      <base-tab title="Оплаты">
        <PaymentMainBanner @focus="onModalOpen" @openAgent="openAgent" />
        <base-button
          text="Оплатить услугу"
          class="mt-10"
          color="green"
          size="biggest"
          @click="onModalOpen"
        />
        <!-- <payment-banner @open="setOpenModal" /> -->
      </base-tab>

      <base-tab title="История">
        <!-- <payment-history /> -->
        <SetupPaymentsHistory />
      </base-tab>
    </base-tabs-wrapper>

    <!-- <div class="search-input-wrapper search-input-wrapper--payments">
            <input type="text" class="search-input" />
            <mdicon size="20" name="magnify" class="search-input-icon" />
        </div> -->
  </div>

  <payment-modal
    v-if="!isSwipeModalShouldShow"
    :is-payment-modal-visible="isPaymentModalVisible"
    @close="onCloseModal"
  />
  <payment-swipe-modal
    v-if="isSwipeModalShouldShow"
    :is-payment-modal-visible="isPaymentModalVisible"
    @close="onCloseModal"
  />

  <SetupPaymentsModal
    :isModalVisible="isModalVisible"
    :agentGroupItems="agentGroupItems"
    :deepModalData="deepModalData"
    @openInfoModal="onInfoModalOpen"
    @submit="onSubmit"
    @close="onModalClose"
  />
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { BaseTabsWrapper, BaseTab, BaseButton } from '@/components/atoms/';
import PaymentModal from './PaymentModal.vue';
import PaymentSwipeModal from './PaymentSwipeModal.vue';
import { useStore } from 'vuex';
import SetupPaymentsModal from './SetupPaymentsModal.vue';
import SetupPaymentsHistory from './SetupPaymentsHistory.vue';
import PaymentMainBanner from './PaymentMainBanner.vue';

const store = useStore();
const sessionToken = store.getters['app/getSessionToken'];
const isModalVisible = ref(false);
const isPaymentModalVisible = ref(false);
const isSwipeModalShouldShow = ref(false);
const agentGroupItems = ref([]);
const deepModalData = ref(null);

const onModalOpen = () => {
  isModalVisible.value = true;
};

const onModalClose = () => {
  isModalVisible.value = false;
  // deepModalData.value = null;
};

const openAgent = (data) => {
  onModalOpen();
  deepModalData.value = data;
};

const getAgentGroupItems = async (name) => {
  try {
    const urlParams = new URLSearchParams({
      group: name,
    }).toString();

    const response = await fetch(
      `https://trouter.domion.tech/genbank/agent-group-items?${urlParams}`,
      {
        // const response = await fetch(`${config.VUE_APP_API_TROUTER}/genbank/agent-group-items?${urlParams}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      const responseData = await response.json();
      agentGroupItems.value = responseData;
      // console.log(responseData);
    }
  } catch (error) {
    // console.error('There was a problem with the fetch operation:', error);
  }
};

const checkFormData = async (data) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await fetch(
      `https://trouter.domion.tech/genbank/check-pay`,
      // `${config.VUE_APP_API_TROUTER}/genbank/check-pay`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify({ ...data, terminalId: 'Domion' }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      const result = await response.json();
      window.open(result.url);
      // window.open(`${result}?return_to=${window.location.href}?success`)
      // window.location.href = result;
    }
  } catch (error) {
    // console.error('There was a problem with the fetch operation:', error);
    throw error;
  }
};

const onSubmit = async (data) => {
  if (typeof data === 'string') {
    if (deepModalData.value) {
      // console.log(deepModalData.value, 'deep');
      await getAgentGroupItems(deepModalData.value.agentGroupName);
      return;
    }
    await getAgentGroupItems(data);
  } else {
    await checkFormData(data);
  }
};

const toggleModal = () => {
  if (window.innerWidth <= 620) isSwipeModalShouldShow.value = true;
  else isSwipeModalShouldShow.value = false;
};

const setPaymentPopupVisibility = (status) => {
  isPaymentModalVisible.value = status;
};

const setOpenModal = (value) => {
  setPaymentPopupVisibility(value);
};

const onCloseModal = (value) => {
  setPaymentPopupVisibility(value);
};

onMounted(() => {
  toggleModal();
  window.addEventListener('resize', toggleModal);
});
</script>

<style scoped lang="scss">
.mt-10 {
  margin-top: 10px;
}
.payments-page {
  &__header {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.search-input-wrapper--payments {
  margin-top: 30px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .header {
    margin-bottom: 15px;
  }
}

@media (max-width: 740px) {
  .payments-page {
    padding: 32px 36px 16px 36px;
  }
}

@media (max-width: 320px) {
  .payments-page {
    padding: 20px 20px 16px 20px;
  }
}
</style>
