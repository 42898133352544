<template>
    <div class="client-second-slide" :style="{ backgroundImage: `url(${backgroundImageMain})` }">
        <div class="client-second-slide__banner" :style="{ backgroundImage: `url(${backgroundImage})` }">
            <div class="client-second-slide__banner-text">
                <div class="client-second-slide__banner-text-title"  :style="THEME === 'domion' ? 'color: var(--white)' : ''">Заявки</div>
                <div class="client-second-slide__banner-text-subtitle" :style="THEME === 'domion' ? 'color: var(--white)' : 'opacity: .5;'">
                    {{ subtitle }}
                </div>
                <div @click="pushToClaims" :class="THEME === 'domion' ? 'client-second-slide__banner-text-button' : 'client-second-slide__banner-text-button--green'" class="client-second-slide__banner-text-button">
                    Подробнее
                </div> 
            </div>
        </div>

        <div class="client-second-slide__image">
            <img class="client-fs__image-item" :src="girlImage" alt="girl"/> 
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import greenGirl from '@/assets/images/claims-green/girl.png';
import domionGirl from '@/assets/images/client/slides/girl.png';
import greenBgClaims from '@/assets/images/claims-green/orders-bg.png';
import domionBgClaims from '@/assets/images/client/slides/claims-bg.png';
import { onMounted } from 'vue';
import { ref } from 'vue';

const THEME = config.VUE_APP_THEME;
const router = useRouter();

const girlImage = THEME === 'domion' ? domionGirl : greenGirl;
// const backgroundImage = THEME === 'domion' ? domionBgClaims : greenBgClaims; 
const subtitle = THEME === 'domion' ? 
`Оптимизируйте процесс обслуживания вашего дома с личным кабинетом Домион!\n\n Создавайте заявки онлайн и следите за их выполнением управляющей компанией. Быстро и удобно решайте любые вопросы, связанные с обслуживанием вашего жилого комплекса.`
:
`Оптимизируйте процесс обслуживания вашего дома с личным кабинетом Грин.Дом!\n\n Создавайте заявки онлайн и следите за их выполнением управляющей компанией. Быстро и удобно решайте любые вопросы, связанные с обслуживанием вашего жилого комплекса.`

const backgroundImage = ref('none');
const backgroundImageMain = ref('none');

if (THEME === 'domion') {
    backgroundImage.value = domionBgClaims;
} else {
    backgroundImage.value = greenBgClaims;
}

const pushToClaims = async () => {
    await router.push('/app/client/claims');
}

const toggleBackground = () => {
    if (window.innerWidth <= 740 && THEME === 'domion') {
        backgroundImageMain.value = domionBgClaims;
        backgroundImage.value = 'none';
    } else if (window.innerWidth <= 740 && THEME !== 'domion') {
        backgroundImageMain.value = greenBgClaims;
        backgroundImage.value = 'none';
    }
};

onMounted(() => {
    toggleBackground();
    window.addEventListener('resize', toggleBackground);
});
</script>

<style scoped lang="scss">
.client-second-slide {
    position: relative;
    width: 100%;
    height: 530px;
    display: flex;
    align-items: end;

    &__banner {
        padding: 47px 35px;
        width: 100%;
        height: 399px;
        // background-image: url('../../../../../../src/assets/images/client/slides/claims-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 29px;
        box-sizing: border-box;

        &-text {
            max-width: 50%;
            text-align: left;

            &-title {
                margin-bottom: 20px;
                font-size: 37px;
                font-weight: 500;
                // color: var(--white);
            }

            &-subtitle {
                max-width: 473px;
                margin-bottom: 38px;
                font-size: 16px;
                font-weight: 500; 
                // color: var(--white);
            }

            &-button {
                width: 240px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 9px;
                background: var(--white);
                color: var(--primary-color) !important;
                cursor: pointer;

                &--green {
                    width: 240px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 9px;
                    background: var(--primary-color);
                    color: var(--white) !important;
                    cursor: pointer;
                }
            }

        }
    }

    &__image {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

@media(max-width: 1420px) {
    .client-second-slide {
        &__banner {
            &-text {
                &-title {
                    font-size: 28px;
                }

                &-subtitle {
                    font-size: 15px;
                }
            }
        }
    }
}

@media(max-width: 1230px) {
    .client-second-slide {
        height: 430px;

        &__banner {
            height: 360px;

            &-text {
                &-title {
                    font-size: 24px;
                }

                &-subtitle {
                    font-size: 14px;
                } 
            }
        }
    }

    .client-fs__image-item {
        width: 400px;
    }
}

@media(max-width: 1140px) {
    .client-second-slide {
        &__banner {
            padding: 25px 30px;
            background-size: 125%;

            &-empty {
                min-width: 350px;
            }
        }
    }
    .client-fs__image-item {
        width: 380px;
    }
}

@media(max-width: 1050px) {
    .client-second-slide {
        height: 360px;

        &__banner {
            background-size: 140%;
        }
    }
    .client-fs__image-item {
        width: 350px;
    }
}

@media(max-width: 740px) {
    .client-second-slide {
        height: 100% !important;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        border-radius: 29px;
        background-repeat: no-repeat;
        // background-position: right top;
        background-size: 600%;

        &__banner {
            height: auto;
            background: transparent;

            &-text {
                max-width: 100%;
                text-align: center;

                &-subtitle {
                    max-width: 100%;
                }

                &-buttons {
                    margin-top: 10px;
                    width: 100%;
                    justify-content: center;
                }

                &-button {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        &__image {
            right: 0;
            position: initial;
        }
    }

    .client-fs__image-item {
        width: 90%;
    }
}

@media(max-width: 400px) {
    .client-second-slide {    
        height: auto;
        margin-bottom: auto;
    }
}
</style>