import { FrontendApi, Configuration } from "@ory/client";

const basePath = process.env.VUE_APP_ORY_URL;

export const ory = new FrontendApi(
    new Configuration({
        basePath,
        baseOptions: {
            withCredentials: true,
        },
    }),
)