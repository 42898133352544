<template>
    <div class="client-main">
        <div class="client-main__header">
            <h1 class="header client-main__header-text">Главная</h1>

            <div class="client-main__header-buttons">

                <carousel id="thumbnails" v-model="currentSlide" :items-to-show="slideCount" :touch-drag="false"
                    :mouseDrag="false">
                    <slide v-for="slide in slideCount" :key="slide">
                        <div @click="slideTo(slide - 1)"
                            :class="currentSlide === slide - 1 ? 'client-slide__dot--active' : 'client-slide__dot'" />
                    </slide>
                </carousel>

                <div @click="prev" class="client-main__header-buttons-button">
                    <mdicon name="arrow-left" size="24" class="client-main__header-buttons-button-icon" />
                </div>
                <div @click="next" class="client-main__header-buttons-button">
                    <mdicon name="arrow-right" size="24" class="client-main__header-buttons-button-icon" />
                </div>
            </div>
        </div>

        <!-- 
            :autoplay="5000"
            :wrap-around="true"
         -->
        <carousel ref="clientCarousel" id="gallery" v-model="currentSlide" :items-to-show="1" :transition="600"
            :throttle="200">
            <slide v-for="slide in slideCount" :key="slide">
                <FirstSlide v-if="slide === 1" />
                <SecondSlide v-if="slide === 2" />
                <ThirdSlide v-if="slide === 3" />
            </slide>
        </carousel>

    </div>
</template>

<script setup>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';
import { FirstSlide, SecondSlide, ThirdSlide } from './Slides/index';
import { ref } from 'vue';

const slideCount = ref(3);
const currentSlide = ref(0);
const clientCarousel = ref(null);

const slideTo = (val) => {
    currentSlide.value = val;
}

const prev = () => {
    // This logic for fix blinking when slide goes from first to last slide
    if (currentSlide.value === 0) {
        currentSlide.value = slideCount.value - 1;
    } else {
        clientCarousel.value.prev();
    }
}

const next = () => {
    // This logic for fix blinking when slide goes from last to first slide
    if (currentSlide.value + 1 === slideCount.value) {
        currentSlide.value = 0;
    } else {
        clientCarousel.value.next();
    }
}
</script>

<style scoped lang="scss">
.client-slide__dot {
    width: 8px;
    height: 8px;
    background: var(--grey-secondary-color);
    border-radius: 50%;
    cursor: pointer;

    &--active {
        width: 30px;
        height: 8px;
        background: var(--primary-color);
        border-radius: 20px;
    }
}

.client-main {
    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-buttons {
            display: flex;
            align-items: center;
            gap: 10px;

            &-button {
                width: 53px;
                height: 53px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background: var(--primary-color);
                color: var(--white);
                cursor: pointer;
            }
        }
    }
}

@media(max-width: 1050px) {
    .client-main__header {

        margin-bottom: 30px;
    }
}

@media(max-width: 740px) {
    .client-main {
        &__header {
            padding: 32px 36px 0px 36px;

            &-text {
                margin-bottom: 0;
            }
        }
    }
}

@media(max-width: 350px) {
    .client-main {
        &__header {
            padding: 25px 10px 0px 10px;
        }
    }
}
</style>
