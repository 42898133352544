<template>
<base-popup 
  size="large" 
  topIndent="30" 
  :is-visible="isModalVisible"
  title="Оплата услуг"
  @close="onModalClose"
  :has-right-side-content="template ? true : false"
>
  <template v-slot:right-side-content>
    <div @click="openInfoModal" class="popup__right-side-content">
      <div class="popup__right-side-content-icon">
        <mdicon name="trash-can" size="24" />
      </div>
      <div class="popup__right-side-content-text">Удалить шаблон</div>
    </div>
  </template>

  <div class="popup">
    <SetupModalForm :template="template" />
  </div>

  <div class="popup__buttons">
    <div
      @click.prevent="onModalClose"
      class="popup__buttons-dismiss"
    >
      Отмена
    </div>
    <base-button 
      :text="'Оплатить'" 
      color="green" 
      size="biggest"
      :disabled="!template"
      @click.prevent="onSubmit"
    />
  </div>
</base-popup>
</template>
<script setup>
import { BasePopup, BaseButton } from '@/components/atoms';
import SetupModalForm from './SetupModalForm';

defineProps({
  isModalVisible: Boolean,
  template: Object
});

const emit = defineEmits(['close', 'openInfoModal']);

const openInfoModal = () => {
  emit('openInfoModal');
}

const onModalClose = () => {
  emit('close');
};

const onSubmit = () => {
  // console.log('submit');
}
</script>
<style scoped lang="scss">
.popup {
  &__right-side-content {
    display: flex;
    align-items: center;
    gap: 9px;
    cursor: pointer;

    &-icon {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #FFF1F1;
      color: #FF0000;
    }

    &-text {
      font-size: 16px;
      font-weight: 600;
      color: #FF0000;
    }
  }

  &__buttons {
    margin-top: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-dismiss {
      padding: 10px 20px;
      opacity: .5;
      cursor: pointer;
    }
  }
}
</style>