<template>
  <div class="main">
    <component :is="sidebarComponent" />
    <div class="content-wrapper" :class="isSetup ? 'content-wrapper--setup' : ''">
      <the-header />
      <div class="content">
        <component :is="pageComponent" />
      </div>
    </div>
  </div>
</template>

<script>
  import { computed, watch, ref, shallowRef, onBeforeMount } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';

  import * as Pages from '@/components/pages';
  import TheHeader from '@/components/TheHeader';
  import * as Sidebars from '@/components/sidebar';

  export default {
    name: 'AuthPagesController',
    components: { ...Pages, ...Sidebars, TheHeader },
    methods: {
      changeName(data) {
        // console.log(data);
      },
    },
    setup() {
      const ROLES_TO_PAGES_NAMES = {
        adminuk: 'AdminUk',
        admin: 'Admin',
        manager: 'Manager',
        client: 'Client'
      };

      // GREEN
      const router = useRouter();
      const store = useStore();
      const role = store.getters['app/userRole'];

      const route = useRoute();
      const pageComponent = shallowRef(null);
      const sidebarComponent = shallowRef(null);
      const isDependOnRole = ref(route.meta.dependOnRole);

      const isSetup = computed(() => {
        return route.path.includes('setup-client') ? true : false;
      });

      const getPageComponent = (pageName) => {
        const prefix = ROLES_TO_PAGES_NAMES[role.toLowerCase()];

        if (isDependOnRole.value) {
          pageComponent.value = `${prefix}${pageName}Page`;
        } else {
          pageComponent.value = pageName;
        }
      };

      const getSidebarComponent = () => {
        const prefix = ROLES_TO_PAGES_NAMES[role.toLowerCase()];
        sidebarComponent.value = `${prefix}Sidebar`;
      };

      watch(
        () => route.name,
        (pageName) => {
          isDependOnRole.value = route.meta.dependOnRole;
          getPageComponent(pageName);
          getSidebarComponent();
        }
      );

      // const logout = async () => {
      //   await store.dispatch('app/logout');
      //   router.push('/');
      // }

      // const newAuthMigrateFunc = () => {
      //   const session = localStorage.getItem('s');
      //   const logoutUrl = localStorage.getItem('logout-url');

      //   if (!session || !logoutUrl) {
      //     logout();
      //   }
      // }

      onBeforeMount(async () => {
        isDependOnRole.value = route.meta.dependOnRole;
        getPageComponent(route.name);
        getSidebarComponent();
        // newAuthMigrateFunc();
      });

      getPageComponent(route.name);
      getSidebarComponent();

      return { pageComponent, sidebarComponent, isSetup };
    },
  };
</script>

<style lang="scss" scoped>
  
  .main {
    display: flex;
    flex: 1;
    overflow: hidden;
    max-height: 100vh;

    .content {
      &-wrapper {
        display: flex;
        flex: 1;
        overflow: hidden;
        flex-direction: column;

        &--setup {
          background-color: #F4F4F5;
          background-image: url('../../src/assets/images/setup-bg.png');
          background-size: cover;
          background-position: center;
        }
      }

      overflow: auto;
      padding: 32px 36px 16px 36px;
      flex: 1;
      min-height: 0;
      max-height: 100%;
    }
  }

@media(max-width: 740px) {
  .main {
    .content {
      padding: 0;
    }
  }
}
</style>
