<template>
    <div class="cards-client">
        <div v-if="claimsArray.length === 0" class="cards-client__empty">
            <img :src="emptyImg" class="cards-client__empty-img" alt="empty-claims" />
            <div v-if="completed" class="cards-client__empty-text">
                На данный момент еще нет завершенных заявок
            </div>
            <div v-else class="cards-client__empty-text">
                На данный момент у вас еще нет заявок, вы можете создать новую
            </div>
        </div>

        <div v-else style="display: flex; flex-wrap: wrap;" class="claims">
        <div v-for="(item, index) in claimsArray" :key="index" @click="onClaimModalOpen(item.id)"
            class="cards-client__item card claims__card">
            <div class="cards-client__item-header">
                <div class="cards-client__item-header-number">№{{ item.id }}</div>
                <div>
                    <span class="status" :class="statusClass(item.status)">
                        {{ getStatus(item.status) }}
                    </span>
                </div>
            </div>

            <div class="cards-client__item-subheader">
                Квартира {{ item.apartment.number }} •
                {{ new Date(item?.dateFrom).toLocaleDateString('ru-RU') }}
            </div>

            <div class="cards-client__item-description clip-1">{{ item.description }}</div>

            <div class="cards-client__item-line" />

            <div class="cards-client__item-action">
                <div class="cards-client__item-action-block">
                    <mdicon size="20" class="cards-client__item-action-block-icon-grey" name="attachment" />
                    <div class="cards-client__item-action-block-text">
                        {{ item.attach.length > 0 ? item.attach.length : '' }} 
                        <span v-if="item.attach.length === 0">нет вложений</span>
                        <span v-else-if="item.attach.length % 10 === 1">вложение</span>
                        <span v-else-if="item.attach.length % 10 === 2">вложения</span>
                        <span v-else-if="item.attach.length % 10 === 4">вложения</span>
                        <span v-else>вложений</span>
                    </div>
                </div>

                <div class="cards-client__item-action-block">
                    <mdicon v-if="item.status !== 'OPENED'" size="20" class="cards-client__item-action-block-icon-primary" name="email-outline" />
                    <div v-if="item.newCommentsCount > 0" class="cards-client__item-action-block-text-primary">
                        новый комментарий
                    </div>
                </div>
            </div>
        </div>
        <!-- <the-pagination
            :totalPages="totalPages"
            :currentPage="currentPage"
            @pageChanged="onChangePage"
            class="pagination"
        ></the-pagination> -->
        </div>
    </div>

    <claim-modal 
        v-if="!isSwipeModalShouldShow"
        :is-claim-modal-visible="isClaimModalVisible" 
        :claim="selectedClaim" 
        :statuses="statusList"
        @setClaim="setClaim" 
        @close="setClaimPopupVisibility(false)" 
        @create="onClaimCreated" 
        @update="onClaimUpdated"
        @delete="onClaimDeleted" 
    />
    
    <claim-swipe-modal 
        v-if="isSwipeModalShouldShow"
        :isOpenModal="isClaimModalVisible" 
        :claim="selectedClaim" 
        :statuses="statusList"
        @setClaim="setClaim" 
        @close="setClaimPopupVisibility(false)" 
        @create="onClaimCreated" 
        @update="onClaimUpdated"
        @delete="onClaimDeleted"  
    />
</template>

<script setup>
import { getRequestsByClient } from '@/graphql/Claims.graphql';
import { useQuery } from '@vue/apollo-composable';
import { inject, onMounted, onUnmounted, ref, watch } from 'vue';
import { statusClass } from '@/constant/status';
import { getStatuses, getRequestByClient } from '@/graphql/Claims.graphql';
import ClaimModal from './ClaimModal';
import ClaimSwipeModal from './ClaimSwipeModal.vue';
import { EventBus } from '@/utils/eventBus';
import { claimData } from '@/constant/claimData';
import emptyDomion from '@/assets/images/empty-claims.png';
import emptyGreen from '@/assets/images/claims-green/empty.png';
import ThePagination from '@/components/ThePagination.vue';

const THEME = config.VUE_APP_THEME
const props = defineProps({
    //isShowCompleted: { type: Boolean, required: true },
    completed: { type: Boolean, required: true },
    searchQuery: { type: String, required: true },
    isActiveSide: Boolean
});

const emptyImg = THEME === 'domion' ? emptyDomion : emptyGreen;
const isLoading = ref(true);
const claimsArray = ref([]);
const previousArray = ref([]);
const statusList = ref([]);
const isStatusListReady = ref(false);
const completedClaims = ref(props.completed);
const isClaimModalVisible = ref(false);
const selectedClaim = ref(claimData);
const claimId = ref(0);
const searchQuery = ref(props.searchQuery);
const selectedTitle = inject('selectedTitle');
const isSwipeModalShouldShow = ref(false);

const itemsPerPage = 6;
const totalPages = ref(0);
const currentPage = ref(1);

const toggleModal = () => {
    if (window.innerWidth <= 620) isSwipeModalShouldShow.value = true;
    else isSwipeModalShouldShow.value = false;
};

const { result: claimsResult } = useQuery(
    getRequestsByClient,
    { completed: completedClaims.value }
);
const { result: getAllStatuses } = useQuery(getStatuses);
const { refetch: refetchClaim } = useQuery(
    getRequestByClient, { id: claimId.value }
);

const onChangePage = () => {

}

const onClaimModalOpen = async (id) => {
    claimId.value = id;
    // console.log(claimId.value, 'ID');

    if (claimId.value !== null) {
        const { data: { getRequestByClient } } = await refetchClaim(
            { id: claimId.value }
        );

        selectedClaim.value = getRequestByClient;
        // // console.log(getRequestByClient, "GETTED DATA NEW");
    }

    if (!completedClaims.value && props.isActiveSide) {
        setClaimPopupVisibility(true);
    }

    if (completedClaims.value && !props.isActiveSide) {
        setClaimPopupVisibility(true);
    }
};

const setClaimPopupVisibility = (status) => {
    isClaimModalVisible.value = status;

    if (!status) {
        selectedClaim.value = claimData;
    }
};

const getStatus = (status) => {
    if (isStatusListReady.value) {
        statusList.value.forEach((item) => {
            if (item.name === status) {
                status = item.title;
            }
        });
    }
    return status;
};

const sortArrayByDateAndComments = (requests) => {
 const sortedRequests = [...requests].sort((a, b) => {
    // Получаем значения newCommentsCount, считая отсутствующие значения равными 0
    const commentsA = a.newCommentsCount || 0;
    const commentsB = b.newCommentsCount || 0;

    // Сначала сортируем по newCommentsCount в порядке убывания
    if (commentsB > commentsA) {
      return 1;
    } else if (commentsB < commentsA) {
      return -1;
    }

    // Если newCommentsCount одинаковый, сортируем по dateFrom в порядке убывания
    const dateA = new Date(a.dateFrom);
    const dateB = new Date(b.dateFrom);

    if (dateB > dateA) {
      return 1;
    } else if (dateB < dateA) {
      return -1;
    }

    return 0;
 });

 return sortedRequests;
}

const onClaimCreated = async (createdClaim) => {
    // console.log(selectedTitle.value, 'selectedTitle');

    if (selectedTitle.value === "Завершенные") {
        // console.log('hello');
    }

    let newClaimsArray = [...claimsArray.value];
    newClaimsArray.push(createdClaim);

    claimsArray.value = sortArrayByDateAndComments(newClaimsArray);
    // claimsArray.value = newClaimsArray.reverse();
};

const onClaimUpdated = async (updatedClaim) => {
    let updatedClaimsArray = [...claimsArray.value];

    updatedClaimsArray = updatedClaimsArray.map((claim) => {
        if (updatedClaim.id === claim.id) {
            return {
                ...claim,
                dateFrom: updatedClaim.dateFrom,
                description: updatedClaim.description
            };
        }
        return claim;
    });

    claimsArray.value = sortArrayByDateAndComments(updatedClaimsArray);
};

const onClaimDeleted = async (id) => {
    // // console.log(id, 'DELETED ID');
    claimsArray.value = claimsArray.value.filter((claim) => claim.id !== id);
};

watch(getAllStatuses, (newValue) => {
    statusList.value = newValue.getStatuses;
    isStatusListReady.value = true;
});

watch(claimsResult, (value) => {
    // console.log(value, 'getted claims');
    claimsArray.value = value.getRequestsByClient;
    previousArray.value = claimsArray.value;

    claimsArray.value = sortArrayByDateAndComments(claimsArray.value);
    // // console.log(claimsArray.value, props.completed ? 'COMPLETED' : 'ACTIVE');

    isLoading.value = false;
});

watch(selectedTitle, (value) => {
    isClaimModalVisible.value = false;
    // console.log(value, "selectedTitle");
})

watch(() => props.searchQuery, async () => {
        // // console.log('GETTED SEARCH', props.searchQuery);
        searchQuery.value = props.searchQuery;

        if (props.searchQuery === '') {
            claimsArray.value = previousArray.value;
        }

        claimsArray.value = claimsArray.value.filter(claim =>
            claim.description.toLowerCase().includes(searchQuery.value.toLowerCase())
        );
    },
    { deep: true }
);

onMounted(() => {
    toggleModal();
    window.addEventListener('resize', toggleModal);
    EventBus.on('open-claim-modal', () => onClaimModalOpen(null));
});

onUnmounted(() => {
    EventBus.off('open-claim-modal', () => onClaimModalOpen(null));
});
</script>

<style scoped lang="scss">
.claims {
    width: 100%;
    gap: 15px;

    &__card {
        width: 24%;
        margin: 0;
    }
}

@media(max-width: 1450px) {
    .claims {
        &__card {
            width: 32%;
        }
    }
}

@media(max-width: 1076px) {
    .claims {
        &__card {
            width: 48%;
        }
    }
}

@media(max-width: 768px) {
    .cards-client__empty-img {
        width: 100%;
    }

    .cards-client__empty-text {
        text-align: center;
    }
}

@media(max-width: 570px) {
    .claims {
        &__card {
            width: 100%;
        }
    }
}
</style>
