<template>
  <div class="logs">
    <div class="logs__table">
      <div v-if="isLoading" class="mt-20 text-center">
        <img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" />
      </div>
      <base-table class="history-table" v-else>
        <template v-slot:head>
          <base-table-row>
            <base-table-head-cell width="15%">Дата/время</base-table-head-cell>
            <base-table-head-cell width="25%"
              >Пользователь/устройство ID</base-table-head-cell
            >
            <base-table-head-cell width="60%">Событие</base-table-head-cell>
          </base-table-row>
        </template>
        <template v-slot:body>
          <base-table-row v-for="log in logs" :key="log.id">
            <base-table-cell width="15%"
              >{{ log.createdAt.substr(0, 10) }} /
              {{ log.createdAt.substr(11, 8) }}</base-table-cell
            >
            <base-table-cell width="25%"
              >{{ log.user.lastName }} {{ log.user.firstName }} /
              {{ log.sensorId }}</base-table-cell
            >
            <base-table-cell width="60%">
              {{ log.action }}
            </base-table-cell>
          </base-table-row>
        </template>
      </base-table>
    </div>
    <the-pagination
      :totalPages="totalPages"
      :currentPage="currentPage"
      @pageChanged="onChangePage"
      class="pagination"
    ></the-pagination>
  </div>
</template>
<script>
import { ref, watch, onMounted } from 'vue';
import { getLogs } from '@/graphql/Logs.graphql';
import { useQuery } from '@vue/apollo-composable';
import ThePagination from '@/components/ThePagination.vue';
import { useRoute, useRouter } from 'vue-router';
import {
  BaseTable,
  BaseTableCell,
  BaseTableHeadCell,
  BaseTableRow,
} from '@/components/BaseTable';

export default {
  name: 'Logs',
  components: {
    ThePagination,
    BaseTable,
    BaseTableCell,
    BaseTableHeadCell,
    BaseTableRow,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const isLoading = ref(true);
    const logs = ref([]);

    const totalPages = ref(0);
    const elementsOnPage = ref(10);
    const loaderDomion = require('@/assets/images/loader.svg');
    const loaderGreen = require('@/assets/images/loader-green.svg');
    // const themeModule = require('@/constant/main');
    // const THEME = themeModule.THEME;
    const THEME = config.VUE_APP_THEME;

    let { result: logsResult, fetchMore } = useQuery(getLogs, {
      pagination: {
        limit: elementsOnPage.value,
        offset: 0,
      },
    });

    // Пагинация с бэка
    const loadMore = (page = 0) => {
      const activePage = page === 1 ? 0 : page;
      // // console.log('activePage', activepage);
      fetchMore({
        variables: {
          pagination: {
            offset:
              (activePage === 0 ? 0 : activePage - 1) * elementsOnPage.value,
            limit: elementsOnPage.value,
          },
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          logs.value = [...fetchMoreResult.getLogs.logs];
        },
      });
      // // console.log('logs', logs.value);
    };

    onMounted(() => {
      loadMore(+route.query.page || 0);
    });

    watch(logsResult, (value) => {
      totalPages.value = Math.ceil(value.getLogs.count / elementsOnPage.value);
      isLoading.value = false;
    });

    const currentPage = ref(+route.query.page || 1);

    const onChangePage = (page) => {
      router.push(`${route.path}?page=${page}`);
      currentPage.value = page;
      isLoading.value = true;
      loadMore(page);
      isLoading.value = false;
    };

    return {
      isLoading,
      logs,
      onChangePage,
      currentPage,
      totalPages,
      loaderDomion,
      loaderGreen,
      THEME
    };
  },
};
</script>
<style scoped>
.pagination {
  margin-top: 25px;
}
.logs {
  margin-top: 40px;
}
</style>
