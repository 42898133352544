<template>
  <div>
    <h1 class="header">Заявки</h1>
  </div>
  <div class="tabs">
    <div class="tab-container">
      <div class="d-flex justify-content-end">
        <base-button
          text="Добавить заявку"
          color="green"
          size="m"
          @click="onClaimModalOpen"
        />
      </div>
      <ClaimFilter
        @filter="onClaimFilterUpdates"
        :claimWatcher="claimWatcher"
      />
      <div v-if="isLoading" class="loading">
        <img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" />
      </div>
      <div v-else>
        <ClaimsTable @click="onClaimUpdate" :claimsList="claims" />
        <the-pagination
          :totalPages="totalPages"
          :currentPage="currentPage"
          @pageChanged="onChangePage"
          class="pagination"
        ></the-pagination>
      </div>
    </div>
  </div>
  <claim-modal
    :is-claim-modal-visible="isClaimModalVisible"
    @close="setClaimPopupVisibility(false)"
    :claim="selectedClaim"
    @create="onClaimCreated"
    @update="onClaimUpdated"
    @delete="onClaimDeleted"
  />
</template>
<script setup>
import { ref, watch, onMounted } from 'vue';
import { BaseButton } from '@/components/atoms/';
import ClaimFilter from './ClaimsFilter.vue';
import ClaimsTable from './ClaimsTable.vue';
import ClaimModal from './ClaimModal.vue';
import { getRequests, getRequestById } from '@/graphql/Claims.graphql';
import { useQuery } from '@vue/apollo-composable';
import { useStore } from 'vuex';
import ThePagination from '@/components/ThePagination.vue';
import { useRoute, useRouter } from 'vue-router';
// import { THEME } from '@/constant/main'
const THEME = config.VUE_APP_THEME;
const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');
const route = useRoute();
const router = useRouter();

const store = useStore();

const isLoading = ref(true);

const claims = ref([]);
const filter = ref(null);

const selectedClaim = ref(null);

const totalPages = ref(0);
const elementsOnPage = ref(5);
const currentPage = ref(+route.query.page || 1);

// New claim modal
const isClaimModalVisible = ref(false);

const onClaimModalOpen = () => {
  setClaimPopupVisibility(true);
};
////////////////////

// Update claim

const onClaimUpdate = async (claim) => {
  const {
    data: { getRequestById },
  } = await claimByIdRefetch({
    id: +claim.id,
  });
  selectedClaim.value = getRequestById;
  // console.log('selectedClaim.value', selectedClaim.value);
  setClaimPopupVisibility(true);
};
//////////////////

// const filtered = ref(null);

const allClaimsValue = ref(null);

// Connect to GraphQl
const {
  result: allClaims,
  refetch,
  fetchMore,
} = useQuery(getRequests, {
  pagination: {
    limit: elementsOnPage.value,
    offset: 0,
  },
  filter: {
    byDate: filter.value?.byDate || false,
    category: filter.value?.category,
    executor: filter.value?.executor,
    type: filter.value?.type,
    status: filter.value?.status,
    apartment: +filter.value?.apartment,
    q: filter.value?.search,
  },
});

const getTotalPages = (totalItems) => {
  totalPages.value = Math.ceil(totalItems / elementsOnPage.value);
};

const setClaimPopupVisibility = async (status) => {
  isClaimModalVisible.value = status;

  // Проверка чтобы при открытии одной и той же заявки подряд срабатывал watch
  if (!status) {
    selectedClaim.value = null;
    loadMore(currentPage.value);
  }
};

// let i = 0;
watch(allClaims, (newValue) => {
  allClaimsValue.value = newValue;
  // if (i === 0) {
  //   claims.value = newValue.getRequests.requests;
  //   // filtered.value = newValue.getRequests.filtered;
  //   getTotalPages(newValue.getRequests.filtered);
  //   i++;
  // }
  // console.log('claims', claims.value);
  isLoading.value = false;
});
///////////////////

const loadMore = (page = 0) => {
  const activePage = page === 1 ? 0 : page;
  fetchMore({
    variables: {
      pagination: {
        offset: (activePage === 0 ? 0 : activePage - 1) * elementsOnPage.value,
        limit: elementsOnPage.value,
      },
    },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      claims.value = [...fetchMoreResult.getRequests.requests];
      getTotalPages(fetchMoreResult.getRequests.filtered);
    },
  });
  // console.log('claimsloadmore', claims.value);
};

let { refetch: claimByIdRefetch } = useQuery(getRequestById, {
  id: 0,
});

onMounted(async () => {
  loadMore(+route.query.page || 0);
  if (route.query.claim) {
    const {
      data: { getRequestById },
    } = await claimByIdRefetch({
      id: +route.query.claim,
    });
    onClaimUpdate(getRequestById);
  }
  // claims.value.forEach((item) => {
  //   if (item.id === +route.query.claim) {
  //     onClaimUpdate(item);
  //   }
  // });
});

let onLoad = true;

// On filter updates
const onClaimFilterUpdates = async (filter) => {
  // console.log('filter', filter);
  const {
    data: { getRequests },
  } = await refetch({
    filter: {
      byDate: filter.byDate,
      category: filter.category?.id || filter.category,
      executor: filter.executor?.id || filter.executor,
      type: filter.type,
      status: filter.status,
      apartment: +filter.apartment,
      q: filter.search,
    },
  });
  // console.log('refetch getRequests', getRequests);
  claims.value = [...getRequests.requests];
  getTotalPages(getRequests.filtered);
  if (onLoad) {
    onChangePage(+route.query.page || 1, filter);
    onLoad = false;
  } else {
    onChangePage(1, filter);
  }
  if (!claims.value.length) {
    await store.dispatch('notification/showNotification', {
      text: 'Совпадений нет',
      type: 'error',
    });
  }
};
/////////////

const claimWatcher = ref(0);

const onClaimCreated = async () => {
  await refetch();
  claimWatcher.value++;
  currentPage.value = 1;
  loadMore(currentPage.value);
};

const onClaimUpdated = async () => {
  await refetch();

  claimWatcher.value++;
};

const onClaimDeleted = async (id) => {
  claims.value = claims.value.filter((item) => item.id !== id);
  claimWatcher.value++;
  loadMore(currentPage.value);
};

const onChangePage = (page, filterList = null) => {
  // console.log(filterList);
  // router.push(`${route.path}?page=${page}`);
  if (filterList) {
    filter.value = filterList;
  }
  router.replace({
    route,
    query: {
      page: page,
      type: filter.value?.type,
      byDate: filter.value?.byDate,
      apartment: filter.value?.apartment,
      category: filter.value?.category?.id || filter.value?.category,
      executor: filter.value.executor?.id || filter.value?.executor,
      status: filter.value?.status,
      search: filter.value?.search,
    },
  });
  currentPage.value = page;
  isLoading.value = true;
  loadMore(page);
  isLoading.value = false;
  // console.log('page', page);
};
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/_colors';
.header {
  color: var(--black);
}
.loading {
  text-align: center;
  margin-top: 40px;
}
.tabs {
  margin-top: 21px;
}
.tab-container {
  margin-top: 37px;
  border-top: 1px solid var(--grey-opacity);
  padding-top: 30px;
}
.filter__input-wrapper {
  position: relative;
  width: 374px;
  height: 36px;
  margin-right: 18px;
}
.filter__input-icon {
  color: var(--grey-dark-color);
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-70%);
}
.filter__input {
  width: 100%;
  height: 100%;
  padding: 0 40px;
  border: none;
  background: var(--light-secondary-bg);
  border-radius: 5px;
  font-family: 'Golos';
  font-size: 15px;
}
</style>
