<template>
  <div class="tab-container">
    <form class="send" @submit.prevent="onFormSubmit">
      <div class="send__title">
        <span>Новая рассылка</span>
      </div>
      <div class="send__inputs">
        <div class="send__inputs__item send__input_theme">
          <label for="theme" class="send__input__label">Тема*</label>
          <input
            type="text"
            id="theme"
            class="send__input"
            v-model.trim="currentNotification.title"
          />
        </div>
        <div class="send__inputs__item send__input_content">
          <label for="content" class="send__input__label">Содержимое*</label>
          <textarea
            id="content"
            class="send__input send__textarea"
            label="Содержимое"
            v-model.trim="currentNotification.text"
          />
          <!-- <base-textarea
            id="content"
            class="send__input"
            label="Содержимое"
            v-model.trim="currentNotification.text"
          /> -->
          <!-- <input
            type="text"
            id="content"
            class="send__input"
            v-model.trim="currentNotification.text"
          /> -->
        </div>
      </div>
      <div class="recipients">
        <div class="section__title">
          <span>Получатели</span>
        </div>
        <div class="recipients__filter">
          <div class="search-input-wrapper">
            <input type="text" class="search-input" v-model="filter.search" />
            <mdicon size="20" name="magnify" class="search-input-icon" />
          </div>
          <v-select
            :options="buildingsOptions"
            class="select modal-select"
            placeholder="Башня"
            :multiple="true"
            :reduce="(value) => value.id"
            label="value"
            v-model="filter.buildings"
          >
          </v-select>
        </div>

        <div class="recipients__table">
          <div v-if="isLoading" class="mt-20 text-center">
            <img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" />
          </div>
          <base-table class="clients-table" v-else>
            <template v-slot:head>
              <base-table-row>
                <base-table-head-cell width="9%">
                  <base-checkbox
                    :value="0"
                    v-model:values="allChecked"
                    class="checkbox_big"
                  ></base-checkbox>
                </base-table-head-cell>
                <base-table-head-cell width="91%">
                  <base-table-head-cell width="15%"
                    >Лицевой счет</base-table-head-cell
                  >
                  <base-table-head-cell width="15%">ЖК</base-table-head-cell>
                  <base-table-head-cell width="45%"
                    >Адрес дома</base-table-head-cell
                  >
                  <base-table-head-cell width="12%">Дом</base-table-head-cell>
                  <base-table-head-cell width="13%"
                    >Квартира</base-table-head-cell
                  >
                </base-table-head-cell>
              </base-table-row>
            </template>
            <template v-slot:body>
              <div
                v-for="apartment in apartments"
                :key="apartment.id"
                class="clients-table__row"
              >
                <base-table-row>
                  <base-table-cell width="9%">
                    <base-checkbox
                      :value="apartment.id"
                      v-model:values="checkedApartments"
                      class="checkbox_big"
                    ></base-checkbox>
                  </base-table-cell>
                  <base-table-cell
                    width="91%"
                    @click="toggleSublist(apartment.id)"
                  >
                    <base-table-cell width="15%">{{
                      apartment.ownership.account
                    }}</base-table-cell>
                    <base-table-cell width="15%">{{
                      apartment.building.complex.name || '-'
                    }}</base-table-cell>
                    <base-table-cell width="45%">{{
                      apartment.building.address
                    }}</base-table-cell>
                    <base-table-cell width="12%"
                      >Дом {{ apartment.building.number }}</base-table-cell
                    >
                    <base-table-cell width="13%"
                      >Квартира {{ apartment.number }}</base-table-cell
                    >
                  </base-table-cell>
                </base-table-row>
                <div ref="content" class="subitem" :data-id="apartment.id">
                  <div v-if="isSubLoading" class="mt-20 text-center">
                    <img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" />
                  </div>
                  <base-table v-else>
                    <template v-slot:head>
                      <base-table-row>
                        <base-table-head-cell width="9%"></base-table-head-cell>
                        <base-table-head-cell width="22.75%"
                          >ФИО</base-table-head-cell
                        >
                        <base-table-head-cell width="22.75%"
                          >Номер телефона</base-table-head-cell
                        >
                        <base-table-head-cell width="22.75%"
                          >Электронная почта</base-table-head-cell
                        >
                        <base-table-head-cell width="22.75%"
                          >Статус</base-table-head-cell
                        >
                      </base-table-row>
                    </template>
                    <template v-slot:body>
                      <div>
                        <base-table-row
                          v-for="client in clients"
                          :key="client.id"
                        >
                          <base-table-cell width="9%">
                            <base-checkbox
                              :value="client.id"
                              v-model:values="checkedClients"
                              class="checkbox_big"
                            ></base-checkbox>
                          </base-table-cell>
                          <base-table-cell width="22.75%"
                            >{{ client.user.lastName }}
                            {{ client.user.firstName }}
                            {{ client.user.patronymic }}
                          </base-table-cell>
                          <base-table-cell width="22.75%">
                            {{ client.user.phone }}
                          </base-table-cell>
                          <base-table-cell width="22.75%">{{
                            client.user.email
                          }}</base-table-cell>
                          <base-table-cell width="22.75%">{{
                            userRole(client.user.role)
                          }}</base-table-cell>
                        </base-table-row>
                      </div>
                    </template>
                  </base-table>
                </div>
              </div>
            </template>
          </base-table>
        </div>
        <the-pagination
          :totalPages="totalPages"
          :currentPage="currentPage"
          @pageChanged="onChangePage"
          class="pagination"
        ></the-pagination>
      </div>
      <!-- <div class="mt-40">
        <div class="section__title">
          <span>Способы получения</span>
        </div>
        <ul class="ways-to-get__list">
          <li
            class="ways-to-get__list__item"
            v-for="wayToGet in waysToGet"
            :key="wayToGet.id"
          >
            <base-checkbox
              :value="wayToGet.label"
              v-model:values="checkedSocials"
              :label="wayToGet.label"
              size="small"
            ></base-checkbox>
          </li>
        </ul>
      </div> -->
      <div class="submit-button">
        <base-button
          color="green"
          text="Отправить"
          size="small"
          :disabled="isFormDataInvalid || clientsId.length === 0"
        ></base-button>
      </div>
    </form>
    <!-- <span>{{ checkedNames }}</span> -->
    <!-- <span>{{ checkedSocials }}</span> -->
  </div>
  <BaseGlobalLoader v-if="isGlobalLoading" />
</template>

<script setup>
import { onMounted, ref, watch, defineEmits, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import { BaseButton, BaseCheckbox, BaseGlobalLoader } from '@/components/atoms';
import ThePagination from '@/components/ThePagination.vue';
import { getBuildings } from '@/graphql/Votes.graphql';
import {
  getApartmentsForUI,
  getAppartmentClients,
} from '@/graphql/Client.graphql';
import { createNotificationsByUI } from '@/graphql/Notification.graphql';
import { useQuery, useMutation } from '@vue/apollo-composable';
import { useRouter, useRoute } from 'vue-router';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
// import { BaseTextarea } from '@/components/atoms';
import {
  BaseTable,
  BaseTableCell,
  BaseTableHeadCell,
  BaseTableRow,
} from '@/components/BaseTable';
// import { THEME } from '@/constant/main'
const THEME = config.VUE_APP_THEME;
const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');
const emit = defineEmits(['create', 'submit']);
const router = useRouter();
const route = useRoute();

const store = useStore();
// const waysToGet = ref([
//   {
//     id: 1,
//     label: 'Уведомления в приложении',
//     checked: true,
//   },
//   {
//     id: 2,
//     label: 'Электронная почта',
//     checked: false,
//   },
//   {
//     id: 3,
//     label: 'SMS-сообщение',
//     checked: false,
//   },
//   {
//     id: 4,
//     label: 'Viber',
//     checked: false,
//   },
//   {
//     id: 5,
//     label: 'Telegram',
//     checked: false,
//   },
//   {
//     id: 6,
//     label: 'WhatsApp',
//     checked: false,
//   },
// ]);

const totalPages = ref(0);
const perPage = ref(5);
const currentPage = ref(+route.query.page || 1);

const checkedApartments = ref([]);
const checkedClients = ref([]);
// const checkedSocials = ref([]);

const isGlobalLoading = ref(false);
const isLoading = ref(true);
const isSubLoading = ref(true);
const apartments = ref([]);
const buildingsOptions = ref([]);

const { result: allBuildings } = useQuery(getBuildings);

watch(allBuildings, (newValue) => {
  buildingsOptions.value = newValue.getBuildings.map(({ id, name }) => {
    return {
      id,
      value: name,
    };
  });
});

const { result: AppartmentClientsResult, refetch } = useQuery(
  getAppartmentClients,
  { apartmentId: 1 }
);

// console.log(AppartmentClientsResult, refetch);

let {
  result: ApartmentsResult,
  fetchMore,
  refetch: refetchApartments,
} = useQuery(getApartmentsForUI, {
  pagination: {
    limit: perPage.value,
    offset: 0,
  },
  filter: {
    q: route.query.search || null,
    buildingIds: null,
  },
});

const clients = ref([]);

const loadMore = async (page = 0) => {
  const activePage = page === 1 ? 0 : page;
  await fetchMore({
    variables: {
      pagination: {
        offset: (activePage === 0 ? 0 : activePage - 1) * perPage.value,
        limit: perPage.value,
      },
    },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      // console.log('fetchMoreResult', fetchMoreResult);
      apartments.value = [...fetchMoreResult.getApartmentsForUI.apartments];
      getTotalPages(fetchMoreResult.getApartmentsForUI.filtered);
    },
  });
  // console.log('clients in update query', apartments.value);
};

const getTotalPages = (totalItems) => {
  totalPages.value = Math.ceil(totalItems / perPage.value);
};

onMounted(async () => {
  await loadMore(+route.query.page || 0);
});
let isAppartmentClientsResultCanBeUpdated = true;
watch(AppartmentClientsResult, (newValue) => {
  if (isAppartmentClientsResultCanBeUpdated) {
    clients.value = newValue?.getAppartmentClients;
  }
  // // console.log('clients', newValue);
  isSubLoading.value = false;
});

watch(ApartmentsResult, async (newValue) => {
  getTotalPages(newValue?.getApartmentsForUI.filtered);
  isLoading.value = false;
});

const onChangePage = async (page, filterList = null) => {
  if (!filterList) {
    filterList = filter;
  }
  router.replace({
    route,
    query: {
      page,
      search: filterList.search,
    },
  });

  currentPage.value = page;
  await loadMore(page);
  if (allChecked.value.length) {
    checkAllApartments();
  }
};

const currentNotification = ref({
  id: null,
  title: '',
  text: '',
  seen: false,
  clientId: null,
});

const { mutate: createNotificationMutation } = useMutation(
  createNotificationsByUI
);

const clientsId = ref([]);

const contains = (what, where) => {
  for (let i = 0; i < what.length; i++) {
    if (where.indexOf(what[i]) === -1) {
      return false;
    }
    return true;
  }
};

const rules = computed(() => {
  return {
    title: { required },
    text: { required },
  };
});

const v$ = useVuelidate(rules, currentNotification);

const isFormDataInvalid = computed(() => {
  return v$.value.$invalid;
});

watch(checkedApartments, (newValue, oldValue) => {
  // // console.log('changed', newValue);
  // // console.log('oldValue', oldValue);
  let filteredValue;
  if (newValue.length === 0) {
    checkedClients.value = [];
    // filteredValue = newValue;
    return;
  } else if (oldValue.length > newValue.length) {
    filteredValue = newValue.filter((item) => oldValue.includes(item));
    if (allChecked.value.length && filteredValue.length) {
      if (exceptions.value.length) {
        let exceptionsTemp = oldValue.filter(
          (item) => !newValue.includes(item)
        );
        exceptions.value = [...exceptions.value, ...exceptionsTemp];
      } else {
        exceptions.value = oldValue.filter((item) => !newValue.includes(item));
      }
      // console.log('exceptions', exceptions.value);
    }
  } else {
    filteredValue = newValue;
    if (allChecked.value.length && exceptions.value.length) {
      exceptions.value = exceptions.value.filter(
        (item) => !newValue.includes(item)
      );
      // console.log('exceptions', exceptions.value);
    }
  }
  // // console.log('filteredValue', filteredValue);
  filteredValue.forEach(async (item) => {
    isAppartmentClientsResultCanBeUpdated = false;
    const {
      data: { getAppartmentClients },
    } = await refetch({
      apartmentId: item,
    });
    // // console.log('getAppartmentClients', getAppartmentClients);

    // // console.log('checkedApartments', getAppartmentClients);

    if (
      contains(filteredValue, checkedClients.value) &&
      oldValue.length > newValue.length
    ) {
      // isGlobalLoading.value = true;
      checkedClients.value = getAppartmentClients.map((item) => item.id);
    } else {
      // isGlobalLoading.value = true;
      checkedClients.value = [
        ...checkedClients.value,
        ...getAppartmentClients.map((item) => item.id),
      ];
    }
  });
});

watch(checkedClients, (newValue) => {
  // console.log('checkedClients');
  // // console.log('checkedClients', newValue);
  if (contains(newValue, clientsId.value) || !newValue.length) {
    clientsId.value = newValue;
  } else {
    clientsId.value = [...clientsId.value, ...newValue];
  }
  // // console.log('clientsId', clientsId.value);
});

const onNotificationCreate = async () => {
  // isGlobalLoading.value = true;
  clientsId.value = Array.from(new Set(clientsId.value));
  const { title, text } = currentNotification.value;

  try {
    await createNotificationMutation({
      data: {
        title,
        text,
        include: allChecked.value.length ? [] : clientsId.value,
        exclude: exceptions.value.length ? exceptions.value : [],
        buildingIds: allChecked.value.length ? filter.buildings : [],
      },
    });
    emit('create');
    store.dispatch('notification/showNotification', {
      text: 'Уведомление успешно отправлено!',
      type: 'success',
    });
  } catch (error) {
    store.dispatch('notification/showNotification', {
      text: 'Что-то пошло не так!',
      type: 'error',
    });
  } finally {
    isGlobalLoading.value = false;
  }
};

const onFormSubmit = async () => {
  const isFormCanBeSubmited = confirm('Отправить уведомления?');
  if (isFormCanBeSubmited) {
    if (
      currentNotification.value.title &&
      currentNotification.value.text &&
      (checkedApartments.value.length > 0 || checkedClients.value.length > 0)
    ) {
      isGlobalLoading.value = true;
      await onNotificationCreate();
      currentNotification.value.title = '';
      currentNotification.value.text = '';
      checkedApartments.value = [];
      checkedClients.value = [];
      allChecked.value = [];
      emit('submit');
      store.dispatch('notification/showNotification', {
        text: 'Уведомление успешно отправлено!',
        type: 'success',
      });
    } else {
      store.dispatch('notification/showNotification', {
        text: 'Что-то пошло не так!',
        type: 'error',
      });
    }
  }
};

const content = ref(null);

const toggleSublist = async (id) => {
  // console.log('click');
  for (let i = 0; i < content.value.length; i++) {
    const element = content.value[i];
    if (+element.dataset.id === id) {
      isAppartmentClientsResultCanBeUpdated = true;
      const {
        data: { getAppartmentClients },
      } = await refetch({
        apartmentId: id,
      });
      clients.value = getAppartmentClients;
      // console.log(getAppartmentClients);
      if (element.style.maxHeight) {
        element.style.maxHeight = null;
        element.classList.remove('active');
      } else {
        element.style.maxHeight = element.scrollHeight + 'px';
        element.classList.add('active');
      }
    } else {
      element.style.maxHeight = null;
      element.classList.remove('active');
    }
  }
};

const userRole = (role) => {
  if (role === 'OWNER') {
    return 'Собственник';
  } else if (role === 'TENANT') {
    return 'Жилец';
  } else {
    return role;
  }
};

const filter = reactive({
  search: route.query.search || null,
  buildings: null,
});

let onLoad = true;

watch(filter, async (newValue) => {
  const {
    data: { getApartmentsForUI },
  } = await refetchApartments({
    filter: {
      q: newValue.search,
      buildingIds: newValue.buildings?.length ? newValue.buildings : null,
    },
  });
  apartments.value = [...getApartmentsForUI.apartments];
  getTotalPages(getApartmentsForUI.filtered);
  if (onLoad) {
    onChangePage(+route.query.page || 1, newValue);
    onLoad = false;
  } else {
    onChangePage(1, newValue);
  }
  // console.log(totalPages.value);
  // console.log('filter', newValue);

  if (!apartments.value.length) {
    await store.dispatch('notification/showNotification', {
      text: 'Совпадений нет',
      type: 'error',
    });
  }
});

const allChecked = ref([]);
const exceptions = ref([]);

const checkAllApartments = () => {
  checkedApartments.value = apartments.value.map((item) => item.id);
  if (exceptions.value.length) {
    checkedApartments.value = checkedApartments.value.filter(
      (item) => !exceptions.value.includes(item)
    );
  }
  // console.log('checkedApartments11', checkedApartments.value);
};

watch(allChecked, async (newValue) => {
  if (newValue.length) {
    checkAllApartments();
  } else {
    checkedApartments.value = [];
    exceptions.value = [];
    checkedClients.value = [];
    // console.log('CLEAR', newValue);
  }
});
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_colors';

.pagination {
  margin-top: 25px;
}

.submit-button {
  margin: 68px 0 30px 0;
  display: flex;
  justify-content: flex-end;
}

// .checkbox_big {
//   margin-bottom: 22px;
// }

.ways-to-get {
  &__list {
    display: flex;
    margin-top: 20px;
  }

  &__list li:not(:last-child) {
    margin-right: 40px;
  }
}

.clients-table {
  margin-top: 20px;

  &__checkbox {
    background: var(--primary-color);
    border-radius: 3px;
    width: 27px;
    height: 27px;
  }

  &__row {
    cursor: pointer;
  }
}

.section {
  &__title {
    border-top: 1px solid var(--grey-opacity);
    padding: 17px 0;
  }

  &__title span {
    color: var(--black);
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
  }
}

.filter {
  display: flex;

  &__label {
    color: map-get($black-text, 'normal');
    font-size: 12px;
    line-height: 22px;
  }

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__item:not(:last-child) {
    margin-right: 17px;
  }

  &__input {
    margin-top: 7px;
    background: var(--grey-opacity-light);
    border-radius: 2px;
    font-size: 12px;
    line-height: 22px;
    color: map-get($black-text, 'normal');
    border: none;
    height: 24px;
    padding-left: 10px;
  }
}

.tab-container {
  border-top: 1px solid var(--grey-opacity);
  margin-top: 37px;
}

.send {
  &__title {
    padding: 16px 0;
  }

  &__title span {
    color: var(--black);
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
  }

  &__textarea {
    resize: vertical;
    height: 44px;
    min-height: 44px;
    max-height: 300px;
    font-family: 'Golos', sans-serif;
  }

  &__inputs {
    display: flex;
    margin-bottom: 37px;
    &__item:not(:last-child) {
      margin-right: 17px;
    }
  }

  &__input {
    color: map-get($primary-text, 'normal');
    font-size: 16px;
    line-height: 22px;
    padding: 10px 0 10px 12px;
    border: 1px solid var(--black-shadow);
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;
    margin-top: 5px;

    &_theme {
      width: 30%;
    }

    &_content {
      width: 70%;
    }
  }
}
.subitem {
  max-height: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-out;
  margin-left: 50px;
}
.subitem.active {
  visibility: visible;
  opacity: 1;
}
.search-input-wrapper {
  position: relative;
  width: 300px;
  height: 34px;
  margin-right: 20px;
}
.search-input {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: var(--light-secondary-bg);
  border: none;
  padding: 0 20px 0 40px;
  font-family: 'Golos';
  font-size: 16px;
}
.search-input-icon {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 10px;
  color: var(--grey-dark-color);
}
.select {
  width: 250px;
}
.recipients__filter {
  display: flex;
  align-items: center;
}
</style>
