<template>
  <base-popup
    size="large"
    topIndent="80"
    :is-visible="isModalVisible"
    @close="onModalClose"
    title="Создать опрос"
  >
    <div :class="{ popup_disabled: isLoading }">
      <div class="input__item">
        <p class="input__item-label">Тема опроса*</p>
        <input
          type="text"
          placeholder="\"
          class="modal-input modal-item"
          v-model.trim="currentVote.title"
        />
      </div>
      <div class="input__item">
        <p class="input__item-label">Варианты ответа*</p>
        <div class="input__wrapper" v-for="(item, idx) in voteItems" :key="idx">
          <span class="input__label">Вариант {{ ++idx }}</span>
          <input
            type="text"
            placeholder="\"
            class="modal-input modal-item"
            ref="variants"
            v-model.trim="item.title"
            maxlength="30"
          />
          <div
            class="delete-item"
            v-if="idx > 2"
            @click="onDeleteVoteItem(item)"
          >
            <mdicon size="24" name="close" class="delete-icon" />
          </div>
        </div>
        <div
          class="new-item-btn"
          @click="onNewVoteItem"
          :class="{ visible: isMoreVariantsVisible }"
        >
          Добавить вариант ответа
        </div>
      </div>
      <div class="input__item">
        <p class="input__item-label">Получатели*</p>
        <div>
          <BaseRadioButtonGroup
            @change="onRecipientChange"
            :options="resipientsRadioButtonList"
            :isHorizontal="true"
            name="resipients"
            :defaultValue="resipientsDefaultValue"
          />
          <v-select
            ref="select"
            class="modal-select modal-select_recipient"
            :options="recipientOptions"
            placeholder="Участники опроса*"
            :multiple="true"
            :reduce="
              (value) => {
                return { id: value.id, count: value.count, title: value.value };
              }
            "
            label="value"
            v-model="buildings"
          >
            <template v-slot:no-options>
              <div>Извините, ничего не найдено</div>
            </template>
            <template #list-header>
              <li class="select-header vs__dropdown-option" @click="selectAll">
                Все
              </li>
            </template>
            <template v-slot:selected-option="{ value }">
              {{ value }}
            </template>
          </v-select>
        </div>
      </div>
      <div class="input__item">
        <p class="input__item-label">Параметры*</p>
        <BaseRadioButtonGroup
          @change="onVariantChange"
          :options="variantsRadioButtonList"
          :isHorizontal="false"
          name="variants"
          :defaultValue="variantsDefaultValue"
        />
      </div>
      <Transition>
        <div class="input__item" v-if="allPeople">
          <p class="input__item-label">Срок*</p>
          <BaseRadioButtonGroup
            @change="onExpirationChange"
            :options="expirationRadioButtonList"
            :isHorizontal="true"
            name="expiration"
            :defaultValue="expirationDefaultValue"
          />
          <div class="expiration__inputs">
            <div v-if="expirationValue === 'percent'" class="d-flex">
              <BaseInputRange
                :min="1"
                :value="rangeInputValue"
                @change="onRangeInputChange"
              />
              <div class="percent-text ml-4">
                <span>{{ percent }}</span
                >%
              </div>
              <div class="people-text ml-2">{{ people }} чел.</div>
            </div>
            <Datepicker
              v-else-if="expirationValue === 'time'"
              class="datepicker"
              v-model="date"
              locale="ru-RU"
              :min-date="minDate"
              selectText="Выбрать"
              cancelText="Отмена"
              :format="'dd/MM/yyyy'"
              placeholder="Выберите дату и время"
              :enable-time-picker="false"
            >
            </Datepicker>
          </div>
        </div>
      </Transition>
    </div>
    <div class="buttons d-flex justify-content-end">
      <div class="loader" v-if="isLoading">
        <img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" />
      </div>
      <base-button
        text="Отмена"
        color="gray"
        :disabled="isLoading"
        class="mr-3"
        @click="onModalClose"
      />

      <base-button
        text="Создать"
        color="green"
        :disabled="isVariantsInvalid || isFormDataInvalid || isLoading"
        @click="onFormSubmit"
      />
    </div>
  </base-popup>
</template>
<script setup>
import { defineEmits, defineProps, onMounted, ref, computed, watch } from 'vue';
import {
  BasePopup,
  BaseRadioButtonGroup,
  BaseButton,
  BaseInputRange,
} from '@/components/atoms';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { getBuildings, createVote } from '@/graphql/Votes.graphql';
import { useQuery, useMutation } from '@vue/apollo-composable';
import { useStore } from 'vuex';
// import { THEME } from '@/constant/main'
const THEME = config.VUE_APP_THEME;
const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');
const store = useStore();

const emit = defineEmits(['close', 'create']);

const emptyVote = {
  id: null,
  title: null,
  options: null,
  toAll: null,
  isMultiple: null,
  buildingIds: null,
  termType: null,
  termValue: null,
};
const currentVote = ref({
  id: null,
  title: null,
  options: null,
  toAll: null,
  isMultiple: null,
  buildingIds: null,
  termType: null,
  termValue: null,
});

const isLoading = ref(false);

const buildings = ref(null);
const buildingsList = ref([]);
const { result: allBuildings } = useQuery(getBuildings);

watch(allBuildings, (newValue) => {
  buildingsList.value = newValue.getBuildings;
  // console.log('buildings', buildingsList.value);
  recipientOptions.value = newValue.getBuildings.map(({ id, name, count }) => {
    return {
      id,
      value: name,
      count,
    };
  });
});

const variants = ref(null);

const select = ref(null);

const selectAll = () => {
  select.value.searchEl.blur();
  buildings.value = buildingsList.value.map(({ id, name, count }) => {
    return {
      id,
      value: name,
      count,
    };
  });
};

watch(buildings, (newValue) => {
  if (newValue) {
    let tempCount = 0;
    newValue.map(({ count }) => (tempCount += count));
    allPeople.value = tempCount;
    people.value = Math.floor((allPeople.value * percent.value) / 100);
    if (newValue?.length) {
      currentVote.value.termValue = people.value;
    }
  } else {
    rangeInputValue.value = percent.value;
    onRangeInputChange(rangeInputValue.value);
  }
});

const rules = computed(() => {
  return {
    title: { required },
    termValue: { required },
  };
});

const v$ = useVuelidate(rules, currentVote);

const isFormDataInvalid = computed(() => {
  return v$.value.$invalid;
});

const props = defineProps({
  isModalVisible: {
    type: Boolean,
    default: false,
  },
});

watch(
  () => props.isModalVisible,
  (newValue) => {
    if (newValue) {
      onRecipientChange(resipientsDefaultValue.value);
      onVariantChange(variantsDefaultValue.value);
      onExpirationChange(expirationDefaultValue.value);
      onRangeInputChange(55);
    }
  }
);

const onModalClose = () => {
  emit('close');
  currentVote.value = ref({ ...emptyVote }).value;
  buildings.value = [];
  voteItems.value = [
    { title: '', id: null },
    { title: '', id: null },
  ];
};

const voteItems = ref([
  { title: '', id: null },
  { title: '', id: null },
]);

// const isVariantsInvalid = ref(true);

// const checkVariant = () => {
//   isVariantsInvalid.value =
//     voteItems.value.filter(({ title }) => title).length !==
//     voteItems.value.length;
//   // if (variant) {
//   //   isVariantsInvalid.value = false;
//   // } else {
//   //   isVariantsInvalid.value = true;
//   // }
// };

// watch(
//   () => voteItems.value[0].title,
//   () => {
//     checkVariant();
//   }
// );
// watch(
//   () => voteItems.value[1].title,
//   () => {
//     checkVariant();
//   }
// );

// watch(
//   () => voteItems.value.length,
//   () => {
//     checkVariant();
//   }
// );

// const onVariantInput = (e) => {
//   const text = e.target.value;
//   if (!text) {

//   }
// };

const isMoreVariantsVisible = computed(() => {
  return (
    voteItems.value.filter(({ title }) => title).length ===
    voteItems.value.length
  );
});

const rulesVariants = computed(() => {
  return {
    $each: helpers.forEach({
      title: { required },
    }),
  };
});

const v$Variants = useVuelidate(rulesVariants, voteItems);

const isVariantsInvalid = computed(() => {
  return v$Variants.value.$invalid;
});

const onNewVoteItem = () => {
  voteItems.value.push({ title: '', id: null });
  setTimeout(() => {
    const lastItem = variants.value[variants.value.length - 1];
    lastItem.focus();
  }, 200);
};
const onDeleteVoteItem = (item) => {
  const index = voteItems.value.indexOf(item);

  if (index !== -1) {
    voteItems.value.splice(index, 1);
  }
};

const date = ref(null);

watch(date, (newValue) => {
  if (newValue) {
    currentVote.value.termValue = new Date(newValue).toLocaleDateString(
      'ru-RU'
    );
  }
});

const resipientsDefaultValue = ref('all');
const variantsDefaultValue = ref('one');
const expirationDefaultValue = ref('percent');

const rangeInputValue = ref(0);

const resipientsRadioButtonList = [
  { label: 'Собственники и жильцы', value: 'all' },
  { label: 'Только собственники', value: 'owner' },
];
const variantsRadioButtonList = [
  { label: 'Один вариант ответа', value: 'one' },
  { label: 'Несколько вариантов ответа', value: 'several' },
];
const expirationRadioButtonList = [
  { label: 'По процентам', value: 'percent' },
  { label: 'По времени', value: 'time' },
];

const onRecipientChange = (value) => {
  value === 'all'
    ? (currentVote.value.toAll = true)
    : (currentVote.value.toAll = false);
};
const onVariantChange = (value) => {
  value === 'one'
    ? (currentVote.value.isMultiple = false)
    : (currentVote.value.isMultiple = true);
};
const expirationValue = ref(null);
const onExpirationChange = (value) => {
  expirationValue.value = value;
  if (value === 'percent') {
    currentVote.value.termType = 'COUNT';
    if (buildings.value?.length) {
      currentVote.value.termValue = people.value;
    }
    date.value = '';
  } else {
    currentVote.value.termType = 'DATE';
    currentVote.value.termValue = '';
  }
};

const percent = ref(null);
const people = ref(null);
const allPeople = ref(0);

watch(allPeople, (newValue) => {
  if (!newValue) {
    currentVote.value.termValue = null;
  }
});

const onRangeInputChange = (value) => {
  rangeInputValue.value = value;
  percent.value = value;
  people.value = Math.floor((allPeople.value * percent.value) / 100);
  if (buildings.value?.length) {
    currentVote.value.termValue = people.value;
  }
};

const recipientOptions = ref([]);
onMounted(() => {
  onRecipientChange(resipientsDefaultValue.value);
  onVariantChange(variantsDefaultValue.value);
  onExpirationChange(expirationDefaultValue.value);
  // onRangeInputChange(rangeInputValue.value);
  onRangeInputChange(55);
});

const { mutate: createVoteMutation } = useMutation(createVote);

const onVoteCreate = async () => {
  const { title, toAll, isMultiple, termType, termValue } = currentVote.value;
  const options = voteItems.value.map(({ title }) => title);
  const buildingIds = buildings.value.map(({ id }) => id);

  await createVoteMutation({
    data: {
      title,
      options,
      toAll,
      isMultiple,
      buildingIds,
      termType,
      termValue: String(termValue),
    },
  });
  emit('create');
};

const onFormSubmit = async () => {
  isLoading.value = true;
  await onVoteCreate();

  store.dispatch('notification/showNotification', {
    text: `Опрос успешно создан`,
    type: 'success',
  });
  onModalClose(true);
  isLoading.value = false;
};

const minDate = computed(() => {
  return new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
});
</script>
<style scoped lang="scss">
@import '~@/assets/styles/_colors';

.popup_disabled {
  pointer-events: none;
  opacity: 0.5;
  transition: all 0.3s ease 0s;
}
.modal-input {
  border: 1px solid map-get($lightgray-stroke, 'normal');
  border-radius: 10px;
  padding: 8px 10px;
  width: 100%;
  font-family: 'Golos';
  font-size: 16px;
  font-weight: 400;
}
.modal-input::placeholder {
  font-weight: 400;
  font-size: 16px;
  color: map-get($lightgray-text, 'normal');
}
// .modal-item:not(:last-child) {
//   margin-bottom: 20px;
// }
.modal-item {
  width: 100%;
  position: relative;
}
.input__item-label {
  color: var(--black);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.25;
  margin-bottom: 15px;
}
.input__wrapper {
  display: flex;
  align-items: center;
  position: relative;
}
.input__wrapper:not(:last-child) {
  margin-bottom: 10px;
}
.input__label {
  color: var(--grey-text-color);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
  white-space: nowrap;
  margin-right: 25px;
}
.input__item {
  margin-bottom: 40px;
}
.new-item-btn {
  color: map-get($green, 'normal');
  cursor: pointer;
  width: fit-content;
  margin: 20px 0 0 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
}
.new-item-btn.visible {
  opacity: 1;
  visibility: visible;
}
.delete-item {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
  height: calc(100% - 8px);
  width: 29px;
  background-color: map-get($lightgray-stroke, 'normal');
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.delete-icon {
  color: var(--white);
}
.expiration__inputs {
  margin-top: 20px;
  // width: 300px;
  width: 100%;
  height: 38px;
}
.buttons {
  margin-top: 35px;
  position: relative;
  padding-top: 20px;
  position: relative;
  height: 50px;
}
.buttons::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--grey-purple);
}
.percent-text {
  color: var(--black);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
  margin-right: 10px;
  // width: 42px;
}
.percent-text span {
  width: 30px;
  display: inline-block;
}
.people-text {
  color: #676767;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
}
.datepicker {
  width: 60%;
}
.modal-select_recipient {
  margin-top: 20px;
  width: 250px;
}
.select-header:hover {
  background-color: var(--primary-color);
  color: var(--white);
}
.loader {
  height: 100px;
  position: relative;
  transform: translateY(-30%);
  left: 0;
}
.loader img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
</style>
