<template>
<base-popup
    size="largest"
    topIndent="80"
    :is-visible="isModalVisible"
    title="Сформировать PDF уведомление"
    subtitle=""
    @close="onModalClose"
>

<div class="block__item">
    <span class="select-label">Тема письма</span>
    <input
        type="text"
        placeholder="Тема письма"
        class="filter-input"
        v-model="emailData.theme"
    />
</div>
<div class="block__item">
    <span class="select-label">Содержание</span>
    <textarea
        type="text"
        placeholder="Содержание письма"
        class="filter-input filter-textarea"
        v-model="emailData.content"
    />
</div>
<div class="users">
    <div v-for="(user, idx) in users" :key="idx" class="pdf-item d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
            <div class="mr-10">
                <img src="@/assets/images/pdf.png" alt="pdf-icon">
            </div>
            <div>
                <span class="user-gray">Пользователь: </span>
                <span class="user-name">{{ user.firstName ? user.firstName : user.user.firstName }} {{ user.lastName ? user.lastName : user.user.lastName }}</span> 
            </div>
        </div>
        <div class="d-flex user-button">
            <div>Просмотреть</div>
            <mdicon name="chevron-right" size="16" /> 
        </div>
    </div>
</div>

<div class="line"></div>
<div class="d-flex justify-content-between">
    <div class="d-flex">
        <base-button
            text="Распечатать"
            color="transparent"
            textColor="green"
            class="mr-3"
            :icon="{
                name: 'printer-outline',
                color: 'var(--primary-color)'
            }"
            @click.prevent="onModalClose"
        />
        <base-button
            text="Скачать"
            color="transparent"
            textColor="gray"
            :icon="{
                name: 'download',
                color: '#949494'
            }"
            class="mr-3"
            @click.prevent="onModalClose"
        />
    </div>

    <base-button
        text="Отправить"
        color="green"
        @click.prevent="onFormSubmit"
    />
</div>
</base-popup>
</template>
<script setup>
import { defineProps, defineEmits, reactive, watch } from 'vue';
import { BasePopup, BaseButton } from '@/components/atoms';
import { useMutation } from '@vue/apollo-composable';
import { getDebtorsDocs, createDebtorsDocs } from '@/graphql/Debtors.graphql';
import { useStore } from 'vuex';

const store = useStore();

const emit = defineEmits(['close']);

const props = defineProps({
    isModalVisible: {
        type: Boolean,
        default: false,
    },
    users: Array
});

const emailData = reactive({
    theme: '',
    content: ''
});

const { mutate: getDebtorsMutate } = useMutation(getDebtorsDocs);
const { mutate: createDocsMutate } = useMutation(createDebtorsDocs);

const onModalClose = (isSubmit) => {
    isSubmit !== true ? (isSubmit = false) : (isSubmit = true);
    let isClosed = true;

    if (!isSubmit) {
        isClosed = confirm(
            'Вы действительно хотите закрыть окно? Внесенные изменения не сохранятся'
        );
    }
    if (isClosed) {
        emailData.theme = '';
        emailData.content = ''; 
        emit('close');
    }
};

const onFormSubmit = async () => {
    if (!emailData.theme.trim() ||!emailData.content.trim()) {
        store.dispatch('notification/showNotification', {
            type: 'error',
            text: 'Поля не должны быть пустыми'
        });
        // console.log("Ошибка: поля theme или content пустые");
        return;
    }

    let debtorsIds = props.users.map(user => user.id);
    const { theme, content } = emailData;

    const { data } = await createDocsMutate({ debtorsIds, theme, content });

    if (data.createDebtorsDocs)
        onModalClose();
        store.dispatch('notification/showNotification', {
            type: 'success',
            text: 'PDF документы были высланы выбранным пользователям'
        });
}

watch(() => props.isModalVisible, async (value) => {
    if (value && props.users) {
        let debtorsIds = props.users.map(user => user.id);
        await getDebtorsMutate({ debtorsIds });
    }
})
</script>

<style scoped lang="scss">
@import '~@/assets/styles/_colors';
.block {
    position: relative;
    width: 100%;
}

.block__item:nth-child(1) {
    margin-right: 10px;
    width: 40%;
}

.block__item:nth-child(2) {
    width: 60%;
}

.second-block {
    width: 100%;
}

.select-label {
  font-family: 'Golos' !important;
  font-size: 14px;
  margin-bottom: 9px;
  display: block;
}

.filter-input {
  font-family: 'Golos' !important;
  margin-bottom: 15px;
  height: 34px;
  font-size: 14px !important;
  width: 100%;
  margin-right: 20px;
  border: 1px solid map-get($lightgray-stroke, 'normal');
  border-radius: 10px;
  padding: 0 0 0 10px;
}
.filter-input::placeholder {
  font-size: 14px;
  font-weight: 400;
  opacity: .9;
}

.filter-textarea {
    width: 100% !important;
    padding-top: 5px;
    margin: 0;
    font-family: 'Golos' !important;
    height: 80px;
    resize: none;
}
.users {
    margin: 25px 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.user {
    &-gray {
        font-size: 13px;
        font-weight: 500;
        color: #8A8A8A;
    }

    &-name {
        font-size: 14px;
        font-weight: 600;
        color: initial;
    }

    &-button {
        font-size: 13px;
        font-weight: 500;
        color: #DB4E4E; 
        cursor: pointer;
    }
}

.pdf-item {
    padding: 5px;
    box-shadow: 0px 4px 4px 0px #00000012;
    border-radius: 14px;
}
</style>