<template>
  <div class="list">
    <div class="list__search">
      <mdicon class="list__search-icon" name="magnify" size="20" />
      <input
        @input="filterServices"
        ref="searchInputRef"
        class="list__search-input"
        alt="search"
      />
    </div>

    <div class="list__menu">
      <div v-if="isLoading" class="mt-20 text-center">
        <img
          :src="THEME === 'domion' ? loaderDomion : loaderGreen"
          alt="loading"
        />
      </div>
      <div
        v-else
        v-for="(item, idx) in filteredServices"
        :key="idx"
        class="list__menu-item"
        :class="chosedItem === item.name ? 'list__menu-item--active' : ''"
        @click="selectItem(item.name)"
      >
        <div class="list__menu-item-icon">
          <mdicon :name="item.icon" size="27" />
        </div>
        <div class="list__menu-item-title">{{ item.name }}</div>
        <div class="list__menu-item-arrow">
          <mdicon name="chevron-right" size="24" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

const THEME = config.VUE_APP_THEME;
const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');
const store = useStore();
const sessionToken = store.getters['app/getSessionToken'];

const props = defineProps({
  isModalVisible: Boolean,
  selectedService: Boolean,
});

const emit = defineEmits(['onSelected']);

const searchInputRef = ref(null);
const isLoading = ref(true);
const services = ref([]);
const chosedItem = ref('');
let searchText = ref('');

const focusSearchInput = () => {
  searchInputRef.value.focus();
};

const filterServices = (event) => {
  searchText.value = event.target.value.toLowerCase();
  filteredServices.value = services.value.filter((service) =>
    service.name.toLowerCase().includes(searchText.value)
  );
};

const filteredServices = computed(() => {
  return services.value.filter((service) =>
    service.name.toLowerCase().includes(searchText.value)
  );
});

const selectItem = (name) => {
  chosedItem.value = name;

  emit('onSelected', chosedItem.value);
};

const getAgentGroups = async () => {
  try {
    const response = await fetch(
      `https://trouter.domion.tech/genbank/agent-groups`,
      {
        // const response = await fetch(`${config.VUE_APP_API_TROUTER}/genbank/agent-groups`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      const data = await response.json();
      services.value = data;
      isLoading.value = false;
    }
  } catch (error) {
    // console.error('There was a problem with the fetch operation:', error);
  }
};

watch(
  () => props.selectedService,
  (value) => {
    if (value === false) chosedItem.value = '';
  }
);

onMounted(() => {
  getAgentGroups();
  focusSearchInput();
});
</script>
<style lang="scss" scoped>
.list {
  &__search {
    width: 370px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    background: #f4f4f4;

    &-icon {
      margin-left: 10px;
    }

    &-input {
      width: 100%;
      height: 100%;
      padding: 10px 5px;
      border: none;
      background: transparent;
    }
  }

  &__menu {
    margin-top: 25px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: scroll;

    &-item {
      padding: 18px;
      display: flex;
      align-items: center;
      width: 100%;
      background-color: #fbfbfb;
      border-radius: 15px;
      cursor: pointer;

      &--active {
        box-shadow: inset 0 0 0 2px var(--primary-color); /* Example: White border */
      }

      &-icon {
        margin-right: 15px;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--primary-color);
        border-radius: 50%;
        background: #ffffff;
      }

      &-title {
        font-size: 16px;
        font-weight: 700;
      }

      &-arrow {
        margin-left: auto;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #ffffff;
        color: var(--primary-color);
      }
    }
  }
}
</style>
