<template>
<div class="client-fs" :style="{ backgroundImage: `url(${backgroundImageMain})` }">
    <div class="client-fs__banner" :style="{ backgroundImage: `url(${backgroundImage})` }">
        <div class="client-fs__banner-text">
            <div class="client-fs__banner-text--wrapper">
                <div class="client-fs__banner-text-title">{{ title }}</div>
                <div class="client-fs__banner-text-subtitle">
                    {{ subtitle }}
                </div>
            </div>
            <div class="client-fs__banner-text-buttons">
                <base-button 
                    @click="openDownloadLink" 
                    externalClasses="client-fs__banner-text-buttons-btn" 
                    text="Скачать приложение" 
                    color="green" 
                    size="big" 
                    @click.prevent="alert('download')" 
                /> 
                <a href="https://apps.apple.com/ru/app/%D0%B3%D1%80%D0%B8%D0%BD-%D0%B4%D0%BE%D0%BC/id1615786010" target="_blank" rel="noopener noreferrer" class="client-fs__banner-text-buttons-market">
                    <mdicon name="apple" size="24" /> 
                </a> 
                <a href="https://apps.rustore.ru/app/org.nativescript.greenwoodmobile" target="_blank" rel="noopener noreferrer" class="client-fs__banner-text-buttons-market">
                    <mdicon name="android" size="24" /> 
                </a> 
            </div>
        </div> 

        <div class="client-fs__banner-empty"></div>
    </div>

    <div class="client-fs__image">
        <img class="client-fs__image-item" src="@/assets/images/client/slides/phones.png" alt="two-phones"/>
    </div>
</div>
</template>

<script setup>
import { BaseButton } from '@/components/atoms';
import blueBg from '@/assets/images/client/slides/circles-bg.png';
import greenBg from '@/assets/images/claims-green/circle.png';
import { onMounted } from 'vue';
import { ref } from 'vue';

const THEME = config.VUE_APP_THEME
const backgroundImage = ref('none');
const backgroundImageMain = ref('none');

let title, subtitle;

if (THEME === 'domion') {
    title = `Добро пожаловать\nв личный кабинет Домион!`
    backgroundImage.value = blueBg;
    subtitle = `Здесь вы найдете все необходимое для управления вашим домом:\n\n Следите за состоянием вашего лицевого счета. Оплачивайте коммунальные услуги онлайн в любое удобное время. Оставляйте заявки для управляющей компании и отслеживайте их выполнение. Будьте в курсе последних новостей вашего жилого комплекса. Управляйте вашим жильем с легкостью в личном кабинете Домион!`
} else {
    title = `Добро пожаловать\nв личный кабинет Грин.Дом!`
    backgroundImage.value = greenBg;
    subtitle = `Здесь вы найдете все необходимое для управления вашим домом:\n\n Следите за состоянием вашего лицевого счета. Оплачивайте коммунальные услуги онлайн в любое удобное время. Оставляйте заявки для управляющей компании и отслеживайте их выполнение. Будьте в курсе последних новостей вашего жилого комплекса. Управляйте вашим жильем с легкостью в личном кабинете Грин.Дом!`
}

const openDownloadLink = () => {
    window.open('https://xn--b1abeozqy.xn--p1ai/prilozhenie-grin-dom/', '_blank');
}

const toggleBackground = () => {
    if (window.innerWidth <= 740 && THEME === 'domion') {
        backgroundImageMain.value = blueBg;
        backgroundImage.value = 'none';
    } else if (window.innerWidth <= 740 && THEME !== 'domion') {
        backgroundImageMain.value = greenBg;
        backgroundImage.value = 'none';
    }
};

onMounted(() => {
    toggleBackground();
    window.addEventListener('resize', toggleBackground);
});
</script>

<style scoped lang="scss">
.client-fs {
    position: relative;
    width: 100%;
    height: 530px;
    display: flex;
    align-items: end;

    &__banner {
        padding: 47px 35px;
        width: 100%;
        height: 400px;
        display: flex;
        background-color: rgba(244, 244, 245, 1);
        // background-image: url('../../../../../../src/assets/images/client/slides/circles-bg.png');
        background-position: top right;
        background-repeat: no-repeat;
        border-radius: 29px;
        box-sizing: border-box;

        &-text {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-align: left; 

            &-title {
                margin-bottom: 20px;
                font-size: 37px;
                font-weight: 500;
                white-space: pre-wrap;
            }

            &-subtitle {
                margin-bottom: 10px;
                max-width: 473px;
                font-size: 16px;
                font-weight: 500;
                opacity: .5;
                white-space: pre-wrap;
            }

            &-buttons {
                display: flex;
                align-items: center;
                gap: 4px;

                &-btn {
                    padding: 0;
                }

                &-market {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    border-radius: 9px;
                    color: var(--white);
                    background-color: var(--primary-color);
                    cursor: pointer;
                }
            }
        }

        &-empty {
            min-width: 497px;
        }
    }

    &__image {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 497px;
    }
}

@media(max-width: 1420px) {
    .client-fs {
        &__banner {
            padding: 30px 35px;

            &-text {
                &-title {
                    font-size: 28px;
                }

                &-subtitle {
                    font-size: 15px;
                }
            }
        }
    }
}

@media(max-width: 1230px) {
    .client-fs {
        height: 430px;

        &__banner {
            height: 360px;

            &-text {
                &-title {
                    font-size: 24px;
                }

                &-subtitle {
                    font-size: 14px;
                }
            }
        }

        &__image {
            &-item {
                width: 400px;
            }
        }
    }
}

@media(max-width: 1140px) {
    .client-fs {
        &__banner {
            padding: 25px 30px;
            background-size: 50%;

            &-empty {
                min-width: 350px;
            }
        }

        &__image {
            width: 350px;

            &-item {
                width: 350px;
            }
        }
    }
}

@media(max-width: 1050px) {
    .client-fs {
        height: 360px;

        &__banner {
            &-empty {
                min-width: 320px;
            }
        }

        &__image {
            width: 300px;

            &-item {
                width: 300px;
            }
        }
    }
}

@media(max-width: 740px) {
    .client-fs {
        height: 100%;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        border-radius: 29px;
        background-color: rgba(244, 244, 245, 1);
        background-repeat: no-repeat;

        &__banner {
            margin-bottom: auto;
            height: auto;
            justify-content: center;
            background: transparent;

            &-text {
                justify-content: flex-start;
                text-align: center;

                &-buttons {
                    margin-top: 10px;
                    width: 100%;
                    justify-content: center;
                }
            }

            &-empty {
                display: none;
            }
        }

        &__image {
            position: initial;
            width: 380px;

            &-item {
                width: 380px;
            }
        }
    }
}

@media(max-width: 400px) {
    .client-fs {
        &__image {
            width: 300px;

            &-item {
                width: 100%;
            }
        }
    }
}

@media(max-width: 350px) {
    .client-fs {
        &__banner {
            &-text {
                width: 100%;
                justify-content: center;
                &--wrapper {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                &-buttons {
                    width: 100%;
                }
            }
        }
    }
}
</style>