<template>
    <div class="client-second-slide" :style="{ backgroundImage: `url(${backgroundImageMain})` }">
        <div class="client-second-slide__banner" :style="{ backgroundImage: `url(${backgroundImage})` }">
            <div class="client-second-slide__banner-text">
                <div class="client-second-slide__banner-text-title">Оплаты</div>
                <div class="client-second-slide__banner-text-subtitle">
                    {{ subtitle }}
                </div>
                <base-button @click="pushToPayments" text="Подробнее" color="green" size="big" /> 
            </div>
        </div>

        <div class="client-second-slide__image">
            <img class="client-fs__image-item" :src="rubImage" alt="rubles"/> 
        </div>
    </div>
</template>

<script setup>
import { BaseButton } from '@/components/atoms';
import { useRouter } from 'vue-router';
import greenRubles from '@/assets/images/claims-green/money.png';
import domionRubles from '@/assets/images/client/slides/rubles.png';
import greenBgRub from '@/assets/images/claims-green/bg-money.png';
import domionBgRub from '@/assets/images/client/slides/payments-bg.png';
import { onMounted } from 'vue';
import { ref } from 'vue';

const THEME = config.VUE_APP_THEME;
const router = useRouter();

const rubImage = THEME === 'domion' ? domionRubles : greenRubles;
// const backgroundImage = THEME === 'domion' ? domionBgRub : greenBgRub; 
const subtitle = THEME === 'domion' ? 
`Оплачивайте коммунальные услуги удобно и без лишних хлопот с личным кабинетом Домион.\n\n Воспользуйтесь преимуществами:\n\n Следите за состоянием лицевого счета. Просматривайте историю начислений. Контролируйте историю оплат. Оплачивайте счета в один клик. Простота и удобство в каждой операции.`
:
`Оплачивайте коммунальные услуги удобно и без лишних хлопот с личным кабинетом Грин.Дом.\n\n Воспользуйтесь преимуществами:\n\nСледите за состоянием лицевого счета. Просматривайте историю начислений. Контролируйте историю оплат. Оплачивайте счета в один клик. Простота и удобство в каждой операции.`

const backgroundImage = ref('none');
const backgroundImageMain = ref('none');

if (THEME === 'domion') {
    backgroundImage.value = domionBgRub;
} else {
    backgroundImage.value = greenBgRub;
}

const pushToPayments = async () => {
    await router.push('/app/client/payments');
}

const toggleBackground = () => {
    if (window.innerWidth <= 740 && THEME === 'domion') {
        backgroundImageMain.value = domionBgRub;
        backgroundImage.value = 'none';
    } else if (window.innerWidth <= 740 && THEME !== 'domion') {
        backgroundImageMain.value = greenBgRub;
        backgroundImage.value = 'none';
    }
};

onMounted(() => {
    toggleBackground();
    window.addEventListener('resize', toggleBackground);
});
</script>

<style scoped lang="scss">
.client-second-slide {
    position: relative;
    width: 100%;
    height: 530px;
    display: flex;
    align-items: end;
    overflow: hidden;

    &__banner {
        padding: 47px 35px;
        width: 100%;
        min-height: 399px;
        // background-image: url('../../../../../../src/assets/images/client/slides/payments-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 29px;
        box-sizing: border-box;

        &-text {
            max-width: 50%;
            text-align: left;

            &-title {
                margin-bottom: 20px;
                font-size: 37px;
                font-weight: 500;
                color: var(--primary-color);
            }

            &-subtitle {
                max-width: 408px;
                margin-bottom: 38px;
                font-size: 16px;
                font-weight: 500;
                opacity: .5;
            }
        }
    }

    &__image {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

@media(max-width: 1420px) {
    .client-second-slide {
        &__banner {
            &-text {
                &-title {
                    font-size: 28px;
                }

                &-subtitle {
                    font-size: 15px;
                }
            }
        }
        &__image {
            right: -80px;
        } 
    }
}

@media(max-width: 1230px) {
    .client-second-slide {
        height: 430px;

        &__banner {
            min-height: 360px;

            &-text {
                &-title {
                    font-size: 24px;
                }

                &-subtitle {
                    font-size: 14px;
                } 
            }
        }
        &__image {
            right: -120px;
        } 
    }

    .client-fs__image-item {
        width: 600px;
    }
}

@media(max-width: 1140px) {
    .client-second-slide {
        &__banner {
            padding: 25px 30px;
            background-size: 150%;

            &-empty {
                min-width: 350px;
            }
        }
    }
    .client-fs__image-item {
        width: 540px;
    }
}

@media(max-width: 1050px) {
    .client-second-slide {
        height: 360px;
    }
    .client-fs__image-item {
        width: 510px;
    }
}

@media(max-width: 740px) {
    .client-second-slide {
        height: 100% !important;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        border-radius: 29px;
        background-repeat: no-repeat;
        background-size: 600%;

        &__banner {
            min-height: auto;
            justify-content: center;
            background: transparent;

            &-text {
                max-width: 100%;
                justify-content: flex-start;
                text-align: center;

                &-subtitle {
                    max-width: 100%;
                }

                &-buttons {
                    margin-top: 10px;
                    width: 100%;
                    justify-content: center;
                }
            }
        }

        &__image {
            right: 0;
            position: initial;
        }
    }

    .client-fs__image-item {
        width: 100%;
    }
}

@media(max-width: 400px) {
    .client-second-slide {    
        height: auto;
        margin-bottom: auto;
    }
}
</style>