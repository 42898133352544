<template>
  <div>
    <h1 class="header header_black">Справочник</h1>
    <div class="d-flex justify-content-between mb-4">
      <span @click="router.back()" class="d-flex align-items-center sub-tltle">
        <mdicon size="24" class="arrow" name="chevron-left" /><span
          >Исполнители</span
        >
      </span>
      <div class="d-flex align-items-center">
        <base-button
          text="Добавить исполнителя"
          color="green"
          @click.prevent="onClaimModalOpen"
        />
      </div>
    </div>
    <ExecutorFilter @filter="onExecutorFilterUpdates" />
    <div v-if="isLoading" class="loading">
      <img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" />
    </div>
    <div v-else>
      <ExecutorTable @click="onExecutorUpdate" :executorsList="executors" />
      <the-pagination
        :totalPages="totalPages"
        :currentPage="currentPage"
        @pageChanged="onChangePage"
        class="pagination"
      ></the-pagination>
    </div>
  </div>
  <ExecutorModal
    :is-executor-modal-visible="isExecutorModalVisible"
    @close="setExecutorPopupVisibility(false)"
    :executor="selectedExecutor"
    @create="onExecutorCreated"
    @update="onExecutorUpdated"
    @delete="onExecutorDeleted"
  />
</template>
<script setup>
import { ref, watch, onMounted } from 'vue';
import { BaseButton } from '@/components/atoms';
import ExecutorFilter from './ExecutorFilter.vue';
import ExecutorTable from './ExecutorTable.vue';
import ExecutorModal from './ExecutorModal.vue';
import ThePagination from '@/components/ThePagination.vue';
import { getAllExecutors } from '@/graphql/Claims.graphql';
import { useQuery } from '@vue/apollo-composable';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
// import { THEME } from '@/constant/main'
const THEME = config.VUE_APP_THEME;
const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');
const router = useRouter();

const store = useStore();

const isLoading = ref(true);

const selectedExecutor = ref(null);

const totalPages = ref(0);
const elementsOnPage = ref(5);
const currentPage = ref(1);

// Open Executor modal
const isExecutorModalVisible = ref(false);

const setExecutorPopupVisibility = (status) => {
  isExecutorModalVisible.value = status;

  if (!status) {
    selectedExecutor.value = null;
  }
};
const onClaimModalOpen = () => {
  setExecutorPopupVisibility(true);
};

const executors = ref(null);
const allExecutorsValue = ref(null);

const {
  result: allExecutors,
  refetch,
  fetchMore,
} = useQuery(getAllExecutors, {
  complexId: null,
  pagination: {
    limit: elementsOnPage.value,
    offset: 0,
  },
  filter: null,
});

const getTotalPages = (totalItems) => {
  totalPages.value = Math.ceil(totalItems / elementsOnPage.value);
};

let i = 0;
watch(allExecutors, (newValue) => {
  allExecutorsValue.value = newValue;
  // executors.value = newValue.getAllExecutors.executors;
  // console.log(newValue.getAllExecutors.executors);
  if (i === 0) {
    executors.value = newValue.getAllExecutors.executors;
    getTotalPages(newValue.getAllExecutors.filtered);
    i++;
  }
  // console.log('executors', executors.value);
  isLoading.value = false;
});

const loadMore = (page = 0) => {
  const activePage = page === 1 ? 0 : page;
  fetchMore({
    variables: {
      pagination: {
        offset: (activePage === 0 ? 0 : activePage - 1) * elementsOnPage.value,
        limit: elementsOnPage.value,
      },
    },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      executors.value = [...fetchMoreResult.getAllExecutors.executors];
      getTotalPages(fetchMoreResult.getAllExecutors.filtered);
    },
  });
};

onMounted(() => {
  loadMore();
});

const onExecutorFilterUpdates = async (filter) => {
  // console.log(filter);
  const {
    data: { getAllExecutors },
  } = await refetch({
    filter: {
      category: +filter.category,
      post: +filter.executorPost,
      complex: +filter.complex,
      q: filter.search,
    },
  });
  executors.value = [...getAllExecutors.executors];
  getTotalPages(getAllExecutors.filtered);
  onChangePage(1);
  if (!executors.value.length) {
    await store.dispatch('notification/showNotification', {
      text: 'Совпадений нет',
      type: 'error',
    });
  }
};

const onChangePage = (page) => {
  currentPage.value = page;
  isLoading.value = true;
  loadMore(page);
  isLoading.value = false;
};

const onExecutorUpdate = (executor) => {
  selectedExecutor.value = executor;
  // console.log('selectedExecutor.value', selectedExecutor.value);
  setExecutorPopupVisibility(true);
};

const onExecutorCreated = (executor) => {
  executors.value = [executor, ...executors.value];
  if (executors.value.length >= elementsOnPage.value) {
    executors.value.pop();
  }
  currentPage.value = 1;
  loadMore(currentPage.value);
};

const onExecutorUpdated = (executor) => {
  executors.value = executors.value.map((item) => {
    if (item.id === executor.id) {
      return { ...executor };
    }
    return item;
  });
};

const onExecutorDeleted = (id) => {
  executors.value = executors.value.filter((item) => item.id !== id);

  loadMore(currentPage.value);
};
</script>
<style scoped lang="scss">
@import '~@/assets/styles/_colors';

.sub-tltle {
  color: map-get($primary-text, 'normal');
  font-family: 'Golos';
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
  cursor: pointer;
}
.light-text {
  font-family: 'Golos';
  color: map-get($lightgray-text, 'normal');
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  cursor: pointer;
}
.loading {
  text-align: center;
  margin-top: 40px;
}
</style>
