<template>
 <div class="tab-content" v-show="title == selectedTitle">
    <slot />
</div>
</template>

<script setup>
import { inject, defineProps } from 'vue';

defineProps({
 title: String,
});

const selectedTitle = inject('selectedTitle');
</script>
