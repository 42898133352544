<template>
  <base-popup
    size="large"
    :is-visible="isExecutorModalVisible"
    :title="executor ? 'Редактировать исполнителя' : 'Новый исполнитель'"
    @close="onModalClose"
    :has-right-side-content="Boolean(executor)"
  >
    <template v-slot:right-side-content>
      <div class="executor-modal-delete" @click="onExecutorDelete">
        Удалить исполнителя
      </div>
    </template>
    <div style="display: none">
      <div class="photo-input">
        <mdicon name="camera-outline" size="50" class="photo-input__icon" />
        <img
          v-if="executorPhoto"
          :src="executorPhoto"
          :alt="currentExecutor.name"
          class="photo-input__img"
        />
        <input
          type="file"
          accept="image/jpg image/png"
          class="photo-input__item"
          @change="onPhotoChoose"
        />
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between mt-20">
      <input
        type="text"
        placeholder="ФИО*"
        class="modal-input modal-item"
        v-model="currentExecutor.name"
      />
      <v-select
        class="modal-item modal-select"
        :options="postOptions"
        placeholder="Должность*"
        v-model="postValue"
        :reduce="(value) => value.id"
        label="value"
      >
        <template v-slot:no-options>
          <div>Извините, ничего не найдено</div>
        </template></v-select
      >
    </div>
    <div class="d-flex align-items-center justify-content-between mt-20">
      <v-select
        class="modal-item modal-select"
        :options="complexOptions"
        placeholder="Комплекс*"
        v-model="complexValue"
        :multiple="true"
        :reduce="(value) => value.id"
        label="value"
      >
        <template v-slot:no-options>
          <div>Извините, ничего не найдено</div>
        </template></v-select
      >
      <v-select
        class="modal-item modal-select"
        :options="categoriesOptions"
        placeholder="Категория*"
        :multiple="true"
        v-model="categoriesValue"
        :reduce="(value) => value.id"
        label="value"
      >
        <template v-slot:no-options>
          <div>Извините, ничего не найдено</div>
        </template></v-select
      >
    </div>
    <div class="d-flex align-items-end justify-content-between mt-20">
      <div>
        <div class="modal-item">
          <label class="modal-label_left">Номер телефона*</label>
          <input
            type="text"
            v-mask="phoneMask"
            placeholder="\"
            class="modal-input modal-input_label"
            v-model="currentExecutor.phone"
          />
        </div>
        <div class="modal-item">
          <label class="modal-label_left">Почта</label>
          <input
            type="email"
            placeholder="\"
            class="modal-input modal-input_label"
            v-model="currentExecutor.email"
          />
        </div>
      </div>
      <div class="modal-item">
        <label class="modal-label_left">Ссылка на мессенджер</label>
        <input
          type="text"
          placeholder="Введите адрес ссылки"
          class="modal-input modal-input_label modal-input_thin"
          v-model="currentExecutor.messenger"
        />
      </div>
    </div>
    <div class="d-flex justify-content-end mt-20">
      <base-button
        text="Отмена"
        color="gray"
        class="mr-3"
        @click.prevent="onModalClose"
      />

      <base-button
        :text="executor ? 'Сохранить' : 'Создать'"
        color="green"
        @click.prevent="onFormSubmit"
        :disabled="isFormDataInvalid"
      />
    </div>
  </base-popup>
  <the-confirm
    :is-visible="isDeleteConfirmVisible"
    @close="onToggleDeleteConfirm"
    @confirm="isDeleteConfirmed"
  />
</template>
<script setup>
import { defineProps, ref, defineEmits, computed, watch } from 'vue';
import { IMaskDirective } from 'vue-imask';
import { BasePopup, BaseButton } from '@/components/atoms';
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import { useStore } from 'vuex';
import TheConfirm from '@/components/TheConfirm.vue';
import { getAllComplexes, getAllCategories } from '@/graphql/Claims.graphql';
import { getAllPosts } from '@/graphql/ExecutorPost.graphql';
import {
  createExecutor,
  updateExecutor,
  deleteExecutor,
} from '@/graphql/Executor.graphql';
import { useQuery, useMutation } from '@vue/apollo-composable';

const store = useStore();

const emit = defineEmits(['close', 'create', 'update', 'delete']);

const props = defineProps({
  isExecutorModalVisible: {
    type: Boolean,
    default: false,
  },
  executor: {},
});

watch(
  () => props.executor,
  (data) => {
    if (data) {
      // console.log('executor', data);
      currentExecutor.value = JSON.parse(JSON.stringify(data));
      complexValue.value = data.complexes.map(({ complex: { id } }) => id);
      postValue.value = data.post.id;
      categoriesValue.value = data.categories.map(({ category: { id } }) => id);
    } else {
      currentExecutor.value = ref({ ...emptyExecutor }).value;
    }
  }
);

// Select Options

const postOptions = ref([]);
const complexOptions = ref([]);
const categoriesOptions = ref([]);

const emptyExecutor = {
  id: null,
  name: '',
  post: {
    title: null,
  },
  complexes: [],
  categories: [],
  phone: '',
  email: '',
  messenger: '',
  photo: null,
};

const currentExecutor = ref({
  id: null,
  name: '',
  post: {
    title: null,
    id: null,
  },
  complexes: [],
  categories: [],
  phone: '',
  email: '',
  messenger: '',
  photo: null,
});

const { result: allComplexes } = useQuery(getAllComplexes);

watch(allComplexes, (newValue) => {
  complexOptions.value = newValue.getAllComplexes.map(({ id, name }) => {
    return {
      id,
      value: name,
    };
  });
  // console.log('complexOptions.value', complexOptions.value);
});

const complexValue = ref(null);

watch(complexValue, (newValue) => {
  currentExecutor.value.complexes = newValue;
  // console.log(currentExecutor.value);
});

const { result: allCategories } = useQuery(getAllCategories);

watch(allCategories, (newValue) => {
  categoriesOptions.value = newValue.getAllCategories.map(({ id, title }) => {
    return {
      id,
      value: title,
    };
  });
});

const categoriesValue = ref(null);

watch(categoriesValue, (newValue) => {
  // console.log('categoriesValue', categoriesValue.value);
  currentExecutor.value.categories = newValue;
  // console.log(currentExecutor.value);
});

const { result: allPosts } = useQuery(getAllPosts);

watch(allPosts, (newValue) => {
  postOptions.value = newValue.getAllPosts.map(({ id, title }) => {
    return {
      id,
      value: title,
    };
  });
});

const postValue = ref(null);

watch(postValue, (newValue) => {
  currentExecutor.value.post.id = newValue;
  // console.log(currentExecutor.value);
});

// Validation

const rules = computed(() => {
  return {
    name: { required },
    post: {
      id: { required },
    },
    categories: { required },
    complexes: { required },
    phone: { required, minLength: minLength(16) },
  };
});

const v$ = useVuelidate(rules, currentExecutor);

const isFormDataInvalid = computed(() => {
  return v$.value.$invalid;
});

//Close Modal
const onModalClose = (isSubmit) => {
  isSubmit !== true ? (isSubmit = false) : (isSubmit = true);
  let isClosed = true;
  if (!isSubmit) {
    isClosed = confirm(
      'Вы действительно хотите закрыть окно? Внесенные изменения не сохранятся'
    );
  }
  if (isClosed) {
    currentExecutor.value = ref({ ...emptyExecutor }).value;
    complexValue.value = null;
    categoriesValue.value = null;
    postValue.value = null;
    executorPhoto.value = null;
    emit('close');
  }
};
//////////////

const executorPhoto = ref(null);

const onPhotoChoose = (e) => {
  currentExecutor.value.photo = e.target.files[0];
  executorPhoto.value = URL.createObjectURL(e.target.files[0]);
  // console.log(e.target.files[0]);
};

const { mutate: createExecutorMutation } = useMutation(createExecutor);
const { mutate: updateExecutorMutation } = useMutation(updateExecutor);
const { mutate: deleteExecutorMutation } = useMutation(deleteExecutor);

const isExecutorCanBeSaved = ref(null);

const onExecutorCreate = async () => {
  const {
    post: { id: postId },
    name,
    phone,
    email,
    categories,
    complexes,
    messenger,
  } = currentExecutor.value;
  // console.log('submited', currentExecutor.value);

  try {
    const { data } = await createExecutorMutation({
      data: {
        executor: {
          postId,
          name,
          phone,
          email,
          messenger,
        },
        categories,
        complexes,
      },
    });
    // console.log('created', data.createExecutor);
    emit('create', data.createExecutor);
    isExecutorCanBeSaved.value = true;
  } catch (error) {
    store.dispatch('notification/showNotification', {
      text: error,
      type: 'error',
    });
    isExecutorCanBeSaved.value = false;
  }
};

const onExecutorUpdate = async () => {
  const {
    id,
    post: { id: postId },
    name,
    phone,
    email,
    categories,
    complexes,
    messenger,
  } = currentExecutor.value;

  try {
    const { data } = await updateExecutorMutation({
      data: {
        id,
        executor: {
          postId,
          name,
          phone,
          email,
          messenger,
        },
        categories,
        complexes,
      },
    });
    // console.log('updated', data.updateExecutor);
    emit('update', data.updateExecutor);
    isExecutorCanBeSaved.value = true;
  } catch (error) {
    store.dispatch('notification/showNotification', {
      text: error,
      type: 'error',
    });
    isExecutorCanBeSaved.value = false;
  }
};

const isDeleteConfirmVisible = ref(false);
const isExecutorShouldBeDeleted = ref(false);

const onToggleDeleteConfirm = () => {
  isDeleteConfirmVisible.value = !isDeleteConfirmVisible.value;
};

const isDeleteConfirmed = () => {
  isExecutorShouldBeDeleted.value = true;
};

const onExecutorDelete = async () => {
  isDeleteConfirmVisible.value = true;
  watch(isExecutorShouldBeDeleted, async (newValue) => {
    if (newValue) {
      const { id } = currentExecutor.value;

      await deleteExecutorMutation({
        id,
      });
      store.dispatch('notification/showNotification', {
        text: `Исполнитель успешно удален`,
        type: 'success',
      });
      onModalClose(true);
      isExecutorShouldBeDeleted.value = false;
      emit('delete', id);
    }
  });
};

//on Form submit
const onFormSubmit = async () => {
  if (isFormDataInvalid.value) return;

  // console.log('Executor form submited', currentExecutor.value);

  if (props.executor) {
    await onExecutorUpdate();
  } else {
    await onExecutorCreate();
  }

  if (isExecutorCanBeSaved.value) {
    store.dispatch('notification/showNotification', {
      text: `Исполнитель успешно ${props.executor ? 'сохранен' : 'создан'}`,
      type: 'success',
    });
    onModalClose(true);
  }
  isExecutorCanBeSaved.value = null;
};

const vMask = IMaskDirective;

const phoneMask = ref({
  mask: '+{7}(000)000-00-00',
});
</script>

<style scoped lang="scss">
@import '~@/assets/styles/_colors';
.modal-input {
  border: 1px solid map-get($lightgray-stroke, 'normal');
  border-radius: 10px;
  padding: 6px 10px;
  width: 100%;
  font-family: 'Golos';
  font-size: 16px;
  font-weight: 400;
}
.modal-input::placeholder {
  font-weight: 400;
  font-size: 16px;
  color: map-get($lightgray-text, 'normal');
}
.modal-item:not(:last-child) {
  margin-right: 20px;
}
.modal-item {
  width: 260px;
  position: relative;
}
.modal-label_left {
  color: map-get($lightgray-text, 'normal');
}
.modal-input_label {
  margin: 10px 0;
}
.modal-input_thin::placeholder {
  opacity: 0.5;
}
.executor-modal-delete {
  color: map-get($red, 'normal');
  cursor: pointer;
}
.photo-input {
  width: 95px;
  height: 95px;
  border-radius: 50%;
  background-color: #f6f6f6;
  border: 1px solid map-get($lightgray-stroke, 'normal');
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.photo-input__item {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.photo-input__item::file-selector-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 50%;
}
.photo-input__icon {
  color: map-get($lightgray-stroke, 'normal');
}
.photo-input__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
