<template>
<div class="input">
  <div class="input__header">
    <label v-if="label" class="input__label">{{ label }}</label>
    <label v-if="suffixLabel" class="input__label">{{ suffixLabel }}</label>
  </div>
  <input 
    :type="type"
    :placeholder="placeholder"
    :value="value"
    class="input__input"
    @input="onInput"
  />
</div>
</template>
<script setup>
defineProps({
  type: String,
  placeholder: String,
  value: String,
  label: String,
  suffixLabel: String,
});

const emit = defineEmits(['input']);

const onInput = (event) => emit('input', event);
</script>
<style scoped lang="scss">
.input {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__label {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 400;
    color: #535352;
  }

  &__input {
    padding-left: 10px;
    height: 50px;
    border: 1px solid #B9B9B9;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 400;
  }
}
</style>