<template>
  <div class="payment-table">
    <div v-if="!paymentHistory.length" class="cards-client__empty">
      <img :src="emptyImg" class="cards-client__empty-img" alt="empty-claims" />
      <div class="cards-client__empty-text">
        На данный момент у вас еще нет истории начислений
      </div>
    </div>
    <base-table v-else>
      <template v-slot:head>
        <base-table-row class="payment-table__header">
          <base-table-head-cell
            class="payment-table__header-cell--first"
            width="50%"
            >Дата</base-table-head-cell
          >
          <base-table-head-cell
            class="payment-table__header-cell--last"
            width="50%"
            >Оплата</base-table-head-cell
          >
        </base-table-row>
      </template>

      <template v-slot:body>
        <base-table-row
          v-for="item in paymentHistory"
          :key="item.id"
          @click="() => {}"
          class="payment-table__row"
        >
          <base-table-cell class="payment-table__body-cell--first" width="50%">
            {{ new Date(item.createdAt).toLocaleDateString('ru-RU') }}
          </base-table-cell>
          <base-table-cell class="payment-table__body-cell--last" width="50%">
            {{ item.amount }}₽
          </base-table-cell>
        </base-table-row>
      </template>
    </base-table>
  </div>
</template>
<script setup>
import { onMounted } from 'vue';
import {
  BaseTable,
  BaseTableCell,
  BaseTableHeadCell,
  BaseTableRow,
} from '@/components/BaseTable';
import { useStore } from 'vuex';
import { ref } from 'vue';

const store = useStore();
const sessionToken = store.getters['app/getSessionToken'];

const paymentHistory = ref([]);

const getPaymentHistory = async () => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await fetch(
      `https://trouter.domion.tech/genbank/history`,
      {
        // const response = await fetch(`${config.VUE_APP_API_TROUTER}/genbank/history`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionToken}`,
        },
        credentials: 'include',
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      const data = await response.json();
      paymentHistory.value = data;
      // console.log(data, 'data');
      return data;
    }
  } catch (error) {
    // console.error('There was a problem with the fetch operation:', error);
    throw error;
  }
};

onMounted(() => {
  getPaymentHistory();
});
</script>
<style scoped lang="scss">
.payment-table {
  margin-top: 20px;
  &__header {
    &-cell {
      &--first {
        border-top-left-radius: 13px !important;
        border-bottom-left-radius: 13px !important;
        background: #f9f9f9;
      }

      &--last {
        border-top-right-radius: 13px !important;
        border-bottom-right-radius: 13px !important;
        background: #f9f9f9;
      }
    }
  }

  &__body {
    &-cell {
      &--first {
        border-top-left-radius: 13px !important;
        border-bottom-left-radius: 13px !important;
        background: var(--white);
      }

      &--last {
        border-top-right-radius: 13px !important;
        border-bottom-right-radius: 13px !important;
        background: var(--white);
      }
    }
  }
}
</style>
