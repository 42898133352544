<template>
  <div class="d-flex">
    <h1 class="header header_black">Клиенты с FaceId</h1>
  </div>

  <div class="filter">
    <div class="filter__item">
      <p class="filter__label">Статус</p>
      <v-select
        class="modal-select modal-select_filter"
        :options="filterOptions"
        v-model="filterObj.searchByApproved"
        :reduce="(value) => value.value"
        label="title"
      >
      </v-select>
    </div>
    <div class="filter__item">
      <p class="filter__label">Комплексы</p>
      <v-select
        class="modal-select modal-select_filter"
        :options="complexOptions"
        v-model="filterObj.complexes"
        :reduce="(value) => +value.id"
        placeholder="Все"
        :multiple="true"
        label="value"
      >
      </v-select>
    </div>
    <div class="filter__item">
      <p class="filter__label">Поиск</p>
      <div class="search-input-wrapper">
        <input type="text" class="search-input" v-model="filterObj.search" />
        <mdicon size="20" name="magnify" class="search-input-icon" />
      </div>
    </div>
  </div>

  <div v-if="isLoading" class="mt-20 text-center">
    <img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" />
  </div>
  <div class="clients" v-else>
    <card-client-faceId
      @show-photo-modal="openFullImageModal"
      @set-approve-status-for-faceId="setApproveStatusForFaceId"
      v-for="(client, idx) in clients"
      :client-with-faceId-data="client"
      :client-loader-data="clientLoaderData"
      :key="idx"
    />
  </div>
  <the-pagination
    :totalPages="totalPages"
    :currentPage="currentPage"
    @pageChanged="onChangePage"
    class="pagination"
  ></the-pagination>

  <client-with-face-id-modal
    :client="selectedClient"
    @set-approve-status-for-faceId="setApproveStatusForFaceId"
    :is-modal-visible="isClientPopupVisible"
    @close="setClientPopupVisibility(false)"
  />
</template>

<script>
import ClientWithFaceIdModal from '@/components/pages/adminUk/ClientsWithFaceIdPage/ClientWithFaceIdModal';
import CardClientFaceId from '@/components/pages/adminUk/ClientsWithFaceIdPage/CardClientFaceId';
import {
  getAllClientsWithFaceUrl,
  setApproveStatusForFaceUrl,
  getAllComplexes,
} from '@/graphql/Client.graphql';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { ref, watch, onMounted, reactive } from 'vue';
import { logErrorMessages } from '@vue/apollo-util';
import { useStore } from 'vuex';
import ThePagination from '@/components/ThePagination.vue';
import { useRouter, useRoute } from 'vue-router';


export default {
  name: 'clients-with-faceId-page',
  components: {
    CardClientFaceId,
    ClientWithFaceIdModal,
    ThePagination,
  },
  setup() {
    const store = useStore();
    const clients = ref([]);
    const isLoading = ref(true);
    const clientLoaderData = ref({});

    const router = useRouter();
    const route = useRoute();

    const totalPages = ref(0);
    const perPage = ref(10);

    const loaderDomion = require('@/assets/images/loader.svg');
    const loaderGreen = require('@/assets/images/loader-green.svg');
    const THEME = config.VUE_APP_THEME;
    // const themeModule = require('@/constant/main');
    // const THEME = themeModule.THEME;

    const filterObj = reactive({
      searchByApproved: !route.query.approved
        ? null
        : route.query.approved === 'true' || false,
      search: route.query.search || null,
      complexes: null,
    });

    const {
      result: clientsWithFaceIdResult,
      fetchMore,
      refetch,
    } = useQuery(getAllClientsWithFaceUrl, {
      pagination: {
        limit: perPage.value,
        offset: 0,
      },
      filter: {
        approved: filterObj.searchByApproved,
        q: filterObj.search,
        complexIds: filterObj.complexes,
      },
    });

    const complexOptions = ref([]);

    const { result: allComplexes } = useQuery(getAllComplexes);

    watch(allComplexes, (value) => {
      complexOptions.value = value.getAllComplexes.map(({ id, name }) => {
        return {
          id,
          value: name,
        };
      });
    });

    const loadMore = async (page = 0) => {
      const activePage = page === 1 ? 0 : page;
      await fetchMore({
        variables: {
          pagination: {
            offset: (activePage === 0 ? 0 : activePage - 1) * perPage.value,
            limit: perPage.value,
          },
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          // console.log('fetchMoreResult', fetchMoreResult);
          clients.value = [...fetchMoreResult.getAllClientsWithFaceUrl.clients];
          // console.log('clients in update query', clients.value);
        },
      });
    };

    const getTotalPages = (totalItems) => {
      totalPages.value = Math.ceil(totalItems / perPage.value);
    };

    onMounted(async () => {
      await loadMore(+route.query.page || 0);
    });

    watch(clientsWithFaceIdResult, (value) => {
      // console.log('clients', clients.value);
      getTotalPages(value?.getAllClientsWithFaceUrl.count);
      isLoading.value = false;
    });

    const currentPage = ref(+route.query.page || 1);

    const onChangePage = async (page) => {
      // console.log('page changed');

      router.replace({
        route,
        query: {
          page,
          approved: filterObj.searchByApproved,
          search: filterObj.search,
        },
      });

      currentPage.value = page;
      isLoading.value = true;
      await loadMore(page);
      isLoading.value = false;
    };

    const selectedClient = ref(null);
    const isClientPopupVisible = ref(false);

    const { mutate: setApproveStatusForFaceUrlMutation, onError } = useMutation(
      setApproveStatusForFaceUrl
    );

    onError((error) => {
      store.dispatch('notification/showNotification', {
        text: 'Ошибка...',
        type: 'error',
      });
      logErrorMessages(error);
    });

    const setClientPopupVisibility = (status) => {
      isClientPopupVisible.value = status;
    };

    const openFullImageModal = (client) => {
      selectedClient.value = client;
      setClientPopupVisibility(true);
    };

    const setApproveStatusForFaceId = async (clientWithFaceIdData) => {
      clientLoaderData.value = {
        id: clientWithFaceIdData.id,
        isLoading: true,
      };

      await setApproveStatusForFaceUrlMutation({
        approvalStatus: clientWithFaceIdData.isFaceUploaded,
        clientId: clientWithFaceIdData.id,
      });

      clients.value = clients.value.map((item) => {
        if (item.id === clientWithFaceIdData.id) {
          return {
            ...clientWithFaceIdData,
          };
        }
        return item;
      });

      clientLoaderData.value = {
        id: clientWithFaceIdData.id,
        isLoading: false,
      };
    };

    const filterOptions = ref([
      {
        title: 'Все',
        value: null,
      },
      {
        title: 'Подтвержден',
        value: true,
      },
      {
        title: 'Не подтвержден',
        value: false,
      },
    ]);

    watch(filterObj, async (newValue) => {
      const { data: getAllClientsWithFaceUrl } = await refetch({
        filter: {
          approved: newValue.searchByApproved,
          q: newValue.search,
          complexIds: newValue.complexes,
        },
      });
      // console.log('result', getAllClientsWithFaceUrl);

      clients.value = getAllClientsWithFaceUrl.clients;

      getTotalPages(getAllClientsWithFaceUrl.count);
      onChangePage(1);
      setTimeout(() => {
        if (!clients.value?.length) {
          store.dispatch('notification/showNotification', {
            text: 'Совпадений нет!',
            type: 'error',
          });
        }
      }, 1000);
      // console.log('filteredClients', clients.value);
    });

    return {
      openFullImageModal,
      setClientPopupVisibility,
      setApproveStatusForFaceId,
      clientLoaderData,
      isLoading,
      clients,
      filterOptions,
      clientsWithFaceIdResult,
      selectedClient,
      isClientPopupVisible,
      onChangePage,
      currentPage,
      perPage,
      filterObj,
      loadMore,
      totalPages,
      complexOptions,
      loaderDomion,
      loaderGreen,
      THEME
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_colors';

.modal-select_filter {
  width: 250px;
}

.pagination {
  margin-top: 25px;
}

.filter {
  display: flex;
  margin-top: 20px;

  &__label {
    color: map-get($black-text, 'normal');
    font-size: 16px;
    margin-bottom: 5px;
    line-height: 22px;
  }

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__item:not(:last-child) {
    margin-right: 17px;
  }

  &__input {
    margin-top: 7px;
    background: var(--grey-opacity-light);
    border-radius: 2px;
    font-size: 12px;
    line-height: 22px;
    color: map-get($black-text, 'normal');
    border: none;
    height: 24px;
    padding-left: 10px;
  }
}

.clients {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
  gap: 24px 16px;
  margin-top: 30px;
  border-top: 1px solid var(--grey-opacity);
  padding-top: 30px;
}

.search-input-wrapper {
  position: relative;
  width: 300px;
  height: 34px;
  margin-right: 20px;
}

.search-input {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: var(--light-secondary-bg);
  border: none;
  padding: 0 20px 0 40px;
  font-family: 'Golos';
  font-size: 16px;
}
.search-input-icon {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 10px;
  color: var(--grey-dark-color);
}
</style>
