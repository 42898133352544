<template>
<div class="main-banner">
  <div class="main-banner__info">
    <div class="main-banner__info-title">Быстрая оплата коммунальных услуг</div>
    <ul class="main-banner__info-list">
      <li class="main-banner__info-list-item">Все платежи у вас под контролем</li>
      <li class="main-banner__info-list-item">Все в одном приложении</li>
    </ul>
    <div class="main-banner__info-input">
      <div class="main-banner__info-input-icon">
        <mdicon name="magnify" size="20" class="main-banner__info-input-icon-item" />
      </div>
      <input @focus="onFocus" class="main-banner__info-input-input" placeholder="Поиск" />
    </div>
    <div class="main-banner__info-cards">
      <div v-for="card in cards" :key="card.id" @click="openModalAgent(card)" class="main-banner__info-cards-item">
        <img :src="require(`@/assets/images/${card.img}.png`)" class="main-banner__info-cards-item-img" :alt="card.name"/>
        <div class="main-banner__info-cards-item-name">{{ card.name }}</div>
        <mdicon name="chevron-right" size="20" class="main-banner__info-cards-item-icon" />
      </div>
    </div>
  </div>
  <div class="main-banner__img">
    <img src="@/assets/images/banner-laptop.png" alt="banner-img"/>
  </div> 
</div>
</template>
<script setup>
import { ref } from 'vue';

const emit = defineEmits(['focus', 'openAgent'])

const cards = ref([
  {
    id: '1',
    agentGroupName: 'Холодная вода',
    agentId: 185,
    name: 'КрымЭнерго',
    img: 'krym-energo' 
  },
  {
    id: '2',
    agentGroupName: 'Холодная вода',
    agentId: 185,
    name: 'Оплата ЖКУ',
    img: 'gky' 
  },
  {
    id: '3',
    agentGroupName: 'Холодная вода',
    agentId: 185,
    name: 'Вода Крым',
    img: 'water-crimea' 
  },
]);

const openModalAgent = ({ agentGroupName,  agentId }) => {
  emit('openAgent', { agentGroupName,  agentId })
}

const onFocus = () => {
  emit('focus');
}
</script>
<style scoped lang="scss">
.main-banner {
  height: 300px;
  padding: 28px 24px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  background-color: var(--primary-color);
  background-image: url('../../../../assets/images/banner-dom.png');
  background-position: right center;
  background-repeat: no-repeat;
  // background-size: cover;
  background-size: 50% 100%;
  border-radius: 25px;
  box-sizing: border-box;

  &__info {
    &-title {
      margin-bottom: 15px;
      font-size: 30px;
      font-weight: 700;
      color: var(--white);
    }

    &-list {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding-left: 20px;
      list-style-type: disc;
      color: var(--white);

      &-item {
        font-size: 16px;
        font-weight: 600;
      }
    }

    &-input {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      max-width: 380px;
      height: 37px;
      background-color: #FFFFFF54;
      border-radius: 33px;
      box-sizing: border-box;

      &-icon {
        margin: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 28px;
        min-height: 28px;
        border-radius: 50%;
        background-color: var(--white);
        color: var(--primary-color);
      }

      &-input {
        margin-right: 5px;
        width: 100%;
        height: 100%;
        border: none;
        background: transparent;
        color: var(--white);
        font-weight: 600;

        &::placeholder {
          color: var(--white);
        }
      }
    }

    &-cards {
      display: flex;
      align-items: center;
      // flex-wrap: wrap;
      gap: 5px;

      &-item {
        padding: 5px;
        display: flex;
        align-items: center;
        gap: 3px;
        background: var(--white);
        color: var(--primary-color);
        border-radius: 9px;
        box-sizing: border-box;
        text-decoration: none;
        cursor: pointer;

        &-icon {
          color: var(--primary-color);
        }
      }
    }
  }

  &__img {
    img {
      // width: 100%;
    }
  }
}
</style>