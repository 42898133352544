<template>
  <h1 class="header header_black">Справочник</h1>
  <div class="d-flex justify-content-between mb-4">
    <span @click="router.back()" class="d-flex align-items-center sub-tltle">
      <mdicon size="24" class="arrow" name="chevron-left" /><span
        >Комплексы</span
      >
    </span>
  </div>
  <div>
    <div v-if="isLoading" class="loading">
      <img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" />
    </div>
    <base-list :list="complexes" v-else />
  </div>
</template>
<script setup>
import { ref, watch } from 'vue';
import { BaseList } from '@/components/atoms';
import { getAllComplexes } from '@/graphql/Claims.graphql';
import { useQuery } from '@vue/apollo-composable';
import { useRouter } from 'vue-router';
// import { THEME } from '@/constant/main'
const THEME = config.VUE_APP_THEME;
const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');
const router = useRouter();

const isLoading = ref(true);

const complexes = ref();

const { result: allComplexes } = useQuery(getAllComplexes);

watch(allComplexes, (newValue) => {
  // console.log(newValue.getAllComplexes);
  complexes.value = newValue.getAllComplexes.map(({ id, name }) => {
    return {
      id,
      title: name,
    };
  });
  isLoading.value = false;
});
</script>
<style scoped lang="scss">
@import '~@/assets/styles/_colors';
.sub-tltle {
  color: map-get($primary-text, 'normal');
  font-family: 'Golos';
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
  cursor: pointer;
}
.loading {
  text-align: center;
  margin-top: 40px;
}
</style>
