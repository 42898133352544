<template>
<div class="main-section">
  <div class="main-section__item-column">
    <div class="main-section__item-column__gradient"></div>
    <div class="main-section__item-column__logo icon-logo"> 
      <img src="@/assets/images/logo-domion.svg" alt="logo-dom"/>
    </div>
    <div class="main-bg-swiper">
    <swiper-container
      ref="firstSwiper"
      class="swiper-wrapper"
      :speed="600"
      :loop="true"
      :slides-per-view="1"
      :allow-touch-move="false"
      direction="vertical"
      effect="fade"
    >
      <swiper-slide class="swiper-slide">
        <img
          src="@/assets/images/slider-slides/1.png"
          alt="1"
          class="inner-img"
        />
      </swiper-slide>
      <swiper-slide class="swiper-slide">
        <img
          src="@/assets/images/slider-slides/2.png"
          alt="2"
          class="inner-img"
        />
      </swiper-slide>
      <swiper-slide class="swiper-slide">
        <img
          src="@/assets/images/slider-slides/3.png"
          alt="3"
          class="inner-img"
        />
      </swiper-slide>
      <swiper-slide class="swiper-slide">
        <img
          src="@/assets/images/slider-slides/4.png"
          alt="4"
          class="inner-img"
        />
      </swiper-slide>
      <swiper-slide class="swiper-slide">
        <img
          src="@/assets/images/slider-slides/5.png"
          alt="5"
          class="inner-img"
        />
      </swiper-slide>
    </swiper-container> 
    </div>
    <div class="main-text-swiper">
      <swiper-container
        ref="secondSwiper"
        @slideChange="onSlideChange"
        class="swiper-wrapper"
        slides-per-view="auto"
        direction="vertical"
        :centered-slides="true"
        :grabCursor="true"
        effect="coverflow"
        :coverflowEffect="{
          rotate: 0,
          stretch: -20,
          depth: 200,
          scale: 0.9,
          modifier: 1,
          slideShadows: false,
        }"
        :mousewheel="{
          sensitivity: 0.3,
          releaseOnEdges: true,
        }" 
        :mousewheelControl="true"
        :autoplay="{
          delay: 1600
        }"
      >
        <swiper-slide class="swiper-slide">контроль расходов ЖКУ</swiper-slide>
        <swiper-slide class="swiper-slide">просмотр камер</swiper-slide>
        <swiper-slide class="swiper-slide">управление IP-домофоном</swiper-slide>
        <swiper-slide class="swiper-slide">контроль заявок в УК</swiper-slide>
        <swiper-slide class="swiper-slide">опросы онлайн</swiper-slide>
        <!-- <swiper-slide class="swiper-slide">новости</swiper-slide> -->
      </swiper-container>
    </div>
    <div class="main-bg-swiper-pagination"></div>
  </div>
</div>
</template>
<script setup>
import { ref, onMounted } from 'vue';

const firstSwiper = ref(null);
const secondSwiper = ref(null);

onMounted(() => {
  secondSwiper.value.addEventListener('swiperslidechange', (event) => {
    // // console.log(event.target.value, 'event');
    firstSwiper.value.swiper.slideToLoop(secondSwiper.value.swiper.activeIndex);
  });
});
</script>
<style scoped>
.main-section {
  position: relative;
  width: 410px;
  height: 70vh;
}

.main-section__item-column__logo {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}

.main-section__item-column__gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    0deg,
    var(--primary-color) 0%,
    rgba(2, 103, 255, 0) 119.31%
  );
  z-index: 2;
  user-select: none;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
}

.inner-img {
  width: 100%;
  height: 100%;
  /* position: absolute;
  height: 100%; */
  object-fit: cover;
}

.swiper-slide {
  position: relative;
  width: 100%;
  text-align: center;
  opacity: 0.3;
  transition: all 0.3s ease;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 100%;
  user-select: none;
}

.drum-swiper .swiper-slide-active {
  transform: scale(2) !important;
  opacity: 1 !important;
  transition: all 0.3s ease;
}

.swiper-wrapper {
  height: 100%;
}

.main-bg-swiper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  border-radius: 25px;
  overflow: hidden;
}

.main-bg-swiper .swiper-slide {
  position: relative;
  height: 100%;
  width: 100%;
}

.main-bg-swiper .swiper-slide img {
  -webkit-user-drag: none;
  border-radius: 25px;
}

.main-text-swiper {
  position: absolute;
  height: 40%;
  width: 82%;
  bottom: 45px;
  left: 45px;
  color: #fff;
  z-index: 3;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 135.714%;
  overflow: hidden;
}

.main-text-swiper .swiper-slide {
  text-align: center;
  height: fit-content;
  opacity: 0.8 !important;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-bottom: 10px; */
}

.main-text-swiper .swiper-slide-active {
  opacity: 1 !important;
  transition: all 0.3s ease;
}

.main-bg-swiper-pagination {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: -50px !important;
  right: 30px !important;
  top: auto !important;
  left: auto !important;
  height: fit-content !important;
  width: fit-content !important;
  z-index: 3;
}

.main-bg-swiper-pagination .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: transparent;
  transition: all 0.3s ease;
}

.main-bg-swiper-pagination .swiper-pagination-bullet-active {
  border: 1px solid #fff;
  background-color: #fff;
  transition: all 0.3s ease;
}

@media screen and (max-width: 1600px) {
  .drum-swiper .swiper-slide-active {
    transform: scale(1.7) !important;
  }
  .main-bg-swiper-pagination {
    right: 20px !important;
  }
  .main-text-swiper {
    bottom: 35px;
    left: 35px;
  }
  .main-text-swiper .swiper-slide {
    font-size: 24px;
  }
}

@media screen and (max-width: 1199px) {
  .main-text-swiper .swiper-slide {
    font-size: 22px !important;
    line-height: 26px !important;
  }
}

@media screen and (max-width: 991px) {
  .main-text-swiper {
    bottom: 25px;
    left: 25px;
    font-size: 20px;
    font-weight: 600;
    width: 92%;
    height: 100%;
  }
  .main-bg-swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
  }
  .drum-swiper .swiper-slide-active {
    transform: scale(1.4) !important;
  }
  .main-text-swiper .swiper-slide {
    font-size: 32px !important;
    line-height: 40px !important;
  }
}

@media screen and (max-width: 767px) {
  .drum-swiper .swiper-slide {
    font-size: 14px;
  }
  .main-text-swiper {
    width: 90%;
  }
}

@media screen and (max-width: 557px) {
  .main-text-swiper .swiper-slide {
    font-size: 22px !important;
    line-height: 26px !important;
  }
  .main-text-swiper {
    width: 88%;
  }
}

@media screen and (max-width: 400px) {
  .main-text-swiper {
    width: 85%;
  }
}
</style>