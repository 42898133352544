const state = {
  auth: {
    token: localStorage.getItem('token') || null,
    role: localStorage.getItem('role') || null,
    user: localStorage.getItem('user') || null
  },
  session: localStorage.getItem('s') || null,
  sessionToken: localStorage.getItem('session_token') || null,
  logOutUrl: localStorage.getItem('logout-url') || null
};

const getters = {
  userRole: () => {
    return state.auth.role;
  },
  userToken: () => {
    return state.auth.token;
  },
  getUser: () => {
    return state.auth.user;
  },
  getSession: () => {
    return state.session;
  },
  getSessionToken: () => {
    return state.sessionToken;
  },
  getLogoutUrl: () => {
    return state.logOutUrl;
  },
};

const actions = {
  logout({ commit }) {
    commit('LOGOUT');
  },
  async setAuth({ commit }, data) {
    saveAuthData(commit, data);
    return data;
  },
  getUser() {
    return state.auth.user;
  },
  getSession() {
    return state.session;
  },
  getSessionToken() {
    return state.sessionToken;
  },
  setSession({ commit }, sessionData) {
    localStorage.setItem('s', JSON.stringify(sessionData));
    commit('SET_SESSION', sessionData);
  },
  setLogoutUrl({ commit }, url) {
    localStorage.setItem('logout-url', url);
    commit('SET_LOGOUT_URL', url);
  },
};

const mutations = {
  SET_AUTH(state, data) {
    state.auth = { ...state.auth, ...data };
  },
  LOGOUT(state) {
    state.auth = {
      token: null,
      role: null,
      user: null
    };
    state.session = null

    localStorage.clear();
  },
  SET_SESSION(state, sessionData) {
    state.session = sessionData;
  },
  SET_LOGOUT_URL(state, url) {
    state.logOutUrl = url;
  },
};

function saveAuthData(commit, data) {
  commit('SET_AUTH', { ...data });

  localStorage.setItem('token', data.token);
  localStorage.setItem('role', data.role);
  localStorage.setItem('user', data.user);
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
