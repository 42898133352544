<template>
  <base-popup
    size="largest"
    @close="onModalClose"
    :is-visible="isModalVisible"
    :has-close-icon="true"
    top-indent="80"
    title="Уведомления"
  >
    <div class="popup">
      <div class="popup__title-list title-list">
        <div
          class="title-list__item"
          :class="{ active: activeItem === 'ALL' }"
          @click="activeItem = 'ALL'"
        >
          Все {{ allCount }}
          <mdicon name="chevron-right" size="30" class="title-list__icon" />
        </div>
        <div
          class="title-list__item"
          :class="{ active: activeItem === 'CLAIMS' }"
          @click="activeItem = 'CLAIMS'"
        >
          Заявки
          {{ claimsCount }}
          <mdicon name="chevron-right" size="30" class="title-list__icon" />
        </div>
        <div
          class="title-list__item"
          :class="{ active: activeItem === 'VOTES' }"
          @click="activeItem = 'VOTES'"
        >
          Опросы {{ votesCount }}
          <mdicon name="chevron-right" size="30" class="title-list__icon" />
        </div>
        <div
          class="title-list__item"
          :class="{ active: activeItem === 'FACE_ID' }"
          @click="activeItem = 'FACE_ID'"
        >
          FaceId {{ faceIdCount }}
          <mdicon name="chevron-right" size="30" class="title-list__icon" />
        </div>
      </div>
      <div v-if="isLoading" class="mt-20 text-center loader">
        <img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" />
      </div>
      <div ref="list" class="notifications-list list" v-else>
        <!-- <div class="list__group">
          <div
            class="list__item list__item-group"
            ref="accordions"
            @click="toggleAccordion(0)"
          >
            <div class="d-flex">
              <div class="group__images">
                <div class="list__img">
                  <img src="@/assets/images/logo.png" alt="logo" />
                </div>
                <div class="list__img">
                  <img src="@/assets/images/logo.png" alt="logo" />
                </div>
                <div class="list__img">
                  <img src="@/assets/images/logo.png" alt="logo" />
                </div>
                <div class="list__img">
                  <img src="@/assets/images/logo.png" alt="logo" />
                </div>
              </div>
              <div class="list__text">
                <p class="list__title">4 пользователя добавили фото</p>
              </div>
            </div>
            <p class="list__item-date">3 м назад</p>
          </div>
          <div class="list__group-item" data-id="0">
            <div class="list__item">
              <div class="list__mark"></div>
              <div class="d-flex">
                <div class="list__img">
                  <img src="@/assets/images/logo.png" alt="logo" />
                </div>
                <div class="list__text">
                  <p class="list__title">
                    Пользователь
                    <span class="list__title_bold">Ольга Артюхова</span>
                    добавила комментарий к заявке11
                  </p>
                </div>
              </div>
              <p class="list__item-date">3 м назад</p>
            </div>
            <div class="list__item">
              <div class="list__mark"></div>
              <div class="d-flex">
                <div class="list__img">
                  <img src="@/assets/images/logo.png" alt="logo" />
                </div>
                <div class="list__text">
                  <p class="list__title">
                    Пользователь
                    <span class="list__title_bold">Ольга Артюхова</span>
                    добавила комментарий к заявке
                  </p>
                </div>
              </div>
              <p class="list__item-date">3 м назад</p>
            </div>
          </div>
        </div> -->

        <div
          class="list__item"
          v-for="item in events"
          :key="item.id"
          :class="{ disabled: item.request?.isDeleted }"
          @click="pushTo(item)"
        >
          <div v-if="item.viewed" class="list__mark"></div>
          <div class="d-flex">
            <div class="list__img">
              <img
                v-if="item.user?.client?.faceUrl"
                :src="item.user?.client?.faceUrl"
                alt="logo"
              />
              <img v-else src="@/assets/images/logo-domion.png" alt="logo" />
            </div>
            <div class="list__text">
              <p class="list__title" v-if="item.type === 'VOTE_END'">
                Опрос
                <span class="list__title_bold">{{ item.vote.title }}</span>
                завершен
              </p>
              <p class="list__title" v-else>
                Пользователь
                <span class="list__title_bold"
                  >{{ item.user.firstName }} {{ item.user.lastName }}</span
                >
                {{ getEventType(item.type) }}
                <span v-if="item?.request?.id">№{{ item.request.id }}</span>
                <span
                  v-if="
                    item?.request?.isDeleted && item.type !== 'REQUEST_DELETE'
                  "
                  class="red"
                >
                  - удалена</span
                >
              </p>
            </div>
          </div>
          <p class="list__item-date">{{ getTime(item.createdAt) }}</p>
        </div>
      </div>
    </div>
  </base-popup>
</template>
<script setup>
import { defineProps, defineEmits, ref, watch, onMounted, computed } from 'vue';
import { BasePopup } from '@/components/atoms';
import { getAllEvents } from '@/graphql/Events.graphql';
import { adminInfo } from '@/graphql/AdminInfo.graphql';
import { useQuery } from '@vue/apollo-composable';
import moment from 'moment';
import { useRouter, useRoute } from 'vue-router';
import { useInfiniteScroll } from '@vueuse/core';
// import { THEME } from '@/constant/main'

const THEME = config.VUE_APP_THEME;
const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');
const router = useRouter();
const route = useRoute();

defineProps({
  isModalVisible: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);

const isLoading = ref(true);

const onModalClose = async () => {
  emit('close');
};

const events = ref([]);

const activeItem = ref('ALL');

const adminRole = ref(null);

let { result: getAdminInfo } = useQuery(adminInfo);

watch(getAdminInfo, (newValue) => {
  switch (newValue.adminInfo.employee.role) {
    case 'ADMINUK':
      adminRole.value = 'admin-uk';
      break;
    case 'ADMIN':
      adminRole.value = 'admin';
      break;
    case 'MANAGER':
      adminRole.value = 'manager';
      break;
  }
});

const list = ref(null);

const paginationOffset = ref(0);

const paginationLimit = ref(10);

let { result: allEvents, fetchMore } = useQuery(getAllEvents, {
  pagination: {
    offset: paginationOffset.value,
    limit: paginationLimit.value,
  },
  filter: {
    type: null,
  },
});

watch(allEvents, () => {
  isLoading.value = false;
});

const refetchEventList = async (offset) => {
  fetchMore({
    variables: {
      pagination: {
        offset,
        limit: paginationLimit.value,
      },
      filter: {
        type: filterType.value,
      },
    },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      events.value = [...events.value, ...fetchMoreResult.getAllEvents.events];
    },
  });
};

useInfiniteScroll(
  list,
  () => {
    refetchEventList((paginationOffset.value += 10));
  },
  { interval: 1000 }
);

// const onScroll = () => {
//   // console.log(111);
//   if (
//     list.value.scrollHeight ===
//       list.value.scrollTop + list.value.clientHeight &&
//     list.value.clientHeight < window.innerHeight
//   ) {
//     refetchEventList((paginationOffset.value += 10));
//   }
// };

// watch(list, (newValue) => {
//   if (newValue) {
//     newValue.addEventListener('scroll', onScroll);
//   }
// });

const filterType = ref(null);

const setActiveFilter = (item = activeItem.value) => {
  switch (item) {
    case 'ALL':
      filterType.value = null;
      break;
    case 'CLAIMS':
      filterType.value = [
        'REQUEST_CREATE',
        'REQUEST_UPDATE',
        'REQUEST_COMMENT',
        'REQUEST_DELETE',
      ];
      break;
    case 'VOTES':
      filterType.value = ['VOTE_END'];
      break;
    case 'FACE_ID':
      filterType.value = ['FACEID'];
  }
};

watch(activeItem, async (newValue) => {
  // list.value?.removeEventListener('scroll', onScroll);
  // setTimeout(() => {
  //   list.value?.addEventListener('scroll', onScroll);
  // }, 200);
  setActiveFilter(newValue);
  paginationOffset.value = 0;
  events.value = [];
  isLoading.value = true;
  refetchEventList(paginationOffset.value);
  isLoading.value = false;
});

const getEventType = (type) => {
  switch (type) {
    case 'FACEID':
      return 'добавил фото в FaceId';
    case 'REQUEST_CREATE':
      return 'создал новую заявку';
    case 'REQUEST_UPDATE':
      return 'обновил данные заявки';
    case 'REQUEST_COMMENT':
      return 'добавил новый комментарий к заявке';
    case 'REQUEST_DELETE':
      return 'удалил заявку';
  }
};

onMounted(() => {
  refetchEventList(paginationOffset.value);

  moment.updateLocale('ru', {
    relativeTime: {
      future: 'в %s',
      past: '%s назад',
      s: 'несколько секунд',
      ss: '%d секунд(-ы)',
      m: 'минута',
      mm: '%d минут(-ы)',
      h: 'час',
      hh: '%d часа(-ов)',
      d: 'день',
      dd: '%d дня(-ей)',
      w: 'неделя',
      ww: '%d недель(-и)',
      M: 'месяц',
      MM: '%d месяца(-ев)',
      y: 'год',
      yy: '%d лет',
    },
  });
});

const getTime = (time) => {
  time = new Date(time);
  return moment(time).fromNow();
};

const checkPath = (pageName) => {
  const pathArr = route.path.split('/');
  let path = pathArr[pathArr.length - 1];
  if (path === pageName) {
    watch(route, () => {
      router.go(0);
    });
  }
};

const pushTo = (item) => {
  onModalClose();
  switch (item.type) {
    case 'FACEID':
      router.push(
        `/app/${adminRole.value}/clients-with-faceId?search=${item.user.lastName}`
      );
      checkPath('clients-with-faceId');
      break;
    case 'REQUEST_CREATE':
    case 'REQUEST_UPDATE':
    case 'REQUEST_COMMENT':
    case 'REQUEST_DELETE':
      router.push(`/app/${adminRole.value}/claims?claim=${item.requestId}`);
      checkPath('claims');
      break;
    case 'VOTE_END':
      router.push(
        `/app/${adminRole.value}/votes?type=DONE&vote=${item.voteId}`
      );
      checkPath('votes');
      break;
  }
};

// Все закоментированное для гриппировки событий

// const content = ref(null);

// onMounted(() => {
//   content.value = document.getElementsByClassName('list__group-item');
//   // console.log(content.value);
// });

// const toggleAccordion = (id) => {
//   for (let i = 0; i < content.value.length; i++) {
//     const item = content.value[i];
//     if (+item.dataset.id === id) {
//       if (item.style.maxHeight) {
//         item.style.maxHeight = null;
//       } else {
//         item.style.maxHeight = item.scrollHeight + 'px';
//       }
//     }
//   }
// };

const allCount = computed(() => {
  return allEvents.value?.getAllEvents.all;
});

const claimsCount = computed(() => {
  const result =
    allEvents.value?.getAllEvents.requestCreate +
    allEvents.value?.getAllEvents.requestUpdate +
    allEvents.value?.getAllEvents.requestComment +
    allEvents.value?.getAllEvents.requestDelete;
  return isNaN(result) ? '' : result;
});

const votesCount = computed(() => {
  return allEvents.value?.getAllEvents.voteEnd;
});
const faceIdCount = computed(() => {
  return allEvents.value?.getAllEvents.faceid;
});
</script>
<style scoped>
.popup {
  border-top: 1px solid var(--grey-secondary-color) eee;
  padding-top: 20px;
}
.popup__title-list {
  display: flex;
  align-items: center;
}
.title-list__item {
  color: var(--grey-text-color);
  align-items: center;
  display: flex;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}
.title-list__item:not(:last-child) {
  margin-right: 30px;
}
.title-list__item.active {
  color: var(--primary-color);
}
.title-list__icon {
  transition: all 0.3s ease 0s;
}
.title-list__item.active .title-list__icon {
  transform: rotate(90deg);
}
.notifications-list,
.loader {
  margin-top: 40px;
  max-height: 500px;
  min-height: 500px;
  overflow-y: auto;
}
.list__item {
  background: var(--white);
  border-radius: 17px;
  padding: 15px 57px 15px 23px;
  margin: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid var(--grey-secondary-color);
  box-sizing: border-box;
}

.list__item.disabled {
  pointer-events: none;
}
.list__item-group {
  box-shadow: 0px 4px 4px var(--black-notification-shadow);
  margin: 0;
}
.list__mark {
  position: absolute;
  top: 10px;
  left: 5px;
  width: 9px;
  height: 9px;
  background-color: var(--primary-color);
  border-radius: 50%;
}
.list__item:not(:last-child) {
  margin-bottom: 10px;
}
.list__img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;
}
.list__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.list__text {
  margin: 10px 0px 0 17px;
  max-width: 640px;
  line-height: 1.25;
}
.list__title_bold {
  font-weight: 600;
}
.list__item-date {
  color: #898989;
  font-size: 12px;
  white-space: nowrap;
}
.group {
  padding: 15px 57px 15px 23px;
}
.group__images {
  display: flex;
}
.group__images .list__img:not(:first-child) {
  margin-left: -20px;
}
.group__images .list__img {
  border: 2px solid var(--white);
  border-radius: 50%;
  background-color: var(--white);
}
.list__group .list__group-item {
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease-out;
}
.list__item-group {
  transition: all 0.4s ease 0s;
}
.red {
  font-size: 14px;
  color: var(--button-error-color);
}
</style>
