<template>
  <div>
    <div class="new__header">
      <span>Что нового?</span>
    </div>
    <div class="new__body">
      <div class="new__list">
        <div class="new__item">
          <div class="new__item-title">Обновление от 19.10.2023</div>
          <div class="new__item-text">
            <p v-if="role !== 'manager'">
              &#x2022; Добавлен менеджмент номеров авто в
              <router-link class="link" :to="`/app/${role}/directory/parking`">паркинге</router-link>;
            </p>
            <p>
              &#x2022; Добавлен новый ЖК «Бавария» и дом в ЖК «Клевер» Бородина
              59г;
            </p>
            <p>&#x2022; Исправлена ошибка с дублированием лицевых счетов;</p>
            <p>
              &#x2022; Произведена оптимизация запроса на получение списка
              пользователей с Face ID с целью ускорения загрузки
              <router-link class="link" :to="`/app/${role}/clients-with-faceId`">страницы</router-link>;
            </p>
            <p>
              &#x2022; Исправлена проблема, при которой при публикации новостей
              пуш-уведомления отправлялись всем пользователям, независимо от
              выбранного жилого комплекса;
            </p>
            <p>
              &#x2022; Добавлен индикатор загрузки при отправке сообщения в
              <router-link class="link" :to="`/app/${role}/claims`">заявках</router-link>
              для предотвращения дублирования отправленных сообщений;
            </p>
          </div>
        </div>
        <div class="new__item">
          <div class="new__item-title">Обновление от 04.08.2023</div>
          <div class="new__item-text">
            <p>
              &#x2022; Добавленен фильтр «Комплекс» на странице
              <router-link class="link" :to="`/app/${role}/clients`">клиенты Гринвуд</router-link>;
            </p>
            <p>
              &#x2022; На странице
              <router-link class="link" :to="`/app/${role}/news`">новостей</router-link>
              реализована возможность просмотра конкретной новости;
            </p>
            <p>
              &#x2022; Теперь при закрытии или отклонении заявки менеджеру
              необходимо оставить комментарий;
            </p>
          </div>
        </div>
        <div class="new__item">
          <div class="new__item-title">Обновление от 18.07.2023</div>
          <div class="new__item-text">
            <p>
              &#x2022; Добавлена новая функциональность - уведомления для
              администратора;
            </p>
            <p>
              &#x2022; Реализована возможность массовой рассылки
              <router-link class="link" :to="`/app/${role}/notifications`">уведомлений</router-link>;
            </p>
            <p>&#x2022; Подключена база данных жителей ЖК Клевер;</p>
            <p>
              &#x2022; При публикации
              <router-link class="link" :to="`/app/${role}/news`">новостей</router-link>
              добавлена возможность выбора ЖК;
            </p>
          </div>
        </div>
        <div class="new__item">
          <div class="new__item-title">Обновление от 12.05.2023</div>
          <div class="new__item-text">
            <p>
              &#x2022; Добавлена фильтрация
              <router-link class="link" :to="`/app/${role}/clients`">клиентов Гринвуд</router-link>
              по статусу задолженности;
            </p>
            <p>
              &#x2022; Обновлен интерфейс
              <router-link class="link" :to="`/app/${role}/claims`">заявок</router-link>, добавлена возможность
              комментирования;
            </p>
          </div>
        </div>
        <div class="new__item">
          <div class="new__item-title">Обновление от 22.03.2023</div>
          <div class="new__item-text">
            <p>
              &#x2022; Теперь все поля, обязательные для заполнения, помечены
              звёздочкой*;
            </p>
          </div>
        </div>
        <div class="new__item">
          <div class="new__item-title">Обновление от 14.03.2023</div>
          <div class="new__item-text">
            <p>
              &#x2022; Добавлена возможность создания
              <router-link class="link" :to="`/app/${role}/votes`">опросов</router-link>;
            </p>
          </div>
        </div>
        <div class="new__item">
          <div class="new__item-title">Обновление от 06.02.2023</div>
          <div class="new__item-text">
            <p>
              &#x2022; Доступно редактирование контактных данных клиентов
              Гринвуд. Для ознакомления перейдите по
              <router-link class="link" :to="`/app/${role}/clients`">ссылке</router-link>;
            </p>
            <!-- <p v-if="role !== 'manager'">
              &#x2022; Добавлена возможность подключения смс информирования для
              менеджеров. Данная функциональность предполагает отправку
              уведомлений о новых заявках. Чтобы подключить смс информирование
              перейдите по
              <router-link class="link" :to="`/app/${role}/managers`"
                >ссылке</router-link
              >;
            </p> -->
          </div>
        </div>
        <div class="new__item">
          <div class="new__item-title">Обновление от 31.01.2023</div>
          <div class="new__item-text">
            <p>
              &#x2022; Появилась возможность создания заявок жителями ЖК. Для
              управления заявками перейдите по
              <router-link class="link" :to="`/app/${role}/claims`">ссылке</router-link>;
            </p>
            <p>
              &#x2022; Добавленна возможность отправлять уведомления отдельным
              жителям квартиры;
            </p>
            <p>&#x2022; Добавленно отображение истории звонков;</p>
            <p>
              &#x2022; Добавлен справочник с возможностью создавать,
              редактировать и удалять исполнителей, категории, подкатегории и
              должности;
            </p>
          </div>
        </div>
      </div>
      <div class="new__splash">
        <p class="splash__title">Что поменялось?</p>
        <p class="splash__sub-title">Обновлено 19.10.2023</p>
        <div class="splash__img">
          <img :src="banner" alt="whats-new" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps } from 'vue';
import domionWhatsNew from '@/assets/images/whats-new-domion.png';
import greenWhatsNew from '@/assets/images/whats-new.png';

const THEME = config.VUE_APP_THEME;
const banner = THEME === 'domion' ? domionWhatsNew : greenWhatsNew;

defineProps({
  role: {
    type: String,
    required: true,
  },
});
</script>
<style scoped>
.new__header {
  background: #f9f9f9;
  box-shadow: 0px 4px 4px var(--black-notification-shadow);
  border-radius: 17px;
  width: 100%;
  padding: 24px 30px;
}

.new__header span {
  color: var(--black);
  font-weight: 600;
  font-size: 24px;
  line-height: 1.25;
}

.new__body {
  margin-top: 40px;
  display: flex;
}

.new__list {
  max-width: 600px;
  margin-right: 25px;
}

.new__item {
  padding: 20px 25px 30px 25px;
  box-shadow: 0px 4px 4px var(--black-notification-shadow);
  border-radius: 17px;
}

.new__item:not(:last-child) {
  margin-bottom: 20px;
}

.new__item-title {
  color: var(--primary-color);
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
  margin-bottom: 15px;
}

.new__item-text {
  color: #7f7d7d;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.25;
}

.new__item-text p:not(:last-child) {
  margin-bottom: 10px;
}

.new__splash {
  box-shadow: 0px 4px 4px var(--black-notification-shadow);
  border-radius: 17px;
  padding: 0 17px 17px 17px;
  height: fit-content;
}

.splash__img {
  margin-top: 20px;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.splash__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 17px;
  pointer-events: none;
}

.splash__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.25;
  color: var(--dark);
}

.splash__sub-title {
  color: #7f7d7d;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
}

.link {
  color: var(--primary-color);
  text-decoration: underline;
  font-weight: 600;
}

.link:hover {
  color: var(--primary-color);
  text-decoration: underline;
  font-weight: 600;
}
</style>
