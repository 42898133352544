<template>
  <div class="setup">
    <div>
      <h1 class="setup__title">
        Добро пожаловать<br />в личный кабинет домион!
      </h1>
      <p class="setup__subtitle">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis
      </p>
      <base-button
        text="Добавить квартиру +"
        color="green"
        size="biggest"
        @click="showModal"
      />
    </div>
    <div class="setup__palette">
      <div class="setup__palette-card">
        <router-link
          to="/app/setup-client/payments"
          class="setup__palette-card-link"
        />
        <div class="setup__palette-card-title">Оплаты</div>
        <div class="setup__palette-card-icon">
          <mdicon
            name="arrow-right"
            size="24"
            class="setup__palette-card-icon-item"
          />
        </div>
      </div>
      <SetupSwiper />
    </div>
  </div>
  <SetupAddRoomModal
    :isModalVisible="isModalVisible"
    :tenants="tenants"
    @submit="onAddRoom"
    @close="onModalClose"
  />

  <SetupInfoModal
    :isModalVisible="isInfoModalVisible"
    title="Квартира не найдена"
    text="Пожалуйста, проверьте правильность введенных данных или обратитесь к администратору для уточнения информации"
    :icon="{
      name: 'close',
      color: '#FF0000',
      size: 35,
    }"
    textButton="Перейти"
    @close="onInfoModalClose"
  />
</template>
<script setup>
import { onMounted, ref, reactive } from 'vue';
import { BaseButton } from '@/components/atoms';
import SetupSwiper from './SetupSwiper.vue';
import SetupAddRoomModal from './SetupAddRoomModal.vue';
import SetupInfoModal from '../SetupComponents/SetupInfoModal.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { EventBus } from '@/utils/eventBus';

const router = useRouter();
const store = useStore();
const isModalVisible = ref(false);
const isInfoModalVisible = ref(false);
const tenants = ref([]);

let payload = reactive({
  tenantId: '',
  ls: '',
  flatNum: '',
  identityId: '',
});

const getIdentityId = () => {
  const storedData = store.getters['app/getSession'];

  if (!storedData) return;

  const data = JSON.parse(storedData);
  payload.identityId = data.identity.id;
};

const showModal = () => {
  isModalVisible.value = true;
};

const onAddRoom = (data) => {
  // // console.log(data, 'payload');
  payload.ls = data.ls;
  payload.flatNum = data.flatNum;
  payload.tenantId = data.tenantId;
  connectTenantToApartment();
};

const onModalClose = () => {
  isModalVisible.value = false;
};

const onInfoModalClose = () => {
  isInfoModalVisible.value = false;
};

const getApiLink = () => {
  if (localStorage.getItem('apiUrl')) {
    return localStorage.getItem('apiUrl');
  }
  const storedData = localStorage.getItem('s');

  if (!storedData) return;

  const data = JSON.parse(storedData);
  const tenants = data.identity.metadata_public.tenants;
  const activeTenant = tenants.active;
  const tenantInfo = tenants.available.find(
    (tenant) => tenant.id === activeTenant
  );

  if (!tenantInfo) return;

  return tenantInfo.apiUrl;
};

const oryLoginFunc = async (resp) => {
  let apiLink = getApiLink();
  // console.log(apiLink, 'apiLink');

  try {
    const response = await fetch(`${apiLink}/graphql`, {
      headers: {
        Authorization: `Bearer ${resp.session_token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        operationName: 'oryLogIn',
        variables: {
          data: {},
        },
        query:
          'mutation oryLogIn($data: OryLoginInput!) {\n  oryLogIn(data: $data) {\n    token\n    role\n    __typename\n  }\n}\n',
      }),
      method: 'POST',
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error(`HTTP error status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // console.error('Произошла ошибка при выполнении запроса:', error);
  }
};

const connectTenantToApartment = async () => {
  try {
    const response = await fetch(
      `https://trouter.domion.tech/tenant/connect-apartment`,
      {
        // const response = await fetch(`${config.VUE_APP_API_TROUTER}/tenant/connect-apartment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      const responseData = await response.json();
      let session = store.getters['app/getSession'];
      let sessionToken = store.getters['app/getSessionToken'];

      let newSession = { ...JSON.parse(session) };
      newSession.identity.metadata_public.tenants = responseData;

      await store.dispatch('app/setSession', newSession);
      EventBus.emit('set-graphql-link');

      const { data: sessionData } = await oryLoginFunc({
        session_token: sessionToken,
        session: newSession,
      });

      await store.dispatch('app/setAuth', {
        token: sessionData.oryLogIn.token,
        role: sessionData.oryLogIn.role,
        user: '{}',
      });

      await router.push('/app/client/main');
      window.location.reload();
    }
  } catch (error) {
    isInfoModalVisible.value = true;
    // console.error('There was a problem with the fetch operation:', error);
  }
};

const getTenantList = async () => {
  try {
    const response = await fetch(`https://trouter.domion.tech/tenant/list`, {
      // const response = await fetch(`${config.VUE_APP_API_TROUTER}/tenant/list`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      const data = await response.json();
      tenants.value = data;
    }
  } catch (error) {
    // console.error('There was a problem with the fetch operation:', error);
  }
};

onMounted(() => {
  getTenantList();
  getIdentityId();
});
</script>
<style scoped lang="scss">
.setup {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__title {
    margin-bottom: 25px;
    font-size: 54px;
    font-weight: 600;
    // line-height: 38px;
    color: var(--primary-color);
  }

  &__subtitle {
    margin-bottom: 40px;
    max-width: 510px;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    opacity: 58%;
  }

  &__palette {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    &-card {
      margin-top: auto;
      position: relative;
      padding: 30px 25px;
      width: 336px;
      height: 253px;
      border-radius: 29px;
      background-color: #c7d3e5;
      background-image: url('../../../../../src/assets/images/setup-ellipse.png'),
        url('../../../../../src/assets/images/setup-ruble.png');
      background-position: left top, right bottom;
      background-repeat: no-repeat, no-repeat;
      background-size: 200px 150px, 228px 228px;

      &-link {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      &-title {
        font-size: 27px;
        font-weight: 700;
        color: #ffffff;
      }

      &-icon {
        position: absolute;
        bottom: 11px;
        right: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #ffffff;

        &-item {
          color: var(--primary-color);
        }
      }
    }
  }
}
</style>
