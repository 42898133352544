<template>
  <div>
    <h1 class="header header_black">Справочник</h1>
    <div class="d-flex justify-content-between mb-4">
      <span @click="router.back()" class="d-flex align-items-center sub-tltle">
        <mdicon size="24" class="arrow" name="chevron-left" /><span
          >Паркинг</span
        >
      </span>
    </div>
    <ParkingFilter @filter="onParkingFilterUpdates" />
    <div v-if="isLoading" class="loading">
      <img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" />
    </div>
    <div v-else>
      <ParkingTable @click="onParkingUpdate" :parking-list="parkingList" />
      <the-pagination
        :totalPages="totalPages"
        :currentPage="currentPage"
        @pageChanged="onChangePage"
        class="pagination"
      ></the-pagination>
    </div>
    <ParkingModal
      :parking="selectedParking"
      :is-parking-modal-visible="isParkingModalVisible"
      @close="setParkingPopupVisibility(false)"
      @update="onParkingUpdated"
    />
  </div>
</template>
<script setup>
import ThePagination from '@/components/ThePagination.vue';
import { useRouter } from 'vue-router';
import ParkingTable from './ParkingTable.vue';
import { ref, watch, onMounted } from 'vue';
import ParkingModal from './ParkingModal.vue';
import ParkingFilter from './ParkingFilter.vue';
import { useQuery } from '@vue/apollo-composable';
import { getParkingPlaces } from '@/graphql/Parking.graphql';
import { useStore } from 'vuex';
// import { THEME } from '@/constant/main'
const THEME = config.VUE_APP_THEME;
const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');
const router = useRouter();

const store = useStore();

const selectedParking = ref(null);

const isLoading = ref(true);

const onParkingUpdate = (parking) => {
  selectedParking.value = parking;
  setParkingPopupVisibility(true);
};

const totalPages = ref(0);
const elementsOnPage = ref(5);
const currentPage = ref(1);

const isParkingModalVisible = ref(false);

const setParkingPopupVisibility = (status) => {
  isParkingModalVisible.value = status;
};

const {
  result: allParkingPlaces,
  fetchMore,
  refetch,
} = useQuery(getParkingPlaces, {
  pagination: {
    limit: elementsOnPage.value,
    offset: 0,
  },
  filter: {
    q: '',
    complexId: 1,
  },
});

const getTotalPages = (totalItems) => {
  totalPages.value = Math.ceil(totalItems / elementsOnPage.value);
};

const loadMore = (page = 0) => {
  const activePage = page === 1 ? 0 : page;
  fetchMore({
    variables: {
      pagination: {
        offset: (activePage === 0 ? 0 : activePage - 1) * elementsOnPage.value,
        limit: elementsOnPage.value,
      },
    },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      parkingList.value = [...fetchMoreResult.getParkingPlaces.parkingPlaces];
      getTotalPages(fetchMoreResult.getParkingPlaces.filtered);
    },
  });
};

onMounted(() => {
  loadMore();
});

const onParkingFilterUpdates = async (filter) => {
  // console.log(filter);
  const {
    data: { getParkingPlaces },
  } = await refetch({
    filter: {
      q: filter.search,
    },
  });
  parkingList.value = [...getParkingPlaces.parkingPlaces].reverse();
  getTotalPages(getParkingPlaces.filtered);
  onChangePage(1);
  if (!parkingList.value.length) {
    await store.dispatch('notification/showNotification', {
      text: 'Совпадений нет',
      type: 'error',
    });
  }
};

const parkingList = ref(null);

let i = 0;
watch(allParkingPlaces, (newValue) => {
  if (i === 0) {
    parkingList.value = newValue.getParkingPlaces.parkingPlaces;
    getTotalPages(newValue.getParkingPlaces.filtered);
    i++;
  }
  isLoading.value = false;
});

const onChangePage = (page) => {
  currentPage.value = page;
  isLoading.value = true;
  loadMore(page);
  isLoading.value = false;
};

const onParkingUpdated = async () => {
  onChangePage(currentPage.value);
};
</script>
<style scoped lang="scss">
@import '~@/assets/styles/_colors';

.sub-tltle {
  color: map-get($primary-text, 'normal');
  font-family: 'Golos';
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
  cursor: pointer;
}
.light-text {
  font-family: 'Golos';
  color: map-get($lightgray-text, 'normal');
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  cursor: pointer;
}
.loading {
  text-align: center;
  margin-top: 40px;
}
</style>
