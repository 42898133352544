<template>
<base-popup 
  size="large" 
  topIndent="30" 
  :is-visible="isModalVisible"
  title="Оплата услуг"
  @close="onModalClose"
  :has-right-side-content="template ? true : false"
>
  <template v-slot:right-side-content>
    <div @click="openInfoModal" class="popup__right-side-content">
      <div class="popup__right-side-content-icon">
        <mdicon name="trash-can" size="24" />
      </div>
      <div class="popup__right-side-content-text">Удалить шаблон</div>
    </div>
  </template>

  <div class="popup">
    <SetupModalList 
      v-if="firstStep && !template" 
      :isModalVisible="isModalVisible"
      :selectedService="selectedService" 
      @onSelected="onSelectService" 
    />
    <SetupModalForm 
      v-else
      :template="template"
      :agentGroupItems="agentGroupItems"
      :deepModalData="deepModalData"
      @formChanged="onFormChanged"
      @formData="getFormData"
    />
  </div>

  <div class="popup__buttons">
    <div
      @click.prevent="onModalClose"
      class="popup__buttons-dismiss"
    >
      Отмена
    </div>
    <base-button 
      :text="firstStep && !template ? 'Далее' : 'Оплатить'" 
      color="green" 
      size="biggest"
      :disabled="!selectedService && !template"
      @click.prevent="onSubmit"
    />
  </div>
</base-popup>
</template>
<script setup>
import { BasePopup, BaseButton } from '@/components/atoms';
import SetupModalList from './SetupModalPages/SetupModalList.vue';
import SetupModalForm from './SetupModalPages/SetupModalForm.vue';
import { ref, watch } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  isModalVisible: Boolean,
  template: Object,
  agentGroupItems: Array,
  deepModalData: Object
});

const emit = defineEmits(['close', 'openInfoModal', 'submit']);

const store = useStore();
const firstStep = ref(true);
const selectedService = ref(false);
const submitForm = ref();
const formData = ref([]);

const onSelectService = (value) => selectedService.value = value;

const openInfoModal = () => {
  emit('openInfoModal');
}

const onModalClose = () => {
  selectedService.value = false; 
  firstStep.value = true;
  emit('close');
};

const onFormChanged = (form) => {
  submitForm.value = form;
}

const getFormData = (data) => {
  formData.value = [...data];
}

const checkForEmptyString = (obj, formData) => {
  for (let item of formData) {
    if (item.Name === 'terminalId') continue;
    if (item.Required === "true") {
      const fieldName = item.Name;
      if (fieldName in obj && obj[fieldName].trim() === '') {
        store.dispatch('notification/showNotification', {
          type: 'error',
          text: 'Необходимо заполнить все поля помеченные звездочкой (*)'
        });
        return false;
      }
    }
  }
  return true;
};

const onSubmit = () => {
  if (firstStep.value) {
    firstStep.value = false;

    emit('submit', selectedService.value)
    return;
  }

  let isFormCorrect = checkForEmptyString(submitForm.value, formData.value);
  if (isFormCorrect) emit('submit', submitForm.value);
}

watch(() => props.deepModalData, (value) => {
  onSelectService(value.agentGroupName);
  onSubmit();
})
</script>
<style scoped lang="scss">
.popup {
  &__right-side-content {
    display: flex;
    align-items: center;
    gap: 9px;
    cursor: pointer;

    &-icon {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #FFF1F1;
      color: #FF0000;
    }

    &-text {
      font-size: 16px;
      font-weight: 600;
      color: #FF0000;
    }
  }

  &__buttons {
    margin-top: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-dismiss {
      padding: 10px 20px;
      opacity: .5;
      cursor: pointer;
    }
  }
}
</style>