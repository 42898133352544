<template>
  <div class="client-claims">
    <div class="client-claims__header">
      <h1 class="header">Заявки</h1>
      <base-button 
        @click="EventBus.emit('open-claim-modal')"
        text="Создать заявку" 
        color="green" 
        size="big" 
      />
    </div>

    <div class="search-input-wrapper">
      <input @input="onSearchClaim" type="text" class="search-input" />
      <mdicon size="20" name="magnify" class="search-input-icon" />
    </div>

    <div class="client-claims__tabs">
      <base-tabs-wrapper @update:selectedTitle="handleTabClick">
        <base-tab :click="changeCompleted(false)" title="Активные">
          <client-claims :isActiveSide="isActiveSide" :completed="false" :searchQuery="searchQuery" />
        </base-tab>

        <base-tab :click="changeCompleted(true)" title="Завершенные">
          <client-claims :isActiveSide="isActiveSide" :completed="true" :searchQuery="searchQuery" />
        </base-tab>
      </base-tabs-wrapper>
    </div>
  </div>
</template>
<script setup>
import { BaseButton } from '@/components/atoms';
import { BaseTabsWrapper, BaseTab } from '@/components/atoms/';
import ClientClaims from './ClientClaims.vue';
import { EventBus } from '@/utils/eventBus';
import { ref } from 'vue';

const searchQuery = ref('');
const completed = ref(false);
const isActiveSide = ref(true);
//const selectedClass = ref('');

const handleTabClick = (title) => {
  switch (title) {
    case 'Активные':
      isActiveSide.value = true;
      break;
    case 'Завершенные':
      isActiveSide.value = false;
      break;
    default:
      return title
  }
}

const onSearchClaim = (e) => {
  searchQuery.value = e.target.value;
}

const changeCompleted = (bool) => {
  completed.value = bool;
}
</script>

<style lang="scss">
.client-claims {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__tabs {
    margin-top: 24px;
  }
}

.cards-client {
  margin-top: 38px;
  display: flex;
  flex-wrap: wrap;

  &__empty {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-text {
      font-size: 20px;
      font-weight: 500;
      color: var(--primary-color);
    }
  }

  &__item {
    &-header {
      margin-bottom: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-number {
        font-size: 17px;
        font-weight: 600;
        color: var(--black);
      }
    }

    &-subheader {
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: 500;
      color: var(--grey-color);
    }

    &-description {
      font-size: 15px;
      font-weight: 500;
      color: var(--dark);
    }

    &-line {
      margin-top: 17px;
      width: 100%;
      height: 1px;
      background: var(--grey-border-color);
    }

    &-action {
      padding: 10px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-block {
        display: flex;
        align-items: center;

        &-icon-grey {
          margin-right: 7px;
          color: var(--grey-color);
        }

        &-icon-primary {
          margin-right: 7px;
          color: var(--primary-color);
        }

        &-text {
          color: var(--grey-color);
        }

        &-text-primary {
          color: var(--primary-color);
        }
      }
    }
  }
}

@media(max-width: 768px) {
  .header {
    margin-bottom: 0;
  }
  .client-claims__header {
    margin-bottom: 20px;
  }
  .search-input-wrapper {
    width: 100% !important;
  }
}

@media(max-width: 740px) {
  .client-claims {
    padding: 32px 36px 16px 36px;
  }
}

@media(max-width: 400px) {
  .client-claims__header {
    gap: 10px;
    align-items: flex-start;
    flex-direction: column;
  }
}
</style>
