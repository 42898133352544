<template>
  <div>
    <h1 class="header">Опросы</h1>
    <div>
      <div class="d-flex justify-content-end">
        <base-button
          text="Создать опрос"
          color="green"
          size="m"
          @click="setPopupVisibility(true)"
        />
      </div>
      <div class="mt-4">
        <div class="d-flex mt-40 align-items-center">
          <div
            class="filter__item"
            @click="setActiveType('ALL')"
            :class="filter.type === 'ALL' ? 'filter__item_active' : null"
          >
            <span class="filter__item-text filter__item-text_title">Все</span>
            <span class="filter__item-text filter__item-text_number">{{
              totalVotes
            }}</span>
          </div>
          <div
            class="filter__item"
            @click="setActiveType('ACTIVE')"
            :class="filter.type === 'ACTIVE' ? 'filter__item_active' : null"
          >
            <span class="filter__item-text filter__item-text_title"
              >Активные</span
            >
            <span class="filter__item-text filter__item-text_number">{{
              activeVotes
            }}</span>
          </div>
          <div
            class="filter__item"
            @click="setActiveType('DONE')"
            :class="filter.type === 'DONE' ? 'filter__item_active' : null"
          >
            <span class="filter__item-text filter__item-text_title"
              >Завершенные
            </span>
            <span class="filter__item-text filter__item-text_number">{{
              archivedVotes
            }}</span>
          </div>
        </div>
        <div class="d-flex mt-20">
          <div class="search-input-wrapper">
            <input type="text" class="search-input" v-model="filter.search" />
            <mdicon size="20" name="magnify" class="search-input-icon" />
          </div>
          <Datepicker
            class="datepicker"
            v-model="filterDate"
            range
            locale="ru-RU"
            selectText="Выбрать"
            cancelText="Отмена"
            :format="'dd/MM/yyyy'"
            placeholder="Выберите дату"
            :enable-time-picker="false"
          >
          </Datepicker>
        </div>
        <div v-if="isLoading" class="mt-20 text-center">
          <img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" />
        </div>
        <div class="votes-list mt-4" v-else>
          <div v-for="vote in votes" :key="vote.id">
            <VoteItem
              :vote="vote"
              @archive="onArchiveVote"
              @delete="onDeleteVote"
            />
          </div>
        </div>
        <the-pagination
          :totalPages="totalPages"
          :currentPage="currentPage"
          @pageChanged="onChangePage"
          class="pagination"
          :class="{ hidden: totalPages <= 1 }"
        ></the-pagination>
      </div>
    </div>
    <!-- <div class="cap" v-else>
      <div class="cap__item">
        <p>На данный момент нет активных опросов</p>
        <base-button
          text="Создать опрос"
          color="green"
          size="m"
          @click="setPopupVisibility(true)"
        />
      </div>
      <mdicon size="600" class="cap__icon" name="ballot" />
    </div> -->
  </div>
  <VotesModal
    :is-modal-visible="isModalVisible"
    @close="setPopupVisibility(false)"
    @create="onVoteCreated"
  />
  <VotesInfoModal
    :is-modal-visible="isInfoModalVisible"
    :vote="voteByUrl"
    @close="setInfoModalVisibility(false)"
  />
</template>
<script setup>
import { BaseButton } from '@/components/atoms/';
import VotesModal from './VotesModal.vue';
import VotesInfoModal from './VotesInfoModal.vue';
import { ref, watch, onMounted, reactive } from 'vue';
import VoteItem from './VoteItem.vue';
import ThePagination from '@/components/ThePagination.vue';
import { useRoute, useRouter } from 'vue-router';
import {
  getAdminVotesList,
  archiveVote,
  getVoteById,
  // deleteVote,
} from '@/graphql/Votes.graphql';
import { useQuery, useMutation } from '@vue/apollo-composable';
import { useStore } from 'vuex';
// import ArchivedVotes from './ArchivedVotes.vue';
// import { THEME } from '@/constant/main'
const THEME = config.VUE_APP_THEME;
const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');
const route = useRoute();
const router = useRouter();

const store = useStore();

const totalVotes = ref(0);
const archivedVotes = ref(0);
const activeVotes = ref(0);

const totalPages = ref(0);
const elementsOnPage = ref(6);

const filterDate = ref(null);

watch(filterDate, (newValue) => {
  if (newValue) {
    if (newValue[0]) {
      filter.fromDate = new Date(
        new Date(newValue[0]).setHours(0, 0, 0, 1)
      ).toISOString();
    }
    if (newValue[1]) {
      filter.toDate = new Date(
        new Date(newValue[1]).setHours(23, 59, 59, 999)
      ).toISOString();
    }
  } else {
    (filter.fromDate = null), (filter.toDate = null);
  }
});

const getTotalPages = (totalItems) => {
  totalPages.value = Math.ceil(totalItems / elementsOnPage.value);
};

const isLoading = ref(true);

const votes = ref([]);

const filter = reactive({
  type: route.query.type || 'ACTIVE',
  search: route.query.search || '',
  fromDate: null,
  toDate: null,
});

const setActiveType = (type) => {
  filter.type = type;
};

let {
  result: allVotes,
  fetchMore,
  refetch,
} = useQuery(getAdminVotesList, {
  pagination: {
    limit: elementsOnPage.value,
    offset: 0,
  },
  filter: {
    type: route.query.type || 'ACTIVE',
    q: route.query.search || '',
    fromDate: null,
    toDate: null,
  },
});

const loadMore = (page = 0) => {
  const activePage = page === 1 ? 0 : page;
  fetchMore({
    variables: {
      pagination: {
        offset: (activePage === 0 ? 0 : activePage - 1) * elementsOnPage.value,
        limit: elementsOnPage.value,
      },
    },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      votes.value = [...fetchMoreResult.getAdminVotesList.votes];
    },
  });
};

const isInfoModalVisible = ref(false);
const setInfoModalVisibility = (status) => {
  isInfoModalVisible.value = status;
};

const voteByUrl = ref(null);

let { refetch: voteByIdRefetch } = useQuery(getVoteById, {
  id: 0,
});

onMounted(async () => {
  loadMore(+route.query.page || 0);
  if (route.query.vote) {
    const {
      data: { getVoteById },
    } = await voteByIdRefetch({
      id: +route.query.vote,
    });
    voteByUrl.value = getVoteById;
    setInfoModalVisibility(true);
  }
});

watch(allVotes, (newValue) => {
  getTotalPages(newValue?.getAdminVotesList.filtered);
  totalVotes.value = newValue?.getAdminVotesList.total;
  archivedVotes.value = newValue?.getAdminVotesList.archived;
  activeVotes.value = newValue?.getAdminVotesList.active;
  isLoading.value = false;
});

const currentPage = ref(+route.query.page || 1);

const onChangePage = (page, filterList = null) => {
  if (!filterList) {
    filterList = filter;
  }
  router.replace({
    route,
    query: {
      page,
      type: filterList?.type,
      search: filterList?.search,
    },
  });
  currentPage.value = page;
  isLoading.value = true;
  loadMore(page);
  isLoading.value = false;
};

let onLoad = true;

watch(filter, async (newValue) => {
  const {
    data: { getAdminVotesList },
  } = await refetch({
    filter: {
      type: newValue.type,
      q: newValue.search,
      fromDate: newValue.fromDate,
      toDate: newValue.toDate,
    },
  });
  votes.value = [...getAdminVotesList?.votes];
  getTotalPages(getAdminVotesList?.filtered);
  if (onLoad) {
    onChangePage(+route.query.page || 1, newValue);
    onLoad = false;
  } else {
    onChangePage(1, newValue);
  }
  if (!votes.value.length) {
    await store.dispatch('notification/showNotification', {
      text: 'Совпадений нет',
      type: 'error',
    });
  }
});

const { mutate: archiveVotesMutation } = useMutation(archiveVote);
// const { mutate: deleteVotesMutation } = useMutation(deleteVote);

const onArchiveVote = async (id) => {
  await archiveVotesMutation({
    id,
  });
  const {
    data: { getAdminVotesList },
  } = await refetch();
  votes.value = getAdminVotesList.votes;
};

const onDeleteVote = async () => {
  const {
    data: { getAdminVotesList },
  } = await refetch();
  votes.value = getAdminVotesList.votes;
  // console.log('votes', votes.value);
};

const isModalVisible = ref(false);

const setPopupVisibility = (status) => {
  isModalVisible.value = status;
};

const onVoteCreated = async () => {
  onChangePage(1);
  const {
    data: { getAdminVotesList },
  } = await refetch();
  votes.value = getAdminVotesList.votes;
};
</script>
<style scoped lang="scss">
@import '~@/assets/styles/_colors';
.header {
  color: var(--black);
}
.search-input-wrapper {
  position: relative;
  width: 300px;
  height: 38px;
  margin-right: 20px;
}
.search-input {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: var(--light-secondary-bg);
  border: none;
  padding: 0 20px 0 40px;
  font-family: 'Golos';
  font-size: 16px;
}
.search-input-icon {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 10px;
  color: var(--grey-dark-color);
}
.datepicker {
  width: 400px;
}
.votes-list {
  display: flex;
  flex-wrap: wrap;
}
.filter__item {
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  margin-right: 40px;
}
.filter-select:not(:last-child) {
  margin-right: 20px;
}
.filter__item_active .filter__item-text_title {
  font-weight: 700 !important;
  color: map-get($green, 'normal') !important;
}
.filter__item_active .filter__item-text_number {
  color: map-get($lightgray-text, 'normal');
}
.filter__item-text {
  font-size: 18px;
  margin-right: 5px;
}
.filter__item-text_title {
  color: map-get($lightgray-text, 'normal');
  font-weight: 700;
  transition: all 0.3s ease 0s;
}
.filter__item-text_number {
  color: map-get($lightgray-text, 'normal');
  font-weight: 500;
  transition: all 0.3s ease 0s;
}
.pagination.hidden {
  opacity: 0;
  visibility: hidden;
}
.cap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: 1;
}
.cap p {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 20px;
  z-index: 1;
}
.cap__icon {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
  color: #dbdbd723;
  // transform: rotate(-180deg);
}
.cap__item {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 250px;
}
</style>
