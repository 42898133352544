<template>
<div class="select__wrapper">
<label v-if="label" class="select__label">{{ label }}</label>
<div class="select" :style="label ? 'margin-top: 8px' : ''">
  <span class="select__layer" @click="onSelectClick" />
  <input
    class="select__field"
    type="text"
    :placeholder="placeholder"
    :value="input.name"
    ref="inputRef"
    disabled
    required
  />
  <div class="select__chevron" :class="isVisible ? 'select__chevron--rotate' : ''">
    <mdicon name="chevron-down" size="24" />
  </div>
  <div class="select__options" v-show="isVisible">
    <div
      v-for="item in optionList"
      :key="item.id"
      class="select__options-option"
      @click="onSelect(item)"
    >
      {{ item.name }}
    </div>
  </div>
</div>
</div>
</template>
<script setup>
import { ref, reactive, computed, onMounted, watch } from 'vue';

const props = defineProps({
  placeholder: String,
  options: Array,
  value: String,
  disabled: Boolean,
  label: String,
})

const emit = defineEmits(['input', 'change'])

const optionList = computed(() => {
  return props.options;
})

const isVisible = ref(false);
const inputRef = ref(null);
let input = reactive({
  id: null,
  name: null,
})

const onSelectClick = () => {
  if (props.disabled) return;
  inputRef.value.focus();
  isVisible.value = !isVisible.value;
}

const onSelect = (opt) => {
  input = {
    id: opt.id,
    name: opt.name,
  };
  isVisible.value = false;
  emit('input', opt.id);
  emit('change', opt);
}

watch(() => props.value, () => {
  // console.log(props.value, 'value');
  if (props.value && props.options) {
    input = props.options.find(({ name }) => name === props.value.name) || {};
  }
}, {deep: true})
// onMounted(() => {
//   if (props.value && props.options) {
//     input = props.options.find(({ name }) => name === props.value) || {};
//   }
// })
</script>
<style scoped lang="scss">
.select {
  position: relative;
  width: 100%;
  height: 50px;
  border: 1px solid #B9B9B9;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 400;

  &__wrapper {
    width: 100%;
  }

  &__label {
    font-size: 16px;
    font-weight: 400;
    color: #535352;
  }

  &__inner {
    display: flex;
    align-items: center;
  }

  &__layer {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }

  &__chevron {
    position: absolute;
    right: 10px;
    top: 50%;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: var(--primary-color);
    background: #F1F7FF;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);

    span {
      transform: rotate(0deg);
      transition: .3s all;
    }

    &--rotate span {
      transition: .3s all;
      transform: rotate(180deg);
    }
  }

  &__field {
    padding-left: 10px;
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    font-size: 16px;
    font-weight: 400;
  }

  &__options {
    border: 1px solid #B9B9B9;
    border-radius: 12px;
    position: absolute;
    z-index: 32001;
    width: 100%;
    max-height: 160px;
    overflow-y: auto; 
    background: #FFFFFF;

    &-option {
      padding: 15px 10px;
      background: #FFFFFF;
      transition: .3s all;
      user-select: none;
      cursor: pointer;

      &:hover {
        background: rgb(235, 233, 233);
      }
    }
  }
}
</style>