<template>
  <div>
    <h1 class="header header_black">Справочник</h1>
    <div class="d-flex justify-content-between mb-4">
      <span @click="router.back()" class="d-flex align-items-center sub-tltle">
        <mdicon size="24" class="arrow" name="chevron-left" /><span
          >Категории/подкатегории</span
        >
      </span>
      <div class="d-flex align-items-center">
        <base-button
          text="Добавить категорию"
          color="green"
          @click.prevent="setCategoryPopupVisibility(true)"
        />
      </div>
    </div>
    <div v-if="isLoading" class="loading">
      <img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" />
    </div>
    <div class="accordion-list" v-else>
      <div
        v-for="category in categoriesList"
        :key="category.id"
        class="d-flex mb-2 accordion-wrapper"
      >
        <base-accordion :header="category.title" class="accordion-item">
          <p
            class="mb-3"
            v-for="subCategory in category.subs"
            :key="subCategory.id"
          >
            {{ subCategory.title }}
          </p>
        </base-accordion>
        <mdicon
          size="17"
          name="pencil"
          class="edit-icon"
          @click="onCategoryUpdate(category)"
        />
      </div>
    </div>
  </div>
  <CategoriesModal
    :is-category-modal-visible="isCategoryModalVisible"
    @close="setCategoryPopupVisibility(false)"
    :category="selectedCategory"
    @create="onCategoryCreated"
    @update="onCategoryUpdated"
    @delete="onCategoryDeleted"
  />
</template>
<script setup>
import { BaseButton, BaseAccordion } from '@/components/atoms';
import { getAllCategories } from '@/graphql/Claims.graphql';
import { useQuery } from '@vue/apollo-composable';
import { watch, ref } from 'vue';
import CategoriesModal from './CategoriesModal.vue';
import { useRouter } from 'vue-router';
// import { THEME } from '@/constant/main'
const THEME = config.VUE_APP_THEME;
const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');
const router = useRouter();

const isLoading = ref(true);

const selectedCategory = ref(null);

const { result: allCategories } = useQuery(getAllCategories);

const categoriesList = ref(null);

watch(allCategories, (newValue) => {
  categoriesList.value = newValue.getAllCategories;
  // console.log(categoriesList.value);
  isLoading.value = false;
});

const isCategoryModalVisible = ref(false);

const setCategoryPopupVisibility = (status) => {
  isCategoryModalVisible.value = status;

  if (!status) {
    selectedCategory.value = null;
  }
};

const onCategoryUpdate = (category) => {
  selectedCategory.value = category;
  // console.log('selectedCategory.value', selectedCategory.value);
  setCategoryPopupVisibility(true);
};

const onCategoryCreated = (category) => {
  categoriesList.value = [category, ...categoriesList.value];
};

const onCategoryUpdated = (category) => {
  categoriesList.value = categoriesList.value.map((item) => {
    if (item.id === category.id) {
      return { ...category };
    }
    return item;
  });
};

const onCategoryDeleted = (id) => {
  categoriesList.value = categoriesList.value.filter((item) => item.id !== id);
};
</script>
<style scoped lang="scss">
@import '~@/assets/styles/_colors';

.sub-tltle {
  color: map-get($primary-text, 'normal');
  font-family: 'Golos';
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
  cursor: pointer;
}
.light-text {
  font-family: 'Golos';
  color: map-get($lightgray-text, 'normal');
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  cursor: pointer;
}
.accordion-list {
  width: 40%;
}
// .accordion-item {
//   margin-bottom: 20px;
// }
.accordion-wrapper {
  height: fit-content;
  width: 100%;
  justify-content: space-between;
}
.accordion-item {
  width: 100%;
}
.edit-icon {
  margin: 10px 0 0 10px;
  color: map-get($lightgray-text, 'normal');
  cursor: pointer;
}
.loading {
  text-align: center;
  margin-top: 40px;
}
</style>
