<template>
<base-popup 
  size="large" 
  topIndent="30" 
  :is-visible="isModalVisible"
  title="Добавление квартиры" 
  @close="onModalClose"
>
  <SetupInfoBlock 
    title="Введите данные квартиры"
    textColor="var(--primary-color)"
    :icon="{
      name: 'information',
      color: 'var(--primary-color)'
    }"
  />

  <div class="popup__select">
    <SetupSelect
      :options="tenantList"
      placeholder="Выбрать"
      label="Наименование ЖК"
      @change="onChooseTenant"
    />
  </div>

  <div class="popup__inputs">
    <SetupInput 
      type="text"
      placeholder="ЛС"
      label="Лицевой счет"
      @input="(e) => payload.ls = e.target.value"
    />
    <SetupInput 
      type="text"
      placeholder="Например: 123"
      label="Номер квартиры"
      @input="(e) => payload.flatNum = e.target.value"
    />
  </div>

  <div class="popup__buttons">
    <div
      @click.prevent="onModalClose(true)"
      class="popup__buttons-dismiss"
    >
      Отмена
    </div>
    <base-button 
      text="Далее" 
      color="green" 
      size="biggest" 
      @click.prevent="onSubmit"
    />
  </div>

</base-popup>
</template>
<script setup>
import { BasePopup, BaseButton } from '@/components/atoms';
import SetupInfoBlock from '../SetupComponents/SetupInfoBlock.vue';
import SetupInput from '../SetupComponents/SetupInput.vue';
import SetupSelect from '../SetupComponents/SetupSelect.vue';
import { computed, ref } from 'vue';
import { reactive } from 'vue';

const props = defineProps({
  isModalVisible: Boolean,
  tenants: Array,
})

const emit = defineEmits(['close', 'submit']);

const payload = reactive({
  ls: '',
  flatNum: '',
  tenantId: ''
});

const tenantList = computed(() => {
  return props.tenants.map((item) => {
    return {
      id: item.id,
      name: item.title,
    }
  });
})

const onChooseTenant = (item) => {
  payload.tenantId = item.id;
}

const onModalClose = (isSubmit) => {
  isSubmit !== true ? (isSubmit = false) : (isSubmit = true);
  let isClosed = true;
  if (!isSubmit)
    isClosed = confirm(
      'Вы действительно хотите закрыть окно? Внесенные изменения не сохранятся'
    );
  if (isClosed) {
    emit('close');
  }
};

const onSubmit = () => {
  emit('submit', payload);
}
</script>
<style scoped lang="scss">
.popup {
  &__select {
    margin-top: 22px;
  }

  &__inputs {
    margin-top: 22px;
    display: flex;
    gap: 10px;
  }

  &__buttons {
    margin-top: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-dismiss {
      padding: 10px 20px;
      opacity: .5;
      cursor: pointer;
    }
  }
}
</style>