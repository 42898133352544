<template>
  <!-- <form class="form mt-24" @submit.prevent="logIn">
    <div class="d-flex align-items-baseline">
      <span class="phone-icon">
        <i class="fas fa-at"></i>
      </span>
      <div class="input wd-100">
        <input
          v-model.trim="form.phone"
          type="tel"
          placeholder="Email"
          class="input__field input__field_phone input__field_auth"
        />
      </div>
    </div>

    <div class="d-flex align-items-baseline">
      <span class="password-icon">
        <i class="fas fa-unlock-alt"></i>
      </span>
      <div class="input wd-100">
        <input
          v-model.trim="form.password"
          type="password"
          placeholder="Пароль"
          class="input__field input__field_password input__field_auth mt-30"
          readonly
          onfocus="this.removeAttribute('readonly')"
        />
      </div>
    </div>
    <div class="mt-40 d-flex justify-content-center align-items-center">
      <div class="text-center">
        <base-button text="Войти" color="green" size="small" />
      </div>
    </div>
  </form> -->

  <!-- ORY AUTH -->
  <!-- {{ session }} -->
  <div v-if="isLoading && !session" class="mt-20 text-center loader">
    <img :src="THEME === 'domion' ? loaderDomion : loaderGreen" alt="loading" />
  </div>
  <div v-if="session">
    <p>{{ session }}</p>
    <br />
    <base-button
      @click="logingOryFetch"
      text="Тест"
      color="green"
      size="small"
    />
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useMutation } from '@vue/apollo-composable';
import { logErrorMessages } from '@vue/apollo-util';

import { BaseButton } from '@/components/atoms';
import { ory } from '@/config/ory';
import { logIn as Login, oryLogIn } from '@/graphql/Login.graphql';
import { FrontendApi, Configuration } from '@ory/client';
import { EventBus } from '@/utils/eventBus';
// import { getCookie } from '@/utils/cookie';

const THEME = config.VUE_APP_THEME;
const loaderDomion = require('@/assets/images/loader.svg');
const loaderGreen = require('@/assets/images/loader-green.svg');

const ownUrl = ref('');
const session = ref(null);
const logoutUrl = ref(null);
const isLoading = ref(false);
const form = reactive({
  phone: '',
  password: '',
});

const router = useRouter();
const store = useStore();

const { mutate: logIn, onError } = useMutation(Login, () => ({
  variables: {
    data: {
      email: form.phone,
      password: form.password,
    },
  },
  update: async (cache, { data }) => {
    await store.dispatch('app/setAuth', {
      token: data.logIn.token,
      role: data.logIn.role,
      user: '{}',
    });
    await router.push('/app');
    // FIX: this remove after make ory login
    window.location.reload();
  },
}));

const { mutate: oryLogin } = useMutation(oryLogIn);

onError((error) => {
  store.dispatch('notification/showNotification', {
    text: 'Ошибка авторизации. Логин или пароль неправильные.',
    type: 'error',
  });
  logErrorMessages(error);
});

const oryLoad = async () => {
  try {
    ownUrl.value = window.location.href;

    isLoading.value = true;
    const { data } = await ory.toSession();
    session.value = data;
    await store.dispatch('app/setSession', data);
    EventBus.emit('set-graphql-link');

    // console.log(data, 'ory Data');
    isLoading.value = false;
  } catch (error) {
    // if (error.response && error.response.status === 401) {
    window.location.replace(
      `https://ui.kratos.domion.tech/login?return_to=${ownUrl.value}`
    );
    // } else {
    //   // console.error('Произошла ошибка при получении сессии:', error);
    // }
  }
};

const oryCreateLogoutLink = async () => {
  const { data: logoutData } = await ory.createBrowserLogoutFlow();
  logoutUrl.value = logoutData.logout_url;
  await store.dispatch('app/setLogoutUrl', logoutData.logout_url);
  // console.log(logoutUrl.value, 'logout value');
};

const logingOryFetch = async () => {
  const mutation = `
  mutation oryLogIn($data: OryLoginInput!) {
    oryLogIn(data: $data) {
      token
      role
    }
  }
  `;
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  const requestBody = {
    query: mutation,
    variables: { data: {} },
  };
  const body = JSON.stringify(requestBody);
  let apiLink = localStorage.getItem('apiUrl');

  if (apiLink) {
    fetch(`${apiLink}/graphql`, {
      method: 'POST',
      headers,
      body,
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => () => {}) // console.log(data))
      .catch((error) => () => {}); // console.error('Error:', error));
  }
};

const getGraphqlApiLinks = async () => {
  // ORY
  const storedData = localStorage.getItem('s');

  if (storedData) {
    const data = JSON.parse(storedData);
    const tenants = data.identity.metadata_public.tenants;
    const activeTenant = tenants.active;

    const tenantInfo = await tenants.available.find(
      (tenant) => tenant.id === activeTenant
    );

    if (tenantInfo) {
      let apiLink = tenantInfo.apiUrl;
      localStorage.setItem('apiUrl', apiLink);
      // console.log(apiLink, 'apiDEV');
    } else {
      // console.log('Объект с id, соответствующим activeTenant, не найден');
    }
  } else {
    // console.log('Данные в localStorage не найдены');
  }
};

// ORY
onMounted(async () => {
  await oryLoad();
  if (!session.value) return;
  await oryCreateLogoutLink();
  await getGraphqlApiLinks();
  await logingOryFetch();
  // await router.push('/app');

  // window.location.reload();
});
</script>

<style scoped lang="scss">
.auth-btn {
  padding: 10px 20px;
  background: var(--primary-color);
  color: #fff;
  border-radius: 8px;
  text-decoration: none;
  text-transform: uppercase;
}

.form {
  width: 300px;
  margin: 0 auto;
}

.forgot-password {
  display: inline-block;

  font-size: 12px;
  line-height: 14px;
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
}

.phone-icon,
.password-icon {
  margin-right: 10px;
  color: var(--primary-color);
}
</style>
