<template>
  <div :class="THEME === 'domion' ? 'bg-blue-logo' : 'bg-green-logo'">
      <object type="image/svg+xml" :data="THEME === 'domion' ? logoDom : logoGreen" width="80">
          <img v-if="THEME === 'domion'" src="@/assets/images/logo-domion.svg" :alt="logoTitle" class="logo" />
          <img v-else src="@/assets/images/logo.png" :alt="logoTitle" class="logo" />
      </object>
       
      <h2 :class="THEME === 'domion' ? 'title' : 'title-green'">{{ logoTitle }}</h2>
  </div>
  </template>
  
  <script setup>
  import logoDom from '@/assets/images/logo-domion.svg';
  import logoGreen from '@/assets/images/logo.svg'
  import { ref } from 'vue';
//   import { THEME } from '@/constant/main'
const THEME = config.VUE_APP_THEME; 
  const logoTitle = ref(THEME === 'domion' ? 'домион' : 'гринвуд');
  </script>
  
  <style scoped lang="scss">
  .bg-blue-logo {
      margin-bottom: 82px;
      width: 100%;
      height: 210px;
  
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-image: url('../../../../assets/images/auth-client-bg.png');
      background-position: bottom center;
      background-size: cover;
      background-repeat: no-repeat;
  }
  .bg-green-logo {
      margin-bottom: 82px;
      width: 100%;
      height: 210px;
  
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // background-image: url('../../../../assets/images/auth-client-bg.png');
      background-position: bottom center;
      background-size: cover;
      background-repeat: no-repeat;
  }
  .title {
      margin-top: 17px;
      text-transform: uppercase;
      color: var(--white);
      font-size: 38px;
      font-weight: 600;
  }
  .title-green {
      margin-top: 17px;
      text-transform: uppercase;
      color: var(--black);
      font-size: 38px;
      font-weight: 600;
  }
  </style>