<template>
<base-popup 
  size="large" 
  topIndent="30" 
  :is-visible="isModalVisible"
  title=""
  @close="onModalClose"
>
<div class="popup">
  <div class="popup__icon" :style="`background: ${icon.color}30`">
    <mdicon 
      :name="icon.name" 
      :size="icon.size ?? '21'" 
      :style="`color: ${icon.color}`"
      class="title-list__icon" 
    /> 
  </div>
  <div class="popup__title">{{ title }}</div>
  <div class="popup__text" v-if="text">{{ text }}</div>
  <div class="popup__buttons">
    <base-button 
      :text="textButton" 
      :style="colorButton ? `background: ${colorButton}` : ''"
      color="green" 
      size="biggest" 
      @click.prevent="onSubmit"
    />
    <a 
      v-if="extraButton" 
      :href="extraButton.link" 
      class="popup__buttons-link"
    >
     {{ extraButton.text }} 
    </a>
    <div 
      v-if="closeButton"
      @click="onModalClose"
      class="popup__buttons-close"
    >
      Закрыть
    </div>
  </div>
</div>
</base-popup>
</template>
<script setup>
import { BasePopup, BaseButton } from '@/components/atoms';

defineProps({
  isModalVisible: Boolean,
  icon: Object,
  extraButton: Object,
  title: String,
  text: String,
  textButton: String,
  colorButton: String,
  closeButton: Boolean
})

const emit = defineEmits(['close']);

const onModalClose = () => {
  emit('close');
};

const onSubmit = () => {
  // console.log('submit');
  onModalClose();
}
</script>
<style scoped lang="scss">
.popup {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  &__title {
    margin-top: 24px;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }

  &__text {
    max-width: 300px;
    margin-top: 10px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    opacity: .5;
    text-align: center;
  }

  &__buttons {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    &-link {
      color: var(--primary-color);
      font-size: 14px;
      font-weight: 500;
      text-decoration: underline;
    }

    &-close {
      opacity: .6;
      cursor: pointer;
    }
  }
}
</style>