<template>
  <div>
    <base-popup
      size="large"
      :is-visible="isCategoryModalVisible"
      :title="category ? 'Редактировать категорию' : 'Новая категория'"
      @close="onModalClose"
      :has-right-side-content="Boolean(category)"
    >
      <template v-slot:right-side-content>
        <div class="category-modal-delete" @click="onCategoryDelete">
          Удалить категорию
        </div>
      </template>
      <div class="d-flex align-items-center justify-content-between">
        <span class="input-label">Категория*:</span>
        <input
          type="text"
          placeholder="Водоснабжение..."
          class="modal-input"
          v-model="currentCategory.title"
        />
      </div>
      <div class="mt-5">
        <span class="input-label" v-if="subCategories.length"
          >Подкатегории:</span
        >
        <div
          class="modal-item mt-4 d-flex align-items-center"
          v-for="(subCategory, idx) in subCategories"
          :key="idx"
        >
          <label class="input-label">№{{ ++idx }}</label>
          <div class="modal-input-wrapper">
            <input
              type="text"
              placeholder="Введите подкатегорию"
              v-model="subCategory.title"
              class="modal-input modal-input_label"
            />
            <div class="delete-item" @click="onDeleteSubCategory(subCategory)">
              <mdicon size="24" name="close" class="delete-icon" />
            </div>
          </div>
        </div>
        <div class="new-item-btn" @click="onNewSubCategory">
          Добавить подкатегорию
        </div>
      </div>
      <div class="d-flex justify-content-end mt-5">
        <base-button
          text="Отмена"
          color="gray"
          class="mr-3"
          @click.prevent="onModalClose"
        />

        <base-button
          :text="category ? 'Сохранить' : 'Создать'"
          color="green"
          @click.prevent="onFormSubmit"
          :disabled="isFormDataInvalid"
        />
      </div>
    </base-popup>
    <the-confirm
      :is-visible="isDeleteConfirmVisible"
      @close="onToggleDeleteConfirm"
      @confirm="isDeleteConfirmed"
    />
  </div>
</template>
<script setup>
import { BasePopup, BaseButton } from '@/components/atoms';
import { defineProps, defineEmits, ref, computed, watch } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import TheConfirm from '@/components/TheConfirm.vue';
import { useStore } from 'vuex';
import {
  createCategory,
  updateCategory,
  deleteCategory,
} from '@/graphql/ClaimCategory.graphql';
import { useMutation } from '@vue/apollo-composable';

const store = useStore();

const emit = defineEmits(['close', 'create', 'update', 'delete']);

const props = defineProps({
  isCategoryModalVisible: {
    type: Boolean,
    default: false,
  },
  category: {},
});

watch(
  () => props.category,
  (data) => {
    if (data) {
      // console.log('category', data);
      currentCategory.value = JSON.parse(JSON.stringify(data));
      subCategories.value = data.subs.map(({ id, title }) => {
        return {
          id,
          title,
        };
      });
    } else {
      currentCategory.value = ref({ ...emptyCategory }).value;
    }
  }
);

const emptyCategory = {
  id: null,
  title: '',
  subs: null,
};

const currentCategory = ref({
  id: null,
  title: '',
  subs: null,
});

const subCategories = ref([]);

const onNewSubCategory = () => {
  subCategories.value.push({ title: '', id: null });
};

for (let i = 0; i < subCategories.value.length; i++) {
  const subCategory = subCategories.value[i];
  watch(subCategory, (newValue) => {
    // console.log('subCategory', newValue);
  });
}

// Delete subCategory

const onDeleteSubCategory = (subCategory) => {
  const index = subCategories.value.indexOf(subCategory);

  if (index !== -1) {
    subCategories.value.splice(index, 1);
  }
};

//Validation
const rules = computed(() => {
  return {
    title: { required },
  };
});

const v$ = useVuelidate(rules, currentCategory);
// console.log(v$);

const isFormDataInvalid = computed(() => {
  return v$.value.$invalid;
});

// Close Modal
const onModalClose = (isSubmit) => {
  isSubmit !== true ? (isSubmit = false) : (isSubmit = true);
  let isClosed = true;
  if (!isSubmit) {
    isClosed = confirm(
      'Вы действительно хотите закрыть окно? Внесенные изменения не сохранятся'
    );
  }
  if (isClosed) {
    currentCategory.value = ref({ ...emptyCategory }).value;
    subCategories.value = [];
    emit('close');
  }
};

const { mutate: createCategoryMutation } = useMutation(createCategory);
const { mutate: updateCategoryMutation } = useMutation(updateCategory);
const { mutate: deleteCategoryMutation } = useMutation(deleteCategory);

const onCategoryCreate = async () => {
  const { title } = currentCategory.value;
  const subs = subCategories.value.map(({ title }) => title);

  const { data } = await createCategoryMutation({
    title,
    subs,
  });
  // console.log('created', data.createCategory);
  emit('create', data.createCategory);
};

const onCategoryUpdate = async () => {
  const { id, title } = currentCategory.value;
  const subs = subCategories.value.map(({ title }) => title);

  const { data } = await updateCategoryMutation({
    id,
    title,
    subs,
  });

  // console.log('updated', data.updateCategory);
  emit('update', data.updateCategory);
};

const isDeleteConfirmVisible = ref(false);
const isCategoryShouldBeDeleted = ref(false);

const onToggleDeleteConfirm = () => {
  isDeleteConfirmVisible.value = !isDeleteConfirmVisible.value;
};

const isDeleteConfirmed = () => {
  isCategoryShouldBeDeleted.value = true;
};

const onCategoryDelete = async () => {
  isDeleteConfirmVisible.value = true;
  watch(isCategoryShouldBeDeleted, async (newValue) => {
    if (newValue) {
      const { id } = currentCategory.value;

      try {
        await deleteCategoryMutation({
          id,
        });
        store.dispatch('notification/showNotification', {
          text: `Категория успешно удалена`,
          type: 'success',
        });
        onModalClose(true);
        emit('delete', id);
      } catch (error) {
        // console.log(error);
        store.dispatch('notification/showNotification', {
          text: error,
          type: 'error',
        });
      } finally {
        isCategoryShouldBeDeleted.value = false;
      }
    }
  });
};

const onFormSubmit = async () => {
  if (isFormDataInvalid.value) return;

  if (props.category) {
    await onCategoryUpdate();
  } else {
    await onCategoryCreate();
  }

  store.dispatch('notification/showNotification', {
    text: `Категория успешно ${props.category ? 'сохранена' : 'создана'}`,
    type: 'success',
  });
  onModalClose(true);
};
</script>
<style scoped lang="scss">
@import '~@/assets/styles/_colors';
.modal-input {
  border: 1px solid map-get($lightgray-stroke, 'normal');
  border-radius: 10px;
  padding: 6px 10px;
  width: 100%;
  font-family: 'Golos';
  font-size: 16px;
  font-weight: 400;
  // width: 350px;
  width: 100%;
}
.modal-input::placeholder {
  color: map-get($lightgray-stroke, 'normal');
}
.input-label {
  color: map-get($lightgray-text, 'normal');
  margin-right: 20px;
}
.new-item-btn {
  color: map-get($green, 'normal');
  cursor: pointer;
  width: fit-content;
  margin: 20px 0 0 auto;
}
.modal-input-wrapper {
  position: relative;
  width: 100%;
}
.delete-item {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
  height: calc(100% - 8px);
  width: 26px;
  background-color: map-get($lightgray-stroke, 'normal');
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.delete-icon {
  color: var(--white);
}
.category-modal-delete {
  color: map-get($red, 'normal');
  cursor: pointer;
}
</style>
