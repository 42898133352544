<template>
<header class="header">
  <div class="header__logo">
    <img class="logo" src="@/assets/images/setup-logo-dom.svg" alt="setup-logo"/>
    <div class="title">домион</div>
  </div>
  <button @click="logout"><img class="icon" src="@/assets/images/icons/logout-dom.svg" alt="logout"/>Выйти</button>
</header>
</template>
<script setup>
import { useStore } from 'vuex';

const store = useStore();

const logout = async () => {
  await store.dispatch('app/logout');
  window.location.reload(); 
}
</script>
<style scoped lang="scss">
.header {
  padding: 0 36px;
  width: 100%;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;

  &__logo {
    display: flex;
    align-items: center;
  }

  .logo {
    margin-right: 20px;
    margin-top: auto;
    height: 110px;
  }

  .title {
    font-size: 44px;
    font-weight: 700;
    color: var(--primary-color);
  }

  button {
    padding: 0 40px;
    height: 50px;

    display: flex;
    align-items: center;
    gap: 10px;

    background: #EAEDF1;
    border-radius: 15px;
    border: none;
    font-size: 16px;
    font-weight: 700;
    color: var(--primary-color);
    cursor: pointer;

    .icon {
      width: 21px;
      height: 21px;
    }
  }
}
</style>